.backWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-top: 30px;
    text-decoration: none;
    color:inherit
}

.formWrapper {
    width:100%;
    position: relative;
    display: block;
}

.titleWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px
}

.formtTitle {
    margin-right: auto;
    line-height: 26.04px;
    font-size: 20px;
    font-weight: 700;
}
.applyBtn {
    width:111px;
    height: 32px;
    padding: 18px 20px 18px 20px;
    gap: 10px;
    border-radius: 4px;
    outline:none;
    background-color: #2B52AA;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 18.23px;
    white-space: nowrap;
    border:none;

}



/* .formContainers {
    background-color: red;
    width: 100%;
    position: relative;   
}

.formContainerWrap {
 margin:auto;
 padding:20px
} */

.formContainers {
  padding: 20px;
  margin-top: 30px;
  background-color: #EFF4FF;

}

.formFull {
    display: flex;
    width: 100%;
    gap: 30px;
}

.formInput {
    flex: 1;
    margin-bottom: 30px;
}

.selectContainer {
    border: 2px solid #DFE8FF;

}

.selectInput {
    border:1px solid #DFE8FF
}

.tableContainer {
    margin-bottom: 50px;
}
.button {
    padding: 18px 20px 18px 20px;
    border-radius: 4px;
    width: 95px;
    max-height: 32px;
    white-space:nowrap;
    font-family: inherit;
    outline: none;
    border:none;
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
    line-height: 18.23px;
    }
  
    .contained {
        composes: button;
        background-color:#2B52AA;
        color: #fff;
    }
    .applyBtn:disabled {
        cursor:not-allowed;
        opacity: 0.5;
    }

    .applyBtnCustomOutline {
        composes: button;
        background-color:#fff;
        border: 1px solid  #2B52AA;;
        color: #2B52AA;
        width:145px
    }
    .applyBtnCustomOContained {
        composes: button;
        background-color:#2B52AA;
        color: #fff;
        width:145px
    }

    .containerWrap {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 100px; 
    }

    .containerTitle {
        color: #2A38A4;
        font-weight: 700;
        line-height: 24px;
        line-height: 31.25px;
        font-style: italic;
        margin-bottom: 10px;

    }

    .containerPara {
        line-height: 20.83px;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 10px;
    }

    .btnWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btnWrapper button {
        background-color: #407BFF;
        padding: 16px;
        outline: none;
        border: none;
        color:#fff;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 700;
        line-height: 20.83px;
    }

    .customFormContainers {
        margin-top: 30px;
    }

    .customRuleForm {

    }

    .inputContainer {
        border:1px solid #DFE8FF;
        border-radius: 5px;
        overflow: hidden;
    }
    
    .inputContainer:focus-within, .inputContainer:hover {
        border:2px solid #407BFF;
    }

    .customFormBtnWrapper {
        display: flex;
       justify-content: flex-end;
        width: 100%;
        margin-top: 30px;
    }

    .customRuleSaveBtn {
        background-color: #407BFF;
        padding: 16px;
        outline: none;
        border: none;
        color:#fff;
        border-radius: 5px;
        font-size: 16px;
        font-weight: 700;
        line-height: 20.83px;
        width: 113px;
        white-space: nowrap;
        max-height: 50px;
    }

    .titleWrapperCustom {
        width: calc(100% - 5em);
    padding: 0 2.5em 4em 2.5em;
    position: relative;
    padding-top: 30px;;
    }

    .customTableContainer {
        background-color: #EFF4FF;
        padding: 30px;
    }
    .tableContainerBg {
        background-color: #EFF4FF;
    }