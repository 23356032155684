.cancelTenant{
    width:150px;
    height:40px;
    border:2px solid #407BFF;
    background-color: white;
    color: #407BFF;
    cursor: pointer;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.saveTenant{
    width:150px;
    height:40px;
    border:2px solid #407BFF;
    color: white;
    cursor: pointer;
    background-color: #407BFF;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}