.accounts-main {
    margin: 1.5em 0 1em 0;
    padding: 2em 2em 1em 2em;
    width: calc(100% - 3em);
    border-radius: 10px;
    position: relative;
    background: transparent;
  }
  
  .accounts-main .tb-filter-container {
    top: 1.5em;
  }
  
  .accounts-main h3 {
    font-size: 1.3em;
    margin: 0 0 1em 0;
  }
  
  .accounts-main .accounts-type-table {
    border-spacing: 0 .5em;
    margin: 1em 0 0 0;
  }
  
  .accounts-main .table-container {
    position: unset;
  }
  
  .accounts-main {
    position: relative;
  }

  .all-accounts-table {
    border-collapse: collapse !important;
  }

  .all-accounts-table thead tr th {
    padding-bottom: 1.2em !important;
  }
  
  .accounts-main .tb-filter-container {
    right: 2em;
    top: 2em;
  }
  
  .accounts-main .table-container thead tr th div {
    padding: 0;
  }
  
  .accounts-type-table .header-row {
    padding: 0 1em;
  }

  .accounts-type-table.consolidated-overview-table tbody tr {
    text-align: right ;
    border-radius: 5px;
  }

  .accounts-type-table.consolidated-overview-table.all-accounts-table tbody tr {
    text-align: left;
  }
  
  .accounts-type-table tbody tr td {
    border: none !important;
    padding: .9em 1em;
  }

  .accounts-type-table.consolidated-overview-table tbody tr td {
    border: unset !important;
    border-top: solid 1px #DCE5F1 !important;
  }
  
  .accounts-type-table tbody tr td:nth-child(2) {
    width: 40%;
  }

  .accounts-type-table.consolidated-overview-table tbody tr td:nth-child(2) {
    width: unset;
  }
  
  .accounts-type-table tbody tr:hover {
    transform: unset;
  }

  table.accounts-type-table tbody tr {
    background-color: #fff;
  }
  
  .accounts-type-table .list-overflow-text-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    max-width: 250px;
  }
  
  @media only screen and (max-width: 1000px) {
    .accounts-type-table .list-overflow-text-container {
      max-width: 200px;
    }
  }
  
  @media only screen and (max-width: 900px) {
    .accounts-type-table .list-overflow-text-container {
      max-width: 100%;
    }
  
    .accounts-main .table-container thead tr th {
      padding: .2em .5em;
    }
  }