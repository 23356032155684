.dropdown {
    position: relative;
  }
  
  .dropdown button {
    padding: 10px;
    cursor: pointer;
  }
  
  .dropdown-menu {
    min-width: 300px;
    background-color: #fff;
    border-radius: 10px;
    padding:  24px 32px 24px 32px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, .1);
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    transform: translateX(0);
    position: absolute;
    z-index: 1;
    position: absolute;
    right: 0;
    top: 2em;

  }
  


  