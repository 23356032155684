
.title-class{
    color:rgba(0, 0, 0, 1);
    font-size:24px;
    font-weight: 700;
    text-align: left;
    padding-left: 10px;
}
.title-subclass{
    color: rgba(106, 113, 125, 1);
    font-size:16px;
    font-weight: 400;
    text-align: left;
    padding-left: 10px;
}

.history-hold{
    margin-top:40px;
    display:flex;
    background-color:whitesmoke;
    flex-direction:column;
    justify-content:space-around;
    border:1px solid rgba(196, 211, 249, 1);
    border-width:1px 0px 0px 0px;
    padding:20px;
    padding-top:35px
}

.history-hold .roof{
    display:flex;
    align-items:center;
    justify-content:start;
    column-gap:5px;
    margin-top:20px
}

.filterHold{
    align-items:end;
    border:1px solid rgba(203, 218, 255, 1);
    margin:30px 0px;
    border-width:0px 0px 1px 0px;
    display:flex;
    justify-content:center;
    column-gap:10px;
}

.filterHeaderSelect{
    color:#2A38A4;
    border: 3px solid #2A38A4;
    border-width:0px 0px 1px 0px;
    padding: 10px;
    cursor: pointer;
    font-weight: 600;
}

.filterHeaderNonSelect{
    color: #000000;
    padding: 10px;
    cursor: pointer;
    font-weight: 600;


}