.tenantModalBox{
    width: 100%;
    height:100%;
}

.tenantModalBox .User{
    color:#2A38A4;
    font-size: 22px;
    font-weight: 700;
    padding:15px;
    border: 1px solid #DFE8FF;
    border-width: 0px 0px 1px 0px;
}

.HolderTenantModal{
    display:flex;
    flex-direction:column;
    height:250px;
    justify-content:space-around;
    align-items:center;
}

.HolderTenantModal .imgFlex{
    display:flex;
    align-items:center;
    justify-content:center
}


.HolderTenantModal .imgFlexFail{
    display:flex;
    align-items:center;
    justify-content:center;
    padding:"5px"
}


.HolderTenantModal .successButton {
    width:120px;
    height:35px;
    border-radius:7px;
    background-color:#407BFF;
    color:white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}