.imageViewHold{
    height:50px;
    font-size: 12px;
    width: 250px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: start;
    column-gap: 6px;
    cursor: pointer;
  }
  
  
  .imageNameHold{
      width: 65%;
      height:100%;
      overflow: hidden;
      background-color:rgba(64, 123, 255, 1);
      border-radius: 10px;
      color:white;
      display:flex;
      align-items: center;
      justify-content: start;
      padding-left: 5px;
      box-sizing: border-box;
  }
  
  .imageViewHold .imgView{
      display:flex;
      color: rgba(64, 123, 255, 1);
      align-items:center;
      justify-content:center;
      border: 1px solid rgba(64, 123, 255, 1);
      border-width: 0px 0px 1px 0px;
  }

  .SalesEstTitles{
    display:flex;
    justify-content:space-around;
    margin-bottom:20px;
  }

  .SalesEstFields{
    display:flex;
    justify-content:space-around;
  }

  .SalesEstTitles .titleValues{
    color:rgba(0, 0, 0, 1);
    font-size:18px;
    display:flex;
    align-items:center;
    justify-content:center;
    width:16.66%;
    font-weight:700
  }

  .SalesEstFields .fieldValues{
    color:rgba(0, 0, 0, 1);
    font-size:18px;
    display:flex;
    align-items:center;
    justify-content:center;
    width:16.66%;
  }

  @media only screen and (max-width:600px){
    .SalesEstTitles{
        display:flex;
        justify-content:space-around;
        flex-direction: column;
        margin-bottom:0px;
      }
   
      .SalesEstFields{
        display:flex;
        justify-content:space-around;
        flex-direction: column;
      }

      .salesEstHolder{
        display: flex;
        justify-content: space-between;
      }

      .SalesEstTitles .titleValues{
        color:rgba(0, 0, 0, 1);
        font-size:18px;
        display:flex;
        align-items:center;
        justify-content:left;
        width:auto;
        font-weight:700;
        height:40px;
        margin-bottom: 5px;
      }

      .SalesEstFields .fieldValues{
        color:rgba(0, 0, 0, 1);
        font-size:18px;
        display:flex;
        align-items:center;
        justify-content:right;
        width:auto;
        height: 40px;
        margin-bottom: 5px;
      }
}