.bureau-form-account-header-container {
  display: flex;
  gap: 1em;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 1em 0;
}

.bureau-form-account-header-container button {
  padding: .7em 2em;
}

.bureau-form-section-account-tables {
  margin: 1em 0;
  border: 1px solid #D9E5FF;
}

.bureau-report-main .bureau-account-payment-history-table thead tr th:first-child, 
.bureau-report-main .bureau-account-payment-history-table tbody tr td:first-child {
  width: 40%;
}

.bureau-report-main .bureau-account-header-reason-table thead tr th:first-child, 
.bureau-report-main .bureau-account-header-reason-table tbody tr td:first-child {
  width: 30%;
}

.bureau-report-account-main-info-container {
  display: flex;
  gap: 1em;
  align-items: center;
  font-size: 1.1em;
}

.bureau-report-account-main-info-container h4,
.bureau-report-account-main-info-container p {
  margin: .2em 0;
}

/* */
.bureau-report-main .tab-bodies-container {
  margin: 1em 0 0 0;
}

.bureau-report-main .st-details-list-main-container .st-details-list {
  display: flex;
  flex-direction: column;
  gap: .8em;
}

.bureau-report-main .st-details-list-main-container .list-type-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}

.bureau-report-view-col {
  display: flex;
  flex-direction: column;
  gap: 2em;
  margin-top: 20px;
}

.bureau-report-view-col .st-details-card {
  border: 1px solid #D9E5FF;
}

.bureau-report-view-col .st-details-card .details-header-row h3 {
  font-size: 1.5em;
}

.bureau-report-view-col .st-details-card .list-type-content h2,
.bureau-report-view-col .st-details-card .list-type-content p {
  font-size: 1em;
}

.bureau-report-view-col .st-details-card .list-type-title {
  color: #407BFF;
  font-weight: 600;
}

.bureau-report-view-col .st-details-card .list-type-content h2 {
  font-weight: 500;
  color: #373A3F;
}

.bureau-report-view-col .st-details-card .list-type-content p {
  font-weight: 600;
}

.bureau-report-view-col .st-details-card .st-details-list-main-container {
  margin: 1.5em 0 0 0;
}

.bureau-report-view-col .st-details-card .st-details-list-main-container:not(:first-child) {
  margin: 3em 0 0 0;
}

.bureau-report-view-col .st-details-card .st-details-list-main-container .list-type-title {
  margin: 0 0 1em 0;
}

.bureau-back-button-container {
  display: flex;
  justify-content: flex-end;
  margin: 1em 0 0 0;
}

.bureau-back-button-container button {
  padding: .7em 2em;
  font-weight: 600;
  border-width: 2px;
}

.contentPremiumTabs{
  margin-top: 30px;
  display: flex;
  justify-content: center;
  column-gap: 15px;
}

.contentPremiumTabs .selecTed{
  padding: 15px 30px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-weight: 700;
  color:#2A38A4;
  cursor: pointer;
}

.contentPremiumTabs .unselecTed{
  padding: 15px 30px;
  border-radius: 10px;
  cursor: pointer;
}


@media only screen and (max-width: 500px) {
  .bureau-report-main .st-details-list-main-container .list-type-content {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: .4em;
  }
}