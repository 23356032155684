.report-table-view {
  margin: 1em 0;
}

.report-table-view-header {
  display: flex;
  gap: 1em;
  align-items: center;
}

.report-table-view-header h3 {
  font-size: 1em;
  color: #373A3F;
}

.report-table-view table tbody tr:nth-child(odd) {
  background-color: #F6F8FC;
}

.report-table-view table tbody tr:nth-child(even) {
  background-color: #D9E5FF;
}