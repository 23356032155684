.Review-hold{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    background-color: white;
    height:250px;
    width:100%;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.Review-hold .title{
    font-size: 20px;
    font-weight: 700;
    color: #2A38A4;
}

.Review-hold .imgHold{
    display: flex;
    justify-content: center;
    align-items: center;
}

.Review-hold .title-block{
    font-size: 20px;
    font-weight: 700;
    color: #E80000;
}

.Review-hold .info{
    font-size: 14px;
    font-weight: 400;
    color: #000000;
}

.buttonClose{
    display: flex;
    justify-content: center;
    align-items: center;
    margin:10px 0px;
}

.buttonClose .done{
    padding: 15px;
    background-color: #2A38A4;
    border:1px solid #2A38A4;
    color: white;
    border-radius: 5px;
    width:150px
}

.buttonClose .close{
    padding: 15px;
    background-color: #E80000;
    border:1px solid white;
    color: white;
    border-radius: 5px;
    width:150px;
}

.buttonClose .allow:hover{
    padding: 15px;
    background-color: white;
    border:1px solid #2A38A4;
    color: #2A38A4;
    border-radius: 5px;
    width:150px;
    transition: all 1s;
}



.buttonHolder{
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
}

.buttonHolder .block{
    padding: 15px;
    background-color: white;
    border:1px solid #E80000;
    color:#E80000;
    border-radius: 5px;
    width:150px
}

.buttonHolder .block:hover{
    padding: 15px;
    background-color: #E80000;
    border:1px solid white;
    color: white;
    border-radius: 5px;
    width:150px;
    transition: all 1s;
}


.buttonHolder .block-solid{
    padding: 15px;
    background-color:  #E80000;
    border:1px solid white;
    color:white;
    border-radius: 5px;
    width:150px
}

.buttonHolder .block-solid:hover{
    padding: 15px;
    background-color:  white;
    border:1px solid #E80000;
    color:#E80000;
    border-radius: 5px;
    width:150px;
    transition: all 1s;
}


.buttonHolder .cancel{
    padding: 15px;
    background-color: white;
    border:1px solid #8F9CA8;
    color: #8F9CA8;
    border-radius: 5px;
    width:150px
}

.buttonHolder .cancel:hover{
    padding: 15px;
    background-color: #8F9CA8;
    border:1px solid white;
    color:  white;
    border-radius: 5px;
    width:150px;
    transition: all 1s;
}



.buttonHolder .allow{
    padding: 15px;
    background-color: #2A38A4;
    border:1px solid #2A38A4;
    color: white;
    border-radius: 5px;
    width:150px
}

.buttonHolder .allow:hover{
    padding: 15px;
    background-color: white;
    border:1px solid #2A38A4;
    color: #2A38A4;
    border-radius: 5px;
    width:150px;
    transition: all 1s;
}