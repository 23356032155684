.settings-body {
  width: calc(100% - 5em);
  padding: 4em 2.5em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.settings-body .top-level-tabs {
  justify-content: flex-start;
  padding: 0;
  width: 100%;
}

.settings-body .tab-bodies-container {
  width: 100%;
  margin: 2em 0;
}

.settings-main-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.settings-section-container {
  background: #fff;
  border: 1px solid #DFE8FF;
  padding: 2em;
  border-radius: 10px;
}

.settings-section-container hr {
  color: #DFE8FF;
  background-color: #DFE8FF;
  border: 1px solid #DFE8FF;
  margin: 1.5em 0;
}

.settings-call-to-action-container {
  display: flex;
  align-items: center;
  gap: 1em;
}

.settings-call-to-action-container-multiple {
  display: flex;
  align-items: center;
  gap: 1em;
  flex-wrap: wrap;
}

.settings-call-to-action-header {
  display: flex;
  flex-direction: column;
  gap: .5em;
  width: 60%;
}

.settings-api-docs-call-to-action-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #407BFF;
  border-radius: 4px;
}

.settings-call-to-action-container a {
  text-decoration: none;
  color: #fff;
  text-align: center;
  padding: 1em;
}

.settings-call-to-action-container-multiple a {
  text-decoration: none;
  color: #fff;
  text-align: center;
  padding: 1em;
}

.settings-section-container #settings-api-setup-message {
 margin: 0 0 2em 0;
}

.settings-section-container .settings-title { font-size: 1.2em; }
.settings-section-container .settings-subtitle { font-size: .9em; }

.settings-section-container .settings-subheader {
  margin: .5em 0 0 0;
}

.settings-api-call-to-action-copy-fields {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: .5em;
}

.settings-api-call-to-action-copy-fields-multiple {
  width: 47%;
  display: flex;
  flex-direction: column;
  gap: .5em;
}

.settings-input-field-hookurl input{
  width:75%;
  height:100%;
  border:none;
  outline: none;
}

.settings-input-field-hookurl button{
  background-color: #407BFF;
  color: #fff;
  border: none;
  border-radius: 0 1px 1px 0;
  padding: 0.5em 0.5em;
  width:80px;
  height:50px;
  margin-left: 3px;
}


.settings-input-field {
  border: 2px solid #DFE8FF;
  border-radius: 4px;
  background-color: #fff;
  padding: 0 0 0 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6d6d6d;
  word-break: break-all;
  height:50px;
}

.settings-input-field-hookurl {
  border: 2px solid #DFE8FF;
  border-radius: 4px;
  background-color: #fff;
  padding: 0 0 0 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6d6d6d;
  word-break: break-all;
}

.settings-input-field button {
  background-color: #407BFF;
  color: #fff;
  border: none;
  border-radius: 0 3px 3px 0;
  padding: 1em 2em;
  margin-left: 3px;
  height:100%;
}

/* Profile */
.settings-personal-details-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: ". ." ". .";
  gap: 1em;
}

.settings-field-container {
  display: flex;
  flex-direction: column;
  gap: .5em;
}

.profile .settings-input-field {
  padding: .9em 1em;
}

.settings-change-password {
  background-color: #407BFF;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 1em 2em;
}

.profile .settings-call-to-action-header {
  width: auto;
  margin: 0 10em 0 0;
}

/* Api */
.api-copy-button:disabled {
  opacity: .7;
  cursor: default;

}

.selfManage{
  background-color: #407BFF;
  color: white;
  border-radius: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  height:50px;
  width:230px;
  cursor: pointer;
}

@media only screen and (max-width: 1100px) {
  .settings-call-to-action-container:not(.reset-password) {
    flex-direction: column;
  }

  .settings-api-call-to-action-copy-fields,
  .settings-call-to-action-header {
    width: 100%;
  }

  .settings-api-call-to-action-copy-fields-multiple,
  .settings-call-to-action-header {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .settings-personal-details-container {
    display: flex;
    flex-direction: column;
  }

  .reset-password {
    flex-direction: column;
  }

  .profile .settings-call-to-action-header {
    width: 100%;
    margin: 0;
  }
}