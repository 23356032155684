.auditHold{
    width:100%;
    box-sizing:border-box;
    display:flex;
    justify-content:space-between;
}

.auditRight{
    width: 58%;
    
}

.auditLeft{
    width: 40%;
    padding:15px;
    box-sizing:border-box;
    height: 350px;
    background-color: white;
}

.auditRightContent{
    max-height: 350px;
    overflow-y: auto;
}

.unSelectedTrail{
    display:flex;
    width:100%;
    justify-content:space-between;
    margin:10px auto;
    padding:15px;
    box-sizing:border-box;
    background-color:white;
    border-radius:10px;
    cursor: pointer;
}

.selectedTrail{
    display:flex;
    width:100%;
    justify-content:space-between;
    margin:10px auto;
    padding:15px;
    box-sizing:border-box;
    background-color:#DFE8FF;
    border-radius:10px;
    border: 1px solid #407BFF;
    cursor: pointer;
}