.error-body {
  width: calc(100% - 4em);
  height: calc(100% - 4em);
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1.5em 0;
  background: linear-gradient(90deg, #eff3fa 32%, #e8eef6 100%);
}

.error-top-icon img {
  width: 3.5em;
  height: 3.5em;
}

.error-message-section h1 {
  font-size: 3em;
  margin: 0;
}

.error-message-section p {
  font-size: 1.2em;
}

.error-body .button-pill {
  padding: .5em 4em;
  font-weight: 500;
  font-size: 1.1em;
  cursor: pointer;
}

@media only screen and (max-width: 400px){
  .error-body {
    font-size: 80%;
    padding: 2em;
    width: calc(100% - 4em);
  }
}

@media only screen and (max-height: 500px), screen and (min-height: 870px){
  .error-body {
    height: 100%;
  }
}
