.summary-row{
    background-color: white;
}

.summary-subtitle{
    margin: 1em 0em !important;
    font-size: .8em !important;
    margin-bottom: 1em;
    margin-right: 2em!important;
}

.header-summary{
  border-right: 1px solid lightgray;
}
/* .details-header-row-none{
    border-right: 1px solid white;
}

.details-header-row {
    border-right: 1px solid lightgray;
}

.details-header-row h4 {
    text-align: left;
}

.details-header-row h3 {
    text-align: left;
} */

.overview-heading{
    padding: 24px;
    padding-bottom: 0px;
    font-weight: 700;
}

.overview-heading span {
  color: #66737F;
  font-weight: 400;
  margin-right: 5px;
}

.tar{
  text-align: right;
}

.default-cursor{
  cursor: default!important;
}

.processed{
  color:green;
}

.failed{
  color:red
}

.turnover-header{
  width:50%;
  float: left;
  font-size: 16px;
}

.turnover-detail{
  width:50%;
  float: right;
}

.overview-summary{
  font-size: 1em!important;
}

  .mb{
    margin-bottom: 0.5em!important;
  }
  
  .detail-balance{
    font-size: 0.7em!important;
    color: gray;
  }

/* Cards Container and rows*/
.st-details-card-container {
    display: flex;
    flex-direction: column;
    gap: 2em 1em; 
    width: 100%;
    margin: 2em 0 0 0;
  }
  
  /* .st-details-card-container-row {
    display: flex; 
    flex-direction: row;
    width: 100%;
    gap: 1em;
  } */
  
  /* consolidate Tab Cards */
  .st-overview-consolidate-top-row-container {
    gap: 1em;
    flex-direction: column;
    width: 100%;
  }
  
  .st-overview-consolidate-top-row-container .st-overview-consolidate-top-row {
    display: flex;
    gap: 1em;
  }

  .st-overview-consolidate-top-row-container .st-overview-consolidate-top-row.sub-total-overview {
    gap: 1px;
    background-color: #DFE1E5;
    margin: 1.5em 0;

  }

  .st-overview-consolidate-top-row-container .st-overview-consolidate-top-row.sub-total-overview .st-details-card {
    border-radius: unset;
    padding: 0 1.5em;
  }
  
  .st-overview-consolidate-top-row .st-details-card-main {
    width: 25%;
  }
  
  .st-overview-consolidate-top-row .cards {
    padding: 1em;
  }
  
  .st-overview-consolidate-top-row .st-details-card {
    flex-direction: row;
    gap: 0 1em;
    width: calc(100% - 3em);
  }
  
  .st-overview-consolidate-top-row .st-details-card .details-header-row {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }
  
  .st-overview-consolidate-top-row .st-details-card svg {
    width: 3em;
    height: 3em;
  }
  
  .st-overview-consolidate-top-row .st-details-card .details-header-row h3 {
    font-size: 1.5em;
  }
  
  .st-overview-consolidate-top-row:first-child .st-details-card .details-header-row h3 {
    font-size: 1.2em;
    word-break: break-all;
  }
  
  .st-overview-consolidate-top-row:first-child .st-details-card .details-header-row h4 {
    font-size: .9em;
  }
  
  .st-overview-consolidate-top-row .st-details-card-main {
    width: 25%;
  }
  
  .st-overview-consolidate-top-row .cards {
    padding: 1.5em;
  }
  
  .st-overview-consolidate-top-row .st-details-card {
    flex-direction: row;
    gap: 0 1em;
    width: calc(100% - 3em);
    /* justify-content: center; */
    align-items: center; 
    /* justify-content: start;
    align-items: start; */
  }

  .transaction-overview-subhead .overview-heading {
    margin-bottom: 30px;
  }

  .transaction-overview-subhead .st-details-card-container-row {
    display: unset;
  }

  .transaction-overview-subhead .st-details-card-container-row .inner-wrapper {
    padding: 0px 24px 20px;
    display: flex;
    gap: 1em;
    justify-content: space-between;
  }