.bureau-main {
    margin: 1em 0 0 0;
    width:95%;
    margin:0px auto
  }
  
  .bureau-check-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    margin: 0 0 2em 0;
  }
  
  .bureau-check-header-container h3 {
    font-size: 1.5em;
  }
  
  .bureau-check-header-container button {
    padding: .8em 2em;
    border-radius: 4px;
  }
  
  .bureau-creditcheck-type-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    width: 100%;
  }
  
  .bureau-creditcheck-type-container .bureau-creditcheck-type-card {
    box-sizing: border-box;
    flex: 1 1 calc(33% - .5em);
    background: #fff;
    padding: 2em 1.5em;
    border-radius: 4px;
    border: 1px solid #DFE8FF;
    display: flex;
    flex-direction: column;
    gap: 1em;
    max-width: calc(33% - .5em);
  }
  
  .bureau-creditcheck-type-container .bureau-creditcheck-type-card img {
    width: 40px;
    height: 40px;
  }
  
  .bureau-creditcheck-type-container .bureau-creditcheck-type-card h3 {
    font-size: 1.25;
  }
  
  .bureau-creditcheck-type-container .bureau-creditcheck-type-card p {
    font-size: .88em;
  }
  
  .bureau-main hr {
    margin: 2em 0 2em -2.5em;
    width: calc(100% + 5em - 1px);
    border: 1px solid #C4D3F9;
  }
  
  /* Reports Table */
  .bureau-all-reports-table-container h3 {
    font-size: 1.5em;
  }
  
  .bureau-all-reports-table-container table thead tr th:last-child,
  .bureau-all-reports-table-container table tbody tr td:last-child {
    width: 10%;
  }
  
  .bureau-all-reports-table-container table tbody tr {
    background-color: #fff;
  }
  
  .bureau-all-reports-table-container table tbody td button {
    padding: .8em 3em;
  }
  
  .disableCrbButton{
    background-color: grey;
    border-radius: 7px;
    color:white;
    cursor:auto;
  
  }

  .roofCrb{
    display:flex;
    align-items:center;
    justify-content:start;
    column-gap:5px;
    margin:20px 0px;
    width: 95%;
    margin: 0px auto;
    margin-top: 20px;
}

.roofCrb .span-view-report{
    font-size:16px;
    cursor:pointer;
    color:rgba(196, 196, 196, 1);
    font-weight:700;
}

.roofCrb .span-view-history{
    font-size:16px;
    cursor:pointer;
    color:rgba(17, 98, 220, 1);
    font-weight:700;
}


  @media only screen and (max-width: 1200px) {
    .bureau-creditcheck-type-container .bureau-creditcheck-type-card {
      flex: 1 1 calc(50% - .5em);
      max-width: calc(50% - .5em);
    }
  }
  
  @media only screen and (max-width: 850px) {
    .bureau-creditcheck-type-container .bureau-creditcheck-type-card {
      flex: 1 1 250px;
      max-width: unset;
    }
  }
  
  @media only screen and (max-width: 650px) {
    .bureau-check-header-container {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  