.score-history-table {
    margin-top: 20px;
}

.button-square.table-row-buttons.edit.button-view {
    background-color: #407BFF;
    color: #fff;
    border: 1px solid #407BFF;
    padding: 0.5em 2em;
    font-size: 0.7em;
    font-weight: 400; 
}