.histHold{
    color:#2A38A4;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 25px;
}

.crbTableCont{
    border:0.1px solid black;
    padding: 0px 0px
}

.wideCrbTitle{
    display:flex;
    justify-content:space-between;
    height:40px;
    padding: 10px 0px;
    box-sizing: border-box;

}

.wideCrbTitle section{
    display: flex;
    justify-content: start;
    align-items: center;
    font-weight: 700;
}

.wideCrbTitle section:nth-child(1){
    width:16.5%;
    box-sizing: border-box;
    padding-left: 10px;
    text-align: left;
}

.wideCrbTitle section:not(:nth-child(1)){
    width:16.5%;
    box-sizing: border-box;
    padding-left: 10px;
    text-align: left;
    border: 0.1px solid black;
    border-width: 0px 0px 0px 0.1px;
}


.wideCrbContent{
    border: 0.1px solid black;
    border-width: 0.1px 0px 0px 0px;
    display:flex;
    justify-content:space-between;
}

.wideCrbContent section{
    display: flex;
    justify-content: start;
    align-items: center;
}

.wideCrbContent section:nth-child(1){
    width:16.5%;
    box-sizing: border-box;
    padding-left: 10px;
    text-align: left;
    overflow-x: auto;
}


.wideCrbContent section:not(:nth-child(1)){
    width:16.5%;
    box-sizing: border-box;
    padding-left: 10px;
    text-align: left;
    border: 0.1px solid black;
    border-width: 0px 0px 0px 0.1px;
    overflow-x: auto;
}



.leanCrbTitle{
    display:flex;
    justify-content:space-between;
    height:40px;
    padding: 10px 0px;
    box-sizing: border-box;

}

.leanCrbTitle section{
    display: flex;
    justify-content: start;
    align-items: center;
    font-weight: 700;
    align-items: center;
}

.leanCrbTitle section:nth-child(1){
    width:50%;
    box-sizing: border-box;
    padding-left: 10px;
    text-align: left;
}

.leanCrbTitle section:not(:nth-child(1)){
    width:16.5%;
    box-sizing: border-box;
    padding-left: 10px;
    text-align: left;
    border: 0.1px solid black;
    border-width: 0px 0px 0px 0.1px;
}


.leanCrbContent{
    border: 0.1px solid black;
    border-width: 0.1px 0px 0px 0px;
    display:flex;
    justify-content:space-between;
}

.leanCrbContent section{
    display: flex;
    justify-content: start;
    align-items: center;
}


.leanCrbContent section:nth-child(1){
    width:50%;
    box-sizing: border-box;
    padding-left: 10px;
    text-align: left;
    overflow-x: auto;
}


.leanCrbContent section:not(:nth-child(1)){
    width:16.5%;
    box-sizing: border-box;
    padding-left: 10px;
    text-align: left;
    border: 0.1px solid black;
    border-width: 0px 0px 0px 0.1px;
    overflow-x: auto;
}

.lowerInfoHold{
    margin:30px 0px
}

.lowerInfoHold .guarantorHold{
    color:#130F26;
    font-size: 16px;
    font-weight: 700;
}

.segmentHold{
    display: flex;
    align-items: center;
    justify-content: start;
    column-gap: 20px;
}