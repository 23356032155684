.affordability-body {
    margin: 2em 0;
  }
  
  .affordability-body .affordability-no-data-header {
    font-style: italic;
    text-align: center;
  }
  
  .affordability-new-container {
    width: calc(100% - 4em);
    padding: 2em;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 2em 0;
    background: #fff;
  }
  
  .affordability-new-text-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .affordability-header-info {
    display: flex;
    gap: .5em;
  }
  
  .affordability-header-info h1 {
    margin: 0;
  }
  
  .affordability-header-info .info {
    background: transparent;
    border: none;
    font-size: 1.2em;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  /* Tooltip */
  .tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .tooltip .tooltip-text {
    visibility: hidden;
    width: 350px;
    text-align: center;
    border-radius: 5px;
    padding: .5em;
    font-size: .8em;
    position: absolute;
    z-index: 1;
    bottom: 130%;
    left: 50%;
    margin-left: -180px;
    background: #fff;
    border: 1px solid #407BFF;
  }
  
  .tooltip .tooltip-text::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: #407BFF transparent transparent transparent;
  }
  
  .tooltip:hover .tooltip-text {
    visibility: visible;
  }
  
  .affordability-header-info .info img {
    width: 1em;
    height: 1em;
  }
  
  .affordability-header-note {
    margin: 0;
    font-size: .9em;
  }
  
  .affordability-header-note span {
    font-weight: 500;
  }
  
  .affordability-form-container {
    width: 100%;
    margin: 1em 0 0 0;
  }
  
  .affordability-form-container .affordability-form-fields {
    display: flex;
    flex-direction: column;
  }
  
  .affordability-form-container .aff-inputs-container {
    width: 100%;
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1em; 
    grid-template-areas: 
      "one two three"; 
  }
  
  #aff-input-one { grid-area: one; }
  #aff-input-two { grid-area: two; }
  #aff-input-three { grid-area: three; }
  
  .affordability-form-container .aff-inputs-container .aff-inputs {
    width: 100%;
    margin: 1em 0 0  0;
  }
  
  .affordability-form-container .aff-inputs h4 {
    font-weight: 500;
    margin: 0 0 .3em 0;
  }
  
  .affordability-form-container .aff-inputs h4 span {
    font-weight: 400;
    font-style: italic;
    font-size: .8em;
  }
  
  .affordability-form-container .aff-inputs input {
    padding: .7em 1em;
    width: calc(100% - 2em - 4px);
    background-color: #F8FBFF;
    border: 2px solid #407BFF;
  }
  
  /* Chrome, Safari, Edge, Opera */
  .aff-inputs input::-webkit-outer-spin-button,
  .aff-inputs input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .affordability-form-container .aff-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 2em 0 0 0;
  }
  
  .affordability-form-container button {
    border: none;
    font-weight: 500;
    font-size: 1.1em;
    padding: .7em 1em;
    width: 40%;
    cursor: pointer;
  }
  
  .affordability-body .aff-error-fields {
    font-style: italic;
    margin: .8em 0 0 0;
    font-size: .8em;
  }
  
  .affordability-body .aff-default-fields-message {
    font-size: .8em;
    margin: .8em 0 0 0;
  }
  
  .affordability-body .aff-default-fields-message span {
    font-weight: 600;
    color: #000;
  }
  
  /* Affordability Accordion */
  .affordability-body .affordability-details-container {
    border-radius: 10px;
    padding: 1em 1em 1em 0;
    background-color: #fff;
  }
  
  .affordability-details-header-row {
    width: calc(100% - 6em);
    margin: 1em 0;
    padding: 0 3em;
    display: grid; 
    grid-template-columns: .95fr .95fr 1.1fr; 
    grid-template-rows: 1fr;
    grid-template-areas: 
      ". . ."; 
  }
  
  .affordability-details-header-row h4 {
    font-size: 1.2em;
  }
  
  .affordability-body .accordion-container {
    width: calc(100% - 2em);
    padding: 0 1em 1em 1em;
    min-height: auto;
    max-height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .affordability-body .accordion-container .acc-row {
    display: grid; 
    grid-template-columns: 1.3fr 1.3fr 1.3fr 0.1fr; 
    grid-template-rows: 1fr;
    grid-template-areas: 
      ". . . .";
  }
  
  .affordability-breakdown-container {
    margin: 0 0 1em 0;
  }
  
  .affordability-breakdown-container h2 {
    margin: 0 0 1em 0;
  }
  
  .affordability-breakdown-container .affordability-breakdown-details {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .affordability-breakdown-container .affordability-breakdown-details p {
    margin: 0 0 .5em 0;
  }
  
  .affordability-breakdown-container .affordability-breakdown-details .affordability-breakdown-column-one {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2em;
  }
  
  .affordability-breakdown-container .affordability-breakdown-details .affordability-breakdown-column-two {
    width: 45%;
    margin: 0 1em 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1em;
  }
  
  .affordability-breakdown-container .affordability-breakdown-details .affordability-breakdown-column-two .aff-details-list {
    display: flex;
    justify-content: space-between;
    gap: 2em;
  }
  
  @media only screen and (max-width: 1200px) {
    .affordability-form-container .aff-inputs-container {
      width: 100%;
      display: grid; 
      grid-template-columns: 1fr 1fr;
      gap: .2em 2em; 
      grid-template-areas: 
        "one two"
        "three ."; 
    }
  }
  
  @media only screen and (max-width: 1000px) {
    .affordability-details-container {
      flex-direction: column-reverse;
    }
  
    .affordability-details-container .dc-simple-header-container {
      margin: 0;
    }
  }
  
  @media only screen and (max-width: 950px) {
    /* Tooltip */
    .tooltip .tooltip-text {
      bottom: unset;
      left: unset;
      margin-left: -180px;
      top: -5px;
      right: 110%;
    }
    
    .tooltip .tooltip-text::after {
      content: none;
    }
  }
  
  @media only screen and (max-width: 850px) {
    .affordability-form-container .aff-inputs-container {
      width: 100%;
      display: grid; 
      grid-template-columns: 1fr; 
      grid-template-rows: 1fr 1fr 1fr; 
      gap: 0; 
      grid-template-areas: 
        "one"
        "two"
        "three"; 
    }
  
    .affordability-breakdown-container .affordability-breakdown-details .affordability-breakdown-column-one {
      justify-content: unset;
      gap: 1em;
    }
  
    .affordability-breakdown-container .affordability-breakdown-details .affordability-breakdown-column-two {
      width: 100%;
    }
  
    .affordability-breakdown-container .affordability-breakdown-details {
      flex-direction: column;
      gap: 2em;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .affordability-form-container button {
      width: 100%;
    }
  
    .affordability-body .accordion-container .acc-row {
      display: grid;
      grid-template-columns: 1.8fr 0.2fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas: 
        " header1 accordion-icon "
        " header2 . "
        " header3 . ";
      padding: 0 0 0 2em;
      width: calc(100% - 2em);
      margin: .5em 0 0 0;
    }
  
    .affordability-body .acc-row h4:first-child { grid-area: header1; }
    .affordability-body .acc-row h4:nth-child(2) { grid-area: header2; }
    .affordability-body .acc-row h4:nth-child(3) { grid-area: header3; }
    .affordability-body .acc-row h4:last-child { grid-area: accordion-icon; }
  
    .affordability-body .acc-row h4 { 
      margin: .5em 0;
    }
  
    .affordability-body .acc-row-right button, .acc-row-right span {
      width: unset;
      text-align: left;
    }
  
    .affordability-details-header-row {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-areas: "." "." ".";
      gap: .5em;
      padding: 2em 3em 1em 3em;
    }
    
    .affordability-details-header-row h4 {
      margin: 0;
    }
    
    .affordability-details-header-row h4:first-child {
      width: 100%;
    }
    
    .affordability-details-header-row h4:nth-child(2) {
      width: 100%;
    }
    
    .affordability-details-header-row h4:last-child {
      width: 100%;
    }
  }
  
  @media only screen and (max-width: 450px) {
    /* Tooltip */
    .tooltip .tooltip-text {
      width: 70vw;
    }
  }