.list-type-title {
  margin: 0;
  font-size: 1em;
  font-weight: 500;
}

.list-type-detail {
  font-size: 2.5em;
  margin: 0;
}

.list-type-detail span {
  font-size: .8em;
}

.st-details-list-main-container {
  display: flex;
  flex-direction: column;
}

.st-details-list-main-container ul,
.st-detail-color-background-list-container ul,
.st-details-pattern-bottom-row ul,
.st-details-double-colored-bottom-row ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list-row-two-colored:nth-child(odd) { background-color: #F6F8FC; }
.list-row-two-colored:nth-child(even) { background-color: #D9E5FF; }

.st-details-list-main-container ul li h2,
.st-details-list-main-container ul li p {
  margin: 0;
}

/* Statement Details */
/* Income Tab */
.st-details-column-list {
  width: 100%;
  display: grid;
  gap: 1.5em 2em;
  grid-template-areas: 'auto auto';
}

.st-details-column-list li {
  width: 100%;
}

.st-details-column-list li h2 {
  font-weight: 500;
  font-size: 1.6em;
}

/* Spend Tab */
.st-analytics-spend-top-row .st-details-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em 0;
  height: 100%;
}

.st-analytics-spend-top-row .st-details-list li .list-type-content {
  display: flex;
  flex-direction: column-reverse;
}

/* Pattern Tab */
.st-details-pattern-top-row .st-details-list{
  display: flex;
  align-items: center;
  gap: 1em;
  justify-content: space-between;
}

.st-details-pattern-top-row .st-detail-content,
.st-details-pattern-top-row .st-detail-content .st-details-list-main-container,
.st-details-pattern-top-row .st-detail-content .st-details-list-main-container .st-details-list {
  height: 100%;
}

.st-details-pattern-top-row .st-details-list li svg,
.st-details-behavior-top-row .st-details-list li svg {
  width: 2.5em;
  height: 2.5em;
}

.st-details-behavior-top-row .st-details-list li .list-type-content {
  flex-direction: row-reverse;
}

.st-details-pattern-top-row .st-details-list-pattern li .list-type-content h2,
.st-details-pattern-top-row .st-details-list-pattern li .list-type-content p,
.st-details-behavior-top-row .st-details-list-pattern li .list-type-content h2,
.st-details-behavior-top-row .st-details-list-pattern li .list-type-content p  {
  margin: 0;
  font-size: .8em;
}

.st-detail-color-background-list-container {
  width: calc(100% - 3em);
  padding: 2em 1em;
  margin: 1em .5em .5em .5em;
  border-radius: 5px;
  text-align: center;
  position: relative;
  bottom: 0;
  flex-grow: 1;
  background: #2B52AA;
  color: #fff;
}

.st-detail-color-background-list-container .st-details-list {
  display: flex;
  flex-direction: column;
  gap: 2em;
}

.st-details-pattern-bottom-row .details-doughnut .st-details-list-main-container {
  margin: 1em 0;
}

.st-details-pattern-bottom-row ul li .list-type-content h2 {
  margin: 0;
  font-size: .9em;
  font-weight: 400
}

.st-details-pattern-bottom-row ul li .list-type-content p {
  margin: 0;
  font-size: 1.5em;
  font-weight: 600;
}

.st-details-pattern-bottom-row .list-legend-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: .5em 0;
  gap: 1.5em;
  font-size: 95%;
}

.list-legend-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: .5em 0;
  gap: 1.5em;
  font-size: 95%;
}

.st-details-pattern-bottom-row .list-legend-type .list-legend-circle-with-header {
  display: flex;
  gap: .5em;
  align-items: center;
}

.list-legend-type .list-legend-circle-with-header {
  display: flex;
  gap: .5em;
  align-items: center;
}

.st-details-pattern-bottom-row .list-legend-type .list-legend-circle {
  width: 1em;
  height: 1em;
  border-radius: 50%;
}


.list-legend-type .list-legend-circle {
  width: 1em;
  height: 1em;
  border-radius: 50%;
}
/* Behavior Tab */
.st-details-behavior-top-row .st-details-behavior-detail-inflow-main-container .st-details-list {
  flex-direction: column;
}

.st-details-behavior-top-row .st-details-behavior-detail-inflow-main-container .st-details-list li h2{
  font-size: 1.2em;
}

.st-details-behavior-top-row .st-details-list {
  align-items: flex-start;
}

.st-details-double-colored-bottom-row ul {
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 0 3em;
}

.st-details-double-colored-bottom-row ul li .list-type-content {
  display: flex;
  justify-content: space-between;
}

.st-details-double-colored-bottom-row ul li .list-type-content h2,
.st-details-double-colored-bottom-row ul li .list-type-content p {
  margin: 0;
  font-size: 1.1em;
  font-weight: 300;
}

.st-details-double-colored-bottom-row .st-details-loan-list-container {
  background-color: #2B52AA;
  color: #fff;
}

@media only screen and (max-width: 1000px) {
  .st-details-column-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5em 2em;
    list-style: none;
    padding: 0;
    margin: 0;
  }
}