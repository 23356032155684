.page-not-found-body {
  width: calc(100% - 4em);
  height: calc(100% - 4em);
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1.5em 0;
  background: linear-gradient(90deg, #eff3fa 32%, #e8eef6 100%);
}

.page-not-found-body .page-not-found-image img {
  width: 400px;
}

.page-not-found-body .button-pill {
  padding: .5em 4em;
  font-weight: 500;
  font-size: 1.1em;
  cursor: pointer;
}

@media only screen and (max-width: 450px){
  .page-not-found-body {
    font-size: 80%;
    padding: 2em;
    width: calc(100% - 4em);
  }

  .page-not-found-body .page-not-found-image img {
    width: 200px;
  }
}