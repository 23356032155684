.form {
  width: 100%;
}

.dash-body .form {
  margin: 0;
  display: grid;
  grid-template-columns: .6fr 1.1fr 1.1fr .8fr .8fr 1.6fr;
  grid-template-rows: 1fr;
  grid-template-areas: "header name date status type buttons"; 
  gap: 1em;
  align-items: center;
}

.dash-body.decision-engine {
  width: 100% !important;
  margin-top: 40px;
  padding: 0px 0px 30px 0;
}

.dash-body.decision-engine form {
  grid-template-columns: .6fr 1.1fr 1.1fr .8fr 1.6fr;
  grid-template-areas: "header name date type buttons"; 
}

.form .header { 
  grid-area: header;
  margin: 0;
  font-weight: 500;
  font-size: 1.1em;
}

.form .name { grid-area: name; }
.form .form-date-range-container { grid-area: date; }
.form .type { grid-area: type; }
.form .status { grid-area: status; }

.form .form-date-pickers {
  display: flex;
  gap: 1em;
}

.form .form-inputs {
  border-radius: 5px;
  background: #fff;
}

.form .form-default-container {
  border: 1px solid #407BFF;
}

.form .form-buttons-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: .5em;
  grid-area: buttons;
}

.form .form-buttons-container button {
  padding: .5em 2em;
  width: 50%;
  font-weight: 500;
  font-size: .9em;
  cursor: pointer;
}

.form .form-buttons-container .clear {
  background-color: #fff;
  color: #407BFF;
  border: 1px solid #407BFF;
}

.form .form-buttons-container .search {
  background-color: #407BFF;
  color: #fff;
  border: 1px solid #407BFF;
}

.form .form-date-pickers .form-inputs {
  width: 100%;
}

.form input,
.form input::-webkit-datetime-edit {
  font-size: .9em;
  padding: .5em 1em;
}

.form input::-webkit-input-placeholder {
  font-size: .9em;
}

.form input::-moz-placeholder {
  font-size: .9em;
}

.form input:-ms-input-placeholder {
  font-size: .9em;
}

.form input:-moz-placeholder {
  font-size: .9em;
}

.form select {
  width: 100%;
  padding: .65em 1em;
  cursor: pointer;
  border-radius: 5px;
}

.dropdown-select-container {
  background: #fff;
  border: 1px solid #407BFF;
  color: #767676;
}

.dropdown-select-container option {
  background: #fff;
  color: #000;
}

.dropdown-select-container p {
  color: #767676;
}

/* Active Fields */
#active-select {
  background-color: #407BFF;
  color: #fff;
}

#active-select p {
  color: #fff;
}

#active-select img {
  filter: brightness(0) invert(1);
}

#active-input {
  background-color: #407BFF;
  color: #fff;
}

/* Dropdown */
.dropdown-body {
  position: relative;
  width: 100%;
  z-index: 11;
}

.dropdown-select-container {
  width: calc(100% - 2em);
  padding: .5em 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 1em;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #407BFF;
}

.dropdown-select-container p {
  margin: 0;
  font-size: .9em;
  color: #767676;
}

.dropdown-select-container svg {
  width: 1em;
  height: .5em;
}

.dropdown-options-container {
  width: 100%;
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  margin: .5em 0 0 0;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, .1);
}

.dropdown-options-container .dropdown-option {
  border: none;
  padding: .5em 1em;
  width: 100%;
  cursor: pointer;
  background: transparent;
}

.dropdown-options-container .dropdown-option:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #757676;
}

.dropdown-options-container button:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dropdown-option:hover {
  background: #407BFF;
  color: #fff;
}

#dropdown-date-option {
  width: 100%;
  padding: 0 0 .7em 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

#dropdown-date-option .dropdown-date-range-container {
  display: flex;
  flex-direction: column;
  gap: .2em;
  padding: .5em 1em;
  width: calc(100% - 2em);
  justify-content: space-between;
}

#dropdown-date-option .dropdown-date-range-container label {
  font-size: .8em;
  font-weight: 600;
}

#dropdown-date-option input {
  border: 1px solid #407BFF;
  background-color: rgba(255, 255, 255, .8);
}

.dropdown-header {
  padding: .5em 1em;
  font-size: 1em;
  margin: 0;
  height: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: #2A38A4;
}

#active-drop {
  background-color: #407BFF;
}

#active-drop p {
  color: #fff;
}

@media only screen and (max-width: 1400px) {
  .dash-body .form {
    grid-template-columns: .6fr 1.1fr 1.1fr 1.1fr 1.1fr; 
    grid-template-rows: 1fr 1fr; 
    grid-template-areas: 
      "header name date status type"
      ". . . buttons buttons";
  }
}

@media only screen and (max-width: 1300px) {
  .dash-body .form {
    grid-template-columns: 1fr 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr; 
    grid-template-areas: 
      "header header header header"
      "name date status type"
      "buttons buttons buttons buttons"; 
  }
}

@media only screen and (max-width: 1170px) {
  .dash-body .form {
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr; 
    grid-template-areas: 
      "header header header"
      "name date status"
      "type buttons buttons";
  }
}

@media only screen and (max-width: 950px) {
  .dash-body .form {
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr; 
    grid-template-areas: 
      "header header"
      "name name"
      "date date"
      "status type"
      "buttons buttons"; 
  }
}

@media only screen and (max-width: 400px) {
  .dash-body .form {
    display: flex;
    flex-direction: column;
  }

  .dash-body .form .form-inputs {
    width: 100%;
  }

  .dash-body .form .form-default-container {
    width: calc(100% - 2em);
  }

  .form .form-buttons-container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }

  .form .form-buttons-container button {
    width: 100%;
  }
}