.supportButton{
    width:250px;
    height:40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color:white;
    background-color: rgba(64, 123, 255, 1);
    cursor: pointer;
}

.supportClassHold{
    margin: 20px 0px;
    background-color: white;
    padding: 15px
}

.docHolder{
    background-color:white;
    margin-top: 25px;
    padding:10px;
    display:flex;
    justify-content:space-between;
    align-items: center;
    border-radius: 10px;
    height:60px;
    flex-basis: 45%;
    border: 1px dotted rgba(64, 123, 255, 1);
    
}