.action-menu {
  position: relative;
  width: fit-content;
}

.action-menu button {
  border: none;
  background-color: transparent;
}

.action-menu .action-menu-toggle-button {
  width: 5px;
}

.action-menu .action-links-container {
  background-color: #fff;
  box-shadow: 0 0 50px rgba(0, 0, 0, .06);
  position: absolute;
  top: 1em;
  left: -1em;
  z-index: 3;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1.5em 1em;
  width: 150px;
}

.action-menu .action-links-container button {
  justify-content: flex-start;
  color: #292D32;
  font-size: 1em;
}

@media only screen and (max-width: 1450px) {
  .action-menu .action-links-container {
    right: 1em;
    left: unset;
  }  
}

@media only screen and (max-width: 900px) {
  .action-menu .action-links-container {
    right: unset;
    left: 1em;
  }
}

@media only screen and (max-width: 300px) {
  .action-menu .action-links-container {
    left: 0;
    top: 1em;
  }
}