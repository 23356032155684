.buttonAml{
    width:120px;
    height:35px;
    border-radius:7px;
    background-color:#407BFF;
    color:white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.InitialAmlModal{
    display:flex;
    flex-direction:column;
    height:350px;
    justify-content:space-around;
    align-items:center;
}

.title{
    color:#2A38A4;
    font-weight:700;
    font-size:24px
}

.info{
    color:#363C4F
}

.FetchAmlModal{
    display:flex;
    flex-direction:column;
    height:350px;
    row-gap:10px;
    padding-bottom:15px;
    box-sizing:border-box;
    justify-content:space-around;
    align-items:center
}

