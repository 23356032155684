/* Lending SMS */
.mb-lending-sms .mb-lending-header-container {
  display: flex;
  align-items: center;
  gap: 1em;
  margin: 1em 0;
}

.mb-lending-sms .mb-lending-header-container .no-logo-url {
  background: #1C256D;
  color: #fff;
}

.mb-lending-sms .mb-lending-header-container img {
  width: 3em;
  height: 3em;
  border-radius: 5px;
}

.mb-lending-sms .mb-lending-header-container .mb-lending-header-text-container {
  display: flex;
  flex-direction: column;
}

.mb-lending-sms .mb-lending-header-container .mb-lending-header-text-container h2,
.mb-lending-sms .mb-lending-header-container .mb-lending-header-text-container p {
  margin: 0;
}

.mb-lending-sms .mb-lending-sms-type-table tbody {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.mb-lending-sms .mb-lending-sms-type-table tbody tr {
  display: flex;
  flex-direction: column;
  gap: .5em;
  padding: 2em;
  background: #fff;
}

.mb-lending-sms .mb-lending-sms-type-table tbody tr td {
  padding: 0;
}

.mb-lending-sms .mb-lending-sms-type-table tbody tr .table-cell-with-caption {
  display: flex;
  gap: 1em;
}

.mb-lending-sms .mb-lending-sms-type-table tbody tr .table-cell-with-caption label {
  font-size: 1.2em;
  font-weight: 500;
}

.mb-lending-sms .mb-lending-sms-type-table tbody tr .table-cell-with-caption h3 {
  margin: 0;
  font-size: 1.2em;
}

.mb-lending-sms .mb-lending-sms-type-table tbody tr td:nth-child(2) {
  margin: 1em 0 0 0;
  font-size: 1.1em;
}

.mb-lending-sms .mb-details-no-table-data-container {
  align-items: center;
}