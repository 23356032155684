.navbar {
  padding: 2.5em 2.5em;
  width: calc(100% - 5em);
  position: relative;
  z-index: 1;
  top: 0;
  margin: 0 0 1em 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.navbar h2 {
  font-size: 2em;
  margin: 0em 0 .2em 0;
}

.navbar p {
  margin: 0;
  font-size: 1.1em;
}

.bellIcon{
    display: flex;
    justify-content:center;
    align-items:center;
    padding:10px;
    background-color:white;
    height:60px;
    box-sizing:border-box;
    border-radius:10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tenantSelectorUl{
  border:none;
  padding:15px;
  display:flex;
  height:60px;
  box-sizing:border-box;
  justify-content: space-between;
  outline:none;
  width:280px;
  align-items:center;
  border-radius:10px;
  box-shadow:rgba(0, 0, 0, 0.1);
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.alignImages{
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.alignImages img{
  width:30px
}

.tenantList{
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  position: absolute;
  top:50px;
  width: 75%;
  max-height: 250px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width:7px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
      background: #6488EA;

      border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: lightgray;
    margin-top: 10px;
  }
}


.tenantList li{
  padding: 10px;
  box-sizing: border-box;
  border: 0.1px solid #ccc;
  border-width: 0px 0px 0.1px 0px;
  text-decoration: none;
  list-style: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 10px;
}

.indTenantName{
  width: 200px;              /* Define a width for the element */
  white-space: nowrap;        /* Prevent text from wrapping to a new line */
  overflow: hidden;           /* Hide the overflowing text */
  text-overflow: ellipsis;
}

.tenantSelector{
  border:none;
  padding:15px;
  display:flex;
  height:60px;
  box-sizing:border-box;
  justify-content:flex-start;
  outline:none;
  width:220px;
  align-items:center;
  border-radius:10px;
  box-shadow:rgba(0, 0, 0, 0.1);
  background-color: white;
}

.mainTenantHold{
  display:flex;
  justify-content:center;
  align-items:flex-start;
  column-gap:10px;
  width:auto;
  position: relative;
}

.tenantTextHolder{
  overflow-x: auto;
  width:80%;

  &::-webkit-scrollbar {
    height:7px;
    background: #6488EA;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
      background: #6488EA;

      border-radius: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: lightgray;
    margin-top: 10px;
  }
}