.tenantHeader{
    width: 100%;
    height:200px;
    padding:20px;
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.tenantHeader .Manage{
    color:#2A38A4;
    font-size: 22px;
    font-weight: 700;
    padding:15px;
    border: 1px solid #DFE8FF;
    border-width: 0px 0px 1px 0px;
}

.exploretab{
    color:#363C4F;
    font-size: 18px;
    font-weight: 500;
}

.exploretabLower{
    color:#363C4F;
    font-size: 14px;
    font-weight: 400;
}

.tenantButton{
    background-color: #407BFF;
    color: white;
    border-radius: 7px;
    width:150px;
    padding:5px 5px;
    height:45px;
    cursor: pointer;
    display:flex;
    justify-content:center;
    align-items:center;
}

.tenantInfoParent{
    display:flex;
    justify-content:space-between;
    margin-top: 30px;
}

.tenantInfoHold{
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items:flex-start;
    row-gap:15px
}