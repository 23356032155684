.successHoldBody{
    background-color:white;
    width:100%;
    border-radius:10px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin-top: 50px;

}

.imgFlex{
    display: flex;
    justify-content: center;
    align-items: center;
}

.successHoldBody .cardInfo{
    color: #2A38A4;
    font-size: 22px;
    text-align: center;
    font-weight: 700;
}

.successHoldBody .cardDirection{
    color: #363C4F;
    font-size: 16px;
    text-align: center;
}

.successHoldBody .cardFailed{
    color: red;
    font-size: 16px;
    text-align: center;
}

.successHoldBody .button{
    background-color: #2A38A4;
    color: white;
    width:150px;
    border-radius: 10px;
    padding: 12px;
    text-align: center;
    cursor: pointer;
}

 .cancelEditCard{
    background-color:  white;
    color: #2A38A4;
    width:150px;
    border-radius: 10px;
    padding: 12px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #2A38A4;
    border-radius: 10px;
}

 .saveEditCard{
    background-color: #2A38A4;
    color: white;
    width:150px;
    border-radius: 10px;
    padding: 12px;
    text-align: center;
    cursor: pointer;
}