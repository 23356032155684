.imageAndCancelPreHold{
    text-align: right;
    height:40px;
    color:white;
    font-size: 12px;
    background-color:rgba(64, 123, 255, 1);
    width: 200px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    cursor: pointer;
  }

  .imagePreHold{
    text-align: right;
    width: 65%;
    overflow: hidden;
}

.imageAndCancelPreHold .imgCancel{
    display:flex;
    align-items:center;
    justify-content:center
}