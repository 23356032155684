.modal-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 2em;
    width: calc(100% - 4em);
    gap: 1em;
  }
  
  .modal-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: 600;
    width: 100%;
  }
  
  .modal-header h2 {
    margin: 0 2em 0 0;
  }
  
  .header-single {
    justify-content: center;
  }
  
  .header-single h2 {
    text-align: center;
    margin: 0;
  }
  
  .modal-dialog .close {
    text-align: center;
    cursor: pointer;
  }
  
  .modal-dialog .close img {
    width: 2em;
  }
  
  .modal-dialog .modal-header button {
    font-size: 1.5em;
  }
  
  .modal-body-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2em;
  }
  
  .modal-body-container.scrollbar {
    width: 100%;
    min-height: auto;
    max-height: calc(100vh - 16em);
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .modal-body-container .scrollable-content {
    margin: 0 10px 0 0;
  }
  
  .centered-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .centered-container svg {
    width: 3.5em;
    height: 3.5em;
  }
  
  .centered-container p {
    margin: .5em 0 0 0;
  }
  
  .submit-container {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1em;
  }
  
  .submit-container button {
    padding: 0.5em 3em;
    font-size: 1.05em;
    width: 100%;
    cursor: pointer;
  }
  
  .modal-body-message {
    display: flex;
    flex-direction: column;
    gap: 1em;
    margin: 0 0 1em 0;
  }
  
  .loading-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 20px;
  }
  
  /* ADD PDF */
  .add-pdf-file-rejected-error-message {
    font-size: .9em;
  }
  
  .modal-dialog .drop-container {
    border-color: #407BFF;
  }
  
  .modal-dialog .drop-container img {
    width: 4em;
    height: 4em;
  }
  
  .modal-dialog .drop-container p {
    text-align: center;
    margin: 0 2em;
  }
  
  .modal-dialog .drop-container .drop-caption {
    font-weight: 500;
    font-size: 1.2em;
    width: 60%;
    margin: 0 2em 2em 2em;
  }
  
  .modal-dialog .drop-container .drop-caption span {
    cursor: pointer;
  }
  
  .modal-dialog .drop-container .drop-subcaption {
    font-size: .8em;
    font-weight: 500;
  }
  
  .modal-dialog .modal-body-container h4 {
    font-size: 1.3em;
    margin: 1.5em 0 .5em 0;
  }
  
  /* Accepted File */
  .modal-dialog .ap-accepted-file-container {
    border-radius: 10px;
    padding: 1em;
    width: calc(100% - 2em);
    border: 1px solid #407BFF;
    margin: 0 0 2em 0;
  }
  
  .modal-dialog .ap-accepted-file-container ul {
    padding: 0;
    margin: 1em 0 1.5em 0;
    list-style: none;
  }
  
  .modal-dialog .ap-accepted-file-container ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-dialog .ap-accepted-file-container ul li .ap-accepted-file-info {
    display: flex;
    align-items: center;
    gap: .5em;
  }
  
  .modal-dialog .ap-accepted-file-container ul li .ap-accepted-file-info img {
    width: 2.5em;
    height: 2.8em;
  }
  
  .modal-dialog .ap-accepted-file-container ul li .ap-accepted-file-info h4 {
    margin: 0;
    font-size: 1.1em;
  }
  
  .modal-dialog .ap-accepted-file-container ul li .ap-accepted-file-info span {
    font-size: .9em;
    font-weight: 500;
  }
  
  .modal-dialog .ap-accepted-file-container ul li button img {
    width: 1.8em;
    height: 1.8em;
  }
  
  .modal-dialog .ap-accepted-file-container .delete-pdf-button {
    background: transparent;
    border: none;
  }
  
  /* Statement Type Radio Buttons */
  .ap-statement-type-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
  }
  
  .ap-statement-type-radio-buttons-container {
    display: flex;
    gap: 0 2em;
  }
  
  .ap-statement-type {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    gap: 0 1em;
  }
  
  .modal-dialog .modal-body-container .ap-statement-type-container h4 {
    width: 100%;
    font-weight: 600;
    font-size: 1em;
    margin: .5em 0;
  }
  
  .modal-dialog .ap-statement-type input {
    width: 1.2em;
    height: 1.2em;
  }
  
  /* Statement Password */
  .ap-password-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1.5em 0 1em 0;
    gap: .5em;
  }
  
  .modal-dialog .modal-body-container .ap-password-container h4 {
    font-weight: 600;
    font-size: 1em;
    margin: 0;
  }
  
  .modal-dialog .modal-body-container .ap-password-container h4 span {
    font-weight: 400;
    font-size: .8em;
    font-style: italic;
  }
  
  .ap-password-container input {
    width: calc(100% - 2em);
    padding: 1em;
    border: 1px solid #407BFF;
    border-radius: 5px;
  }
  
  /* DOWNLOAD PDF */
  .modal-dialog.download-dialog form .modal-header {
    width: 100%;
    margin: 0 0 1em 0;
  }
  
  .modal-dialog.download-dialog form hr {
    margin: 0 0 1em 0;
  }
  
  .modal-dialog.download-dialog form button {
    margin: 2em 0 0 0;
  }
  
  .modal-dialog.download-dialog .download-inputs {
    display: grid;
    grid-template-areas: 'auto auto' 'auto auto';
    gap: .5em;
  }
  
  .modal-dialog.download-dialog .download-input-container {
    padding: .5em 1em;
    border-radius: 20px;
    background: #d1d5de;
  }
  
  .modal-dialog.download-dialog .modal-body-container .modal-body-message ul {
    margin: 0;
  }
  
  .spinner-container {
    text-align: center;
    margin: 3em 5em 0 5em;
  }
  
  /* PAYMENT */
  .payment-main-body {
    width: 20em;
    padding: 1em .5em;
    display: flex;
    flex-direction: column;
    gap: 1em 0;
  }
  
  .payment-main-body .payment-form {
    display: flex;
    flex-direction: column;
    gap: .5em;
  }
  
  .payment-main-body .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .payment-main-body .header h2 {
    margin: 0;
  }
  
  .payment-main-body .close {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
  
  .payment-main-body .close img {
    width: 2em;
    height: 2em;
  }
  
  .payment-field-container {
    display: flex;
    flex-direction: column;
    gap: .5em 0;
  }
  
  .payment-field-container input {
    width: calc(100% - 1em);
    padding: .5em;
    border-radius: 5px;
  }
  
  .payment-input-error-span {
    font-size: .9em;
  }
  
  .payment-field-container .input-container {
    padding: 0 0 0 .5em;
  }
  
  .payment-field-container .input-container input {
    padding: .5em;
    margin: 0 0 0 .5em;
    border: none;
    border-radius: 0 5px 5px 0;
  }
  
  .payment-button {
    width: 100%;
    font-weight: 500;
    font-size: 1.05em;
    padding: .5em 0;
    cursor: pointer;
    margin: 1em 0 0 0;
    border: none;
  }
  
  .payment-button:disabled {
    opacity: .7;
    cursor: default;
  }
  
  /* Payment Providers */
  .payment-provider-container {
    margin: 1em 0;
    display: flex;
    flex-direction: column;
    gap: .5em 0;
  }
  
  .payment-provider-container select {
    padding: .5em;
    border-radius: 5px;
  }
  
  
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 300ms ease-in-out;
    height: 100%;
  }
  
  .ReactModal__Overlay--after-open{
    opacity: 1;
    position: fixed !important;
  }
  
  .ReactModal__Content--after-open {
    height: unset !important;
    overflow: unset !important;
    inset: unset !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    border-radius: 20px !important;
    border: unset !important;
    box-shadow: 0 4px 6px 4px #00000030;
  }
  
  .ReactModal__Overlay--before-close{
    opacity: 0;
  }
  
  .required{
    color:red;
  }
  
  .unique-id-info{
    font-size: 0.7em;
    color: gray;
  }
  
  @media only screen and (max-width: 960px) {
    .ReactModal__Content--after-open {
      width: 70% !important;
    }
  }
  
  @media only screen and (max-width: 500px) {
    .ReactModal__Content--after-open {
      width: 90% !important;
    }
  }
  
  