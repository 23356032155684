.uploadWrapper {
    width:100%;
    display:flex;
   background-color: #F0F6FF;
   border: 2px dotted #407BFF;
   justify-content: center;
   align-items: center;
   min-height: 179px;
   flex-direction: column;
}

.uploadDesc {
    width:50%
}

.uploadDesc p {
text-align: center;



}

.uploadInput {
    color: #407BFF;
    cursor: pointer;
    
}

.dragTitle {
    margin-bottom: 10px;
    line-height: 20.83px;
font-size: 16px;
font-weight: 400;
}


.uploadLimitDesc {
    font-weight: 500;
    font-size: 10px;
    line-height: 13.02px;
    color:#66737F;
    margin-top: 10px;
}

.selectedWrapper {
    margin-top: 26px;
}
.selectedHeader {

    font-size: 12px;
    line-height: 15.62px;
    font-weight: 700;
    color:#6A717D;
    text-transform: uppercase;

}

.formWrapper {
    padding: 15px;
    border-radius: 15px;
    background-color:#F0F6FF ;
    border: 1px solid  #DFE8FF ;
    margin-top: 20px;
}

.inputWrapper {
    margin-bottom: 30px;
}

.formInput {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
}

.textFieldInput {
    flex: 1
}

.inputContainer {
    border: 1px solid #6A717D;
  
}

.inputContainer:focus-within, .inputContainer:hover {
    border:1px solid #6A717D
}

.textContainer {
    height: 40px;
}

.optionalName {
    color: #66737F;
    font-size: 10px;
    font-style: italic;
    line-height: 13.02px;
    font-weight: 500;
    margin-left: 8px;
}

.btnWrapper {
    display:flex;
    align-items: center;
    justify-content: center;
}

.submitBtn {
    background-color: #407BFF;
    color:#fff;
    width: 200px;
    height: 56px;
    font-size: 18px;
    line-height: 23.44px;
    font-weight: 700;
    outline: none;
    border: none;
    border-radius: 5px;
}


.submitBtn:disabled {
    cursor:not-allowed;
    opacity: 0.5;
}

