.buttonGroup {
    display: flex;
    gap:10px;
}


.button {
padding: 18px 20px 18px 20px;
border-radius: 4px;
width: 147px;
max-height: 32px;
white-space:nowrap;
font-family: inherit;
outline: none;
border:none;
background-color: transparent;
font-weight: 500;
font-size: 14px;
line-height: 18.23px;
}

.contained {
    composes: button;
    background-color:#2B52AA;
    color: #fff;
}

.outlined {
    composes: button;
    border: 1px solid #2B52AA;
    color: #2B52AA;
    background-color: #fff;
}

.customFormBtnWrapper {
    display: flex;
   justify-content: flex-end;
    width: 100%;
    margin-top: 30px;
}

.customRuleSaveBtn {
    background-color: #407BFF;
    padding: 16px;
    outline: none;
    border: none;
    color:#fff;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
    line-height: 20.83px;
    width: 113px;
    white-space: nowrap;
    max-height: 50px;
}


/* .tableWrapper {
    padding: 20px;
} */







