.table-container {
  position: relative;
  margin-top: 30px;
  padding: 0 15px;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 .35em;
}

table thead {
  font-size: 1em;
}

thead tr th {
  color: #000;
  font-weight: 700;
}

thead tr th span {
  font-size: .8em;
}

table tbody {
  font-size: .9em;
}

table tbody td {
  font-weight: 400;
}

table thead tr {
  text-align: left;
}

table tbody tr {
  border-radius: 5px;
}

table tbody tr td:nth-child(1) {
  text-align: left
}

table th,
table td {
  padding: 1em;
}

.table-row-two-colored:nth-child(odd) {
  background-color: #F6F8FC;
}

.table-row-two-colored:nth-child(even) {
  background-color: #D9E5FF;
}

.tableAudit{
  background-color: #91b2f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.table-row-two-colored-audit:nth-child(odd) {
  background-color: #F6F8FC;
}

.table-row-two-colored-audit:nth-child(even) {
  background-color: #D9E5FF;
}

.table-two-col tr {
  justify-content: space-between;
  margin: 0 .5em 0 0;
}

.table-two-col tr th,
.table-two-col tr td {
  width: unset !important;
  padding: .5em !important;
}

.table-align-right-last-row tr th,
.table-align-right-last-row tr td {
  padding: .5em !important;
  width: calc(40% - 1em) !important;
}

.table-align-right-last-row tr th:last-child,
.table-align-right-last-row tr td:last-child {
  text-align: end;
  padding: 0 .5em 0 0;
  width: calc(20% - 1em) !important;
}

/* Filter Row */
#global-filter {
  background: rgba(255, 255, 255, .8);
  border: 1px solid rgba(0, 0, 0, .2);
}

.tb-filter-container {
  position: absolute;
  right: 1em;
  top: 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 1;
  border-radius: 5px;
  padding: .2em .5em;
  border: 2px solid #407BFF;
}

.filter-primary-darkest {
  border: 1px solid #2A38A4;
}

.tb-filter-container button {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: .5em;
  font-weight: 600;
  cursor: pointer;
}

.tb-filter-container button svg {
  width: 1.2em;
  height: 1.2em;
}

.tb-filter-container .tb-filters {
  width: 350px;
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1.5em 1em 1em 1em;
  z-index: 9;
  position: absolute;
  right: 0;
  top: 2em;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, .1);
}

.table-filter-field-container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.table-filter-field-container .table-date-range-container {
  display: flex;
  align-items: center;
  gap: .5em;
}

.tb-filter-container .tb-filters label {
  font-weight: 600;
  font-size: .9em;
}

.tb-filter-inputs {
  padding: .6em;
  width: calc(100% - 1.2em);
  margin: .2em 0 0 0;
  background: rgba(255, 255, 255, .8);
  border: 1px solid rgba(0, 0, 0, .2);
  height:35px;
  box-sizing: border-box;
}

.table-filter-field-container select {
  width: 100%;
  height: 35px;
}

.tb-filter-default {
  width: calc(100% - 1.2em);
}

.tb-filter-select {
  width: 100%;
  height: 100%;
  padding: .6em 0;
  border: none;
  border-radius: 5px;
  background-color: transparent;
}

/* Top Table Filter */
.tb-filter-top-header {
  margin: 0 0 .5em 0;
}

.tb-filter-top-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.tb-filter-top-container .table-filter-field-container {
  flex: 1 0 250px;
}

.tb-filter-top-container span {
  font-weight: 500;
}

.tb-filter-top-container .table-filter-field-container  .table-date-range-container {
  display: flex;
  gap: .5em;
  align-items: center;
}

.tb-filter-top-container .table-filter-field-container .tb-filter-inputs {
  margin: 0;
}

/* Date Range Picker */
table .table-date-range-container {
  display: flex;
  width: 100%;
  align-items: center;
}

table .table-date-range-container span {
  text-align: center;
  font-weight: 500;
  margin: 0 .3em;
}

/* Top Table Filter */
.tb-filter-top-header {
  margin: 0 0 .5em 0;
}

.tb-filter-top-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.tb-filter-top-container .table-filter-field-container {
  flex: 1 0 250px;
}

.tb-filter-top-container span {
  font-weight: 500;
}

.tb-filter-top-container .table-filter-field-container  .table-date-range-container {
  display: flex;
  gap: .5em;
  align-items: center;
}

.tb-filter-top-container .table-filter-field-container .tb-filter-inputs {
  margin: 0;
}

/* Link Buttons */
.table-row-buttons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.table-row-buttons {
  padding: .5em 2em;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
}

/* Global Filter with Button */
.tb-global-and-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  margin: 0 0 2em 0;
}

.global-filter-with-button-input-container {
  width: 40%;
  display: flex;
  gap: 1em;
  border: 2px solid #DFE8FF;
  border-radius: 4px;
  padding: .5em 1em;
}

.global-filter-with-button-input-container input {
  width: 100%;
  border: none;
  background-color: transparent;
}

.global-filter-with-button-input-container input:focus {
  outline: none;
}

.global-filter-with-button-input-container input::placeholder {
  color: #5D6272;
  font-style: italic;
}

.tb-global-and-button-container button {
  padding: 1em 2em;
  border-radius: 4px;
}

/* Pagination */
.pagination {
  width: calc(100% - 2em);
  padding: 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0 4em;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.pagination .pagination-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 1em;
}

.pagination-center .pagination-page-number-button,
.pagination-center .pagination-dots {
  border: none;
  background-color: transparent;
  font-size: 1em;
  color: #C4C4C4;
  font-weight: 600;
  cursor: pointer;
}

.pagination-center .pagination-page-number-button#active-button-page {
  color: #2A38A4;
}

.pagination-center .pagination-input {
  border: 1px solid #DFE8FF;
  width: 2em;
  border-radius: 2px;
}

.pagination-center .pagination-input:focus {
  outline: none;
  border: 1px solid #407BFF;
}

.pagination-arrows {
  display: flex;
  flex-direction: row;
  gap: 0 1em;
}

.pagination-arrows button {
  cursor: pointer;
}

.pagination-arrows button:disabled {
  cursor: unset;
}

.pagination-arrows button {
  border: none;
  background-color: transparent;
  font-size: 1.1em;
  font-weight: 500;
  cursor: pointer;
}

.pagination-arrows button:disabled {
  opacity: .4;
  cursor: unset;
}

.pagination span {
  margin: 0 1em;
}


@media only screen and (max-width: 900px) {
  table tbody tr {
    margin: 0 0 1em 0;
  }

  table thead th {
    padding: .4em 1em .4em 0;
    display: block;
    width: calc(100% - 1em) !important;
  }

  table,
  table thead,
  table tbody, 
  table tr { 
		display: block; 
	}

  table tbody td {
    border: none;
    display: block;
    width: calc(100% - 1em) !important;
  }
	
	td:before { 
		position: absolute;
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
	}
}

@media only screen and (max-width: 500px) {
  .pagination {
    flex-direction: column;
    align-items: center;
    gap: .5em;
  }
}