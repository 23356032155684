.backWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    padding-top: 30px;
    text-decoration: none;
    color:inherit
}

.bodyWrapper {
    width: calc(100% - 5em);
    padding: 0 2.5em 4em 2.5em;
    position: relative;
}







