.engine-page-header {
    border-bottom: solid 1px #C3D4FF;
    padding: 40px 40px 20px;
}

.de-subhead-wrap {
    display: flex;
    align-items: center;
    margin-top: 30px;
    gap: 15px;
}

.de-body {
    height: 100%;
    padding: 25px 40px;
}

.de-body .cards.cs-history-container {
    margin-top: 30px;
    
}

.de-body .cs-ns-current-container {
    background-color: transparent;
    margin-top: 50px;
}

.de-body .required-info {
    color:rgb(228, 0, 0) ;
}

.de-sub-page-wrap {
    display: block;
    height: 100%;
}

.subpage-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.subpage-header h3 {
    font-size: 1.5rem;
    color: #000;
    margin: 0;
    font-weight: 600;
}

.subpage-header p {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 5px;
    color: #55575A;
}

.subpage-header .flex-overall .flex-box {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 5px;
}

.subpage-header .flex-overall .flex-box img {
    width: 25px;
}

.subpage-header .flex-overall .flex-box p {
    color: #130F26;
    font-size: 1.2em;
    font-weight: 700;
    margin: 0;
}

.subpage-header .flex-overall .flex-box p span {
    color: #6A717D;
    font-weight: 400;
}