.modal-dialog.credit-score {
    justify-content: center;
    padding: 3em 10em;
    width: unset;
}

.modal-dialog.credit-score .modal-header.header-single svg {
    width: 140px;
}

.modal-dialog.credit-score .modal-header.header-single img {
    width: 60px;
}

.modal-dialog.credit-score .modal-body-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    justify-content: center;
    width: unset;
}

.modal-dialog.credit-score .modal-body-container h2 {
    text-align: center;
    color: #000;
    font-size: 1.8em;
}

.modal-dialog.credit-score .modal-body-container p {
    text-align: center;
    margin: 0;
}

.modal-dialog.credit-score .modal-body-container button {
    padding: 1em 3em;
}

.modal-dialog.credit-score .modal-body-container button.done-btn {
    width: unset;
}

.modal-dialog.add-csv {
    padding: 1.5em;
    width: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-dialog.add-csv .modal-body-container {
    width: 100%;
}

.modal-dialog.add-csv .drop-container img {
    width: 40px;
    filter: invert(37%) sepia(77%) saturate(6973%) hue-rotate(212deg) brightness(93%) contrast(87%);
}

.modal-dialog.add-csv .drop-container .drop-caption {
    color: #407BFF;
    cursor: pointer;
}