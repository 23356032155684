.statement-card-wrapper {
    border-radius: 5px;
    background-color: #fff;
    flex-grow: 1;
    flex-basis: 0;
}

.header-card {
    display: flex;
    padding: 10px;
    background-color: #004ABA;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    color: #fff;
    align-items: center;
    justify-content: space-between;
}

.header-card .left-section-card {
    display: flex;
    align-items: center;
    gap: 10px;
}

.header-card img {
    width: 20px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(98%) saturate(0%) hue-rotate(27deg) brightness(107%) contrast(101%);
}

.header-card p {
    font-size: 0.8rem;
    margin: 0;
}

.header-card h3 {
    font-size: 1.9rem;
    margin: 0;
}

.content-card {
    padding: 18px 25px;
}

.content-card div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.7em;
}

.content-card div p {
    margin: 0;
    font-size: 0.8rem;
}

.content-card div .value-text {
    font-size: 1.1rem
}

.processed-info p {
    color: green;
}

.failed-info p {
    color: red;
}
