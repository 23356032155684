.permissions-body .scrollbar {
  width: 100%;
  min-height: auto;
  max-height: calc(100vh - 20em);
  overflow-y: auto;
  overflow-x: hidden;
}

.permissions-body .scrollable-content {
  margin: 0 5px 0 0;
}

.permissions-body p.text-darker {
  margin: .5em 0 2em 0;
}

/* Permission Group */
.permission-group {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.permission-group:not(:first-child) {
  margin: 2em 0 .5em 0;
}

/* Permission Row*/
.permission-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}

.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 20px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.switch input:focus { 
  outline: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #D9D9D9;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 5px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #0CBC8B;
}

input:focus + .slider {
  box-shadow: 0 0 1px #0CBC8B;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}