.containerWrapper {
    width: 80%;
    margin:auto;
    text-align: center;
}

.textWrapper  {
    margin-bottom: 30px;
}

.textWrapper h5 {
color: #2A38A4;
font-size: 24px;
line-height: 31.25px;
font-weight: 700;
margin-bottom: 10px;
}

.textWrapper p {
 color:#363C4F;
    font-size: 16px;
    line-height: 20.83px;
    font-weight: 400;
    
}

.btnWrapper {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.contained {
    text-align: center;
    padding:20px;
    background-color: #407BFF;
    color: #fff;
    border-radius: 8px;
    outline: none;
    border: none;
    height: 47.52px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 20.83px;
    font-weight: 400;

}
.confirmText {
    font-size: 20px;
    line-height: 26.04px;
    font-weight: 700;
    color:#E80000;
    text-align: center;
    width:331px;
}

.btnGroup {
    display: flex;
    gap: 10px;
    margin-top:50px;
}

.button {
    padding: 18px 20px 18px 20px;
    border-radius: 4px;
    width: 147px;
    max-height: 32px;
    white-space:nowrap;
    font-family: inherit;
    outline: none;
    border:none;
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
    line-height: 18.23px;
    }
    
    .confirmContained {
        composes: button;
        background-color:#E80000;
        color: #fff;
    }
    
    .confirmOutlined {
        composes: button;
        border: 1px solid #8F9CA8;
        color: #8F9CA8;
        background-color: #fff;
    }

    .confirmContained:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }