.dash-body {
  width: calc(100% - 5em);
  padding: 0 2.5em 4em 2.5em;
  position: relative;
}

.top-cards-container {
  display: flex;
  flex-direction: row;
  width: calc(100% - 2em);
  margin: 0 0 2.5em 0;
  gap: 1em;
  padding: 1em;
  border-radius: 5px;
  border: 1px solid #407BFF;
}

.top-cards-container .st-details-card {
  justify-content: space-between;
}

.top-cards-container .st-details-card .details-header-row {
  display: flex;
  flex-direction: column-reverse;
}

.top-cards-container .st-details-card .details-header-row h3 {
  font-size: 1.7em;
  color: #2A38A4;
}

.top-cards-container .st-details-card .details-header-row h4 {
  font-size: 1em;
}

.top-cards-container .st-details-card .st-details-icon-container svg {
  height: 2.5em;
}

.top-cards-container .st-details-card .st-details-icon-container .wallet-icon {
  width: 3em;
}

.dash-statement-add-container {
  display: flex;
  gap: 2em;
  margin: 0 0 2em 0;
  align-items: center;
}

.dash-statement-add-container button {
  padding: .5em 2em;
  font-weight: 600;
  font-size: .9em;
  cursor: pointer;
}

/* Search */
.dash-body .st-search-container {
  padding: .7em;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #fff;
}

.dash-body .st-search-container#no-statements-seach {
  display: none;
}

/* Table */
.dash-body table thead th:first-child {
  padding: 1em 0 1em 1em;
}

.dash-body table tbody tr {
  transition: all .1s ease-in-out;
  background: linear-gradient(106deg, rgba(255, 255, 255, .8) 0%, rgba(255, 255, 255, .4) 100%);
}

.dash-body table tbody td:first-child {
  width: 25%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  word-break: break-all;
}

.dash-body table tbody td:nth-last-child(2),
.dash-body table tbody td:nth-last-child(3) {
  font-weight: 600;
}

.dash-body table tbody .table-row-buttons {
  background-color: #2B52AA;
  color: #fff;
}

.dash-body .table-date-range-container .tb-filter-inputs {
  width: calc(100% - 1.2em);
}

.table-row-status-container {
  display: flex;
  flex-direction: row;
  gap: 0 1em;
  align-items: center;
}

.table-row-status-container .status-circle {
  width: .6em;
  height: .6em;
  border-radius: 50%;
}

.dash-body .pagination {
  background-color: #fff;
}

/* No Table data */
.dash-body .dash-no-statement-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;
  margin: 1em 0 0 0;
}

.dash-body .dash-no-statement-data button {
  padding: .7em 3.5em;
  font-size: 1em;
  border: none;
  width: fit-content;
  margin: 2.5em 0 0 0;
}

.dash-body .dash-no-statement-data h2 {
  margin: 0 0 .2em 0;
  font-style: italic;
}

.dash-body .dash-no-statement-data p {
  margin: 0;
}

.selfBoxHold{
  display: flex;
  column-gap: 15px;
  margin:35px 0px;
}

.selfBoxHold .selfGenerate{
  color: rgba(42, 56, 164, 1);
  background-color: white;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    height:50px;
    width:230px;
    border: 0.1px solid rgba(42, 56, 164, 1);
    cursor: pointer;
}

.selfBoxHold .selfManage{
    background-color: rgba(42, 56, 164, 1);
    color: white;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    height:50px;
    width:230px;
    cursor: pointer;
}


@media only screen and (max-width: 1090px) {
  .top-cards-container .dc-status-icon-type {
    flex-direction: column;
    align-items: flex-start;
  }

  .top-cards-container .dc-icon-visual-details {
    margin: 1em 0 0 0;
  }
}

@media only screen and (max-width: 900px) {
  .dash-body .table-container {
    margin: 1em 0 0 0;
  }

  .dash-body .tb-filter-container {
    right: 0;
  }

  .dash-body table thead tr th:last-of-type {
    padding: 1em 0;
  }

  .dash-body table tbody tr td:nth-last-child(-n+2) {
    width: unset;
  }

  .dash-body table thead th:first-child {
    padding: .4em 1em .4em 0;
  }

  .dash-body td:first-child { 
    padding-left: 1em;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .dash-body table tbody td {
    width: unset !important;
		position: relative;
    text-align: center;
  }

  .dash-body .table-row-buttons-container {
    justify-content: center;
  }

  .top-cards-container {
    flex-direction: column;
  }
}

@media only screen and (max-width: 600px) {
  .ReactModal__Content--after-open {
    width: 75% !important;
  }
}

@media only screen and (max-width: 500px) {
  .top-cards-container {
    justify-content: unset;
  }
}