.createEngineBut{
    background-color: #2A38A4;
    color: white;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    height:50px;
    width:230px;
    cursor: pointer;
}

.selfCards{
    background-color:white;
    padding:10px;
    display:flex;
    justify-content:space-around;
    flex-direction:column;
    border-radius: 10px;
    height:180px;
    flex-basis: 250px;
}