button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.button-square {
  border-radius: 5px;
}

.button-pill {
  border-radius: 20px;
}

.button-outline {
  background: transparent;
  border: 1px solid #407BFF;
  color: #407BFF;
}

.button-solid {
  background: #407BFF;
  border: 1px solid #407BFF;
  color: #fff;
}

.button-outline:hover,
.button-solid:hover {
  opacity: .7;
}

.button-solid-darker {
  background: #2A38A4;
  border: 1px solid #2A38A4;
  color: #fff;
}

.button-solid-darker:hover {
  background: #24308c;
  border: 1px solid #24308c;
}

.button-solid-darkest {
  background: #1C256D;
  border: 1px solid #1C256D;
  color: #fff;
}

.button-solid-darkest:hover {
  opacity: .7;
}

.button-solid-white {
  background: #fff;
  color: #407BFF;
  border: none;
}

.button-solid-white:hover {
  background: #EEEEEE;
  box-shadow: none;
}

.button-link {
  display: flex;
  gap: .5em;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  padding: 0;
}

.button-link:hover {
  filter: brightness(10%);
}

.button-link:disabled {
  opacity: .5;
  cursor: unset;
}

.button-link:disabled:hover {
  filter: unset;
}

.link-color {
  color: #407BFF;
  font-weight: 600;
}

.link-color:hover {
  filter: unset;
  color: #367ce7;
}

.button-nested {
  border: none;
  background: transparent;
  cursor: pointer;
}

.back-button {
  padding: 0;
  font-weight: 600;
  font-size: 1.1em;
}

.back-button:hover {
  filter: brightness(60%);
}