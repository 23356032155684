.report-contentBureauKen{
    display:flex;
    justify-content:space-between;
    width: 100%;
}

.report-contentBureauKen .personalDetKen{
    height:auto;
    min-height:300px;
    width:100%;
    margin: 0px auto;
    padding:15px 20px;
    box-sizing:border-box;
    background-color:white;
    border:1px dotted rgba(217, 229, 255, 1);
    display:flex;
    flex-direction:column;
    justify-content:space-between;
}

.report-contentBureauKen .img-container{
    height:fit-content;
    width:auto;
    padding:10px;
    box-sizing:border-box;
    background-color:white;
    border-radius:12px;
    border:1px dotted rgba(217, 229, 255, 1);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2)
}

.report-contentBureauKen .img-container .photo{
    color:rgba(0, 0, 0, 1);
    font-weight:700;
    font-size:16px;
    height:30px;
    margin-bottom:15px;

}

.personalDetKen .keyValue-holder{
    display:flex;
    justify-content:space-between;
    margin:10px 0px;
    align-items:center;
}

.keyValue-holder .key{
    font-weight:400;
    color:rgba(55, 58, 63, 1);
    font-size:14px;
}

.keyValue-holder .value{
    font-weight:700;
    color:rgba(0, 0, 0, 1);
    font-size:16px
}

.contentTwoTabsKen{
    display: flex;
    justify-content: center;
    column-gap:10px;
}

.contentTwoTabsKen .selecTed{
    padding: 15px 15px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-weight: 700;
    color:#2A38A4;
    cursor: pointer;
}

.reportOverallContent{
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: white;
    padding: 20px;
    border-radius:12px;
    margin-top: 20px;
}

.reportOverallContent .headerItem{
    color:#2A38A4;
    font-size: 24px;
    font-weight: 700;
}

.contentTwoTabsKen .unselecTed{
    padding: 15px 15px;
    border-radius: 10px;
    cursor: pointer;
}