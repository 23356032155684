.sidebar,
.sidebar-burger {
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1C256D;
}


.sidebar {
  display: flex;
  width: 220px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
  overflow-y: auto;
}

.sidebar-burger {
  width: 90%;
}

.burger-container,
.sidebar-burger {
  display: none;
}

.burger-container {
  z-index: 8;
  position: fixed;
  top: 0;
  left: 0;
}

.burger-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 9;
  position: relative;
}

.burger-icon {
  width: 20px;
}

.logo-container {
  width: calc(100% - 3em);
  height: 3em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2em 1.5em 4em 1.5em;
}

.logo-link {
  text-decoration: none;
}

.logo-container .insights-logo {
  width: 100%;
  max-width: 100%;
}

.sidebar-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.sidebar-link-row.active {
  background: rgba(255, 255, 255, .3);
}

.sidebar-link-row {
  width: calc(90% - 1.4em);
  padding: 1em .7em;
  gap: .7em;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  margin:10px 0px;
  color: #fff;
}

.sidebar-link-row:hover {
  opacity: .7;
}

.sidebar-link-row.active:hover {
  opacity: 1;
}

.sidebar-link-row svg {
  width: 2em;
  height: 2em;
}

.sidebar-link-row p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}

.sidebar-link-logout {
  position: absolute;
  bottom: 0;
}

.decision-engine-nav {
  width: calc(90% - 1.4em);
  padding: 1em 0.7em;
  margin-left: 2.8em;
}

.decision-engine-nav a {
  font-size: 0.8em;
}

@media only screen and (max-width: 800px) {
  .sidebar {
    display: none;
  }

  .burger-container,
  .sidebar-burger {
    display: flex;
  }
}