.login-body {
    width: 100%;
    height: 100%;
  }
  
  .login-main {
    display: flex;
    width: 100%;
    min-height: 100%;
    background-color: #2a38a4;
  }
  
  .login-left {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: relative;
  }
  
  .login-left-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .login-left img {
    height: 70%;
    object-fit: cover;
  }
  
  .login-right {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em 0;
    background-color: #fff;
  }
  
  .login-right-container {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    background-color: #fff;
  }
  
  .login-right-container .login-insights-logo {
    width: 3em;
    box-shadow: 4px 4px 24px rgba(0, 0, 0, .09);
    border-radius: 15px;
    padding: .5em;
    margin: 0 0 1em 0;
  }
  
  .login-right-container h2 {
    word-spacing: 5px;
    font-size: 1.8em;
    margin: 0;
  }
  
  .login-right-container .login-inputs-section {
    display: flex;
    flex-direction: column;
    gap: .3em 0;
  }
  
  .login-form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 1em 0 0 0;
  }
  
  .login-form-container .login-input-container:nth-child(2) {
    margin: 1em 0 0 0;
  } 
  
  .login-input-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .login-input-container .input-container:focus-within {
    box-shadow: 0 0 6px rgba(64, 123, 255, .35);
  }
  
  .input-labels {
    text-align: left;
    margin-bottom: .5em;
    font-weight: 500;
    font-size: .9em;
  }
  
  .login-inputs {
    border: none;
    height: 3em;
    outline: none;
    background: transparent;
    width: 100% !important;
    padding: 0 !important;
  }
  
  .login-link {
    margin: 0 0 1.5em 0;
    font-size: .9em;
    cursor: pointer;
    color: #2A38A4;
  }
  
  .buttons-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 100%;
  }
  
  .buttons-container:hover {
    cursor: pointer;
  }
  
  .btn-login {
    height: 2.5em;
    width: 100%;
    font-weight: 600;
    font-size: 1.2em;
    letter-spacing: 2px;
    border: none;
    background-color: #407BFF;
    color: #fff;
  }
  
  .btn-login:hover {
    opacity: .8;
    cursor: pointer;
  }
  
  .login-body .login-spinner-container {
    margin: 0 auto;
  }
  
  /* Reset Password */
  .password-reset-container p {
    margin: 0;
    font-weight: 400;
  }
  
  .password-reset-container span {
    font-weight: 600;
    cursor: pointer;
  }
  
  .login-reset-message {
    margin: 0;
    font-size: .9em;
  }
  
  .reset-password-form-container .back-to-login-link {
    margin: 0;
    cursor: pointer;
    font-weight: 600;
  }
  

  .formDirective{
    font-size: 14px;
  }

  .policyTitle{
    margin-top:10px;
    margin-bottom: 10px;
  }

  .policyHeader{
    font-size: 14px;
  }

  .policyTitle li{
    font-size: 13px;
    letter-spacing: 1.2px;
  }
  /* All Media Queries */
  @media only screen and (max-width: 900px) {
    .login-right-container {
      width: 80%;
    }
  }
  
  @media only screen and (max-width: 800px) {
    .login-left {
      display: none;
    }
  
    .login-main {
      justify-content: center;
      align-items: center;
    }
  
    .login-right {
      min-height: 80%;
      border-radius: 10px;
      margin: 2em;
    }
  }
  
  @media only screen and (max-width: 600px) { 
    .login-right {
      width: 80%;
    }
  }
  
  @media only screen and (max-width: 400px) { 
    .login-right {
      width: 90%;
    }
  }