.tbody {
    position: relative;
  }
  
  .thead .tr {
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 700;
    line-height: 20.83px;
  }
  
  .thead th,
  .tbody th {
    border-bottom: 1px #e0e0e0 solid;
    border-top: 1px #e0e0e0 solid;
     /* border-collapse: collapse;
     margin-bottom: 2rem; */
  
    &:first-child {
      border-left: 1px #e0e0e0 solid;
    }
    &:last-child {
      border-right: 1px #e0e0e0 solid;
    }
  }

  .tbody  .th {
    color:#130F26;
    font-weight: 500;
    font-size: 14px;
    line-height: 20.83px;
     }

  .tbody .tr {
    background-color: transparent;
  }
  
  .spinnerWrapper,
  .errorWrap {
    width: 100%;
    position: absolute;
    left: 0;
  }
  /* .spinnerWrapper {
    min-height: 100vh;
  } */

  .spinnerWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .th{
    cursor: pointer;
  }

  .th .tableTitle{
    font-weight: 600;
  }

  .table-row-two-colored:nth-child(odd) {
    background-color: #F6F8FC;
  }
  
  .table-row-two-colored:nth-child(even) {
    background-color: #D9E5FF;
  }
  
  .table-row-two-colored-audit:nth-child(odd) {
    background-color: #F6F8FC;
  }
  
  .table-row-two-colored-audit:nth-child(even) {
    background-color: #D9E5FF;
  }
 
  