.screenHeader{
    width:100%;
    border: 1px solid #2A38A4;
    border-width: 0px 0px 1px 0px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.screenHeader .unselected{
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    cursor:pointer
}

.screenHeader .selected{
    color: #2A38A4;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
}

.screenInfoTitle{
    color:#8C8C8C;
    text-align: left;
}

.screenInfoResult{
    color: #1F2228;
    text-align: left;
}