.filterHeader {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.filterHeader span {
    font-size:24px;
    line-height: 31.25px;
    font-weight: 500;
    color:#407BFF;
}

.inputLabel {
    color: #000000;
    font-size:14px;
    line-height: 18.23px;
    font-weight: 500

}
.formInput {
    margin-bottom: 25px;
}

.inputContainer {
    border:1px solid #407BFF;
    border-radius: 5px;
    overflow: hidden;
}

.inputContainer:focus-within, .inputContainer:hover {
    border:2px solid #407BFF;
}

/* .selectContainer {
    border:1px solid #407BFF;

} */
.control {

    border:1px solid #407BFF;
}


.btnWrapper {

    display: flex;
    align-items: center;
    justify-content: center;

}

.contained {
    padding:10px 24px 10px 24px;
    outline: none;
    border: none;
    background-color: #407BFF;
    color: #fff;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14.47px;
    line-height: 18.83px;

}