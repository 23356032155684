.report-hold{
    margin-top:40px;
    display:flex;
    background-color:whitesmoke;
    flex-direction:column;
    justify-content:space-around;
    border:1px solid rgba(196, 211, 249, 1);
    border-width:1px 0px 0px 0px;
    padding:20px;
    padding-top:35px
}

.report-hold .roof{
    display:flex;
    align-items:center;
    justify-content:start;
    column-gap:5px;
    margin:20px 0px;
}

.report-hold .roof .span-view-report{
    font-size:16px;
    cursor:pointer;
    color:rgba(196, 196, 196, 1);
    font-weight:700;
}

.report-hold .roof .span-view-history{
    font-size:16px;
    cursor:pointer;
    color:rgba(17, 98, 220, 1);
    font-weight:700;
}

.title-class{
    color:rgba(0, 0, 0, 1);
    font-size:24px;
    font-weight: 700;
    text-align: left;
    padding-left: 10px;
}

.reportOverallHolder{
   
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    background-color: white;
}

.reportOverallHolder .headerItem{
    color:#2A38A4;
    font-size: 24px;
    font-weight: 700;
}


.reportOverallHolder .reportOverallContent{
    display:flex;
    justify-content:space-between;
    width: 100%;
    column-gap: 20px;
  
}

.report-contentBureauOne{
    display:flex;
    justify-content:space-between;
    width: 50%;
}

.report-contentBureauOne .personalDet{
    height:auto;
    min-height:300px;
    width:100%;
    margin: 0px auto;
    padding:15px 20px;
    box-sizing:border-box;
    background-color:white;
    border-radius:12px;
    border:1px dotted rgba(217, 229, 255, 1);
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.personalDet .keyValue-holder{
    display:flex;
    justify-content:space-between;
    margin:10px 0px;
    align-items:center;
}

.keyValue-holder .key{
    font-weight:400;
    color:rgba(55, 58, 63, 1);
    font-size:14px;
}

.keyValue-holder .value{
    font-weight:700;
    color:rgba(0, 0, 0, 1);
    font-size:16px
}

.report-contentBureauOne .img-container{
    height:fit-content;
    width:auto;
    padding:10px;
    box-sizing:border-box;
    background-color:white;
    border-radius:12px;
    border:1px dotted rgba(217, 229, 255, 1);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2)
}

.report-contentBureauOne .img-container .photo{
    color:rgba(0, 0, 0, 1);
    font-weight:700;
    font-size:16px;
    height:30px;
    margin-bottom:15px;

}


.contentTwoTabs{
    display: flex;
    justify-content: center;
    column-gap: 15px;
}

.contentTwoTabs .selecTed{
    padding: 15px 30px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    font-weight: 700;
    color:#2A38A4;
    cursor: pointer;
}

.contentTwoTabs .unselecTed{
    padding: 15px 30px;
    border-radius: 10px;
    cursor: pointer;
}

.rbcTable{
    margin:40px 0px;
    padding:20px 10px;
    background-color: white;
    /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
    border-radius: 15px;
}

.rbcTable .header-hold{
    width:100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:20px;
}


.rbcTable .header-hold section{
    width:20%;
    text-align:left;
    font-weight:700;
    padding-left:10px;
    color:rgba(0, 0, 0, 1);
    font-size:16px;
}


.rbcTable .header-hold .headerAcc{
    width:20%;
    text-align:left;
    font-weight:700;
    padding-left:10px;
    color:rgba(0, 0, 0, 1);
    font-size:16px;
}

.header-hold .Attri{
    width: 40%;
    text-align:left;
    font-weight:700;
    padding-left:10px;
    color:rgba(0, 0, 0, 1);
    font-size:16px;
}

.header-hold .currencyTitle{
    width: 15%;
    text-align:left;
    font-weight:700;
    padding-left:10px;
    color:rgba(0, 0, 0, 1);
    font-size:16px;
}




.rbcTable .body{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items:center;

}

.rbcTable .body .inner{
    width:100%;
    background-color:rgba(255, 255, 255, 0.6);
    border:1px solid rgba(255, 255, 255, 1);
    height:60px;
    padding:10px 5px;
    display:flex;
    justify-content:space-between;
    box-sizing: border-box;

}

.rbcTable .body .inner .element{
    width:15%;
    display:flex;
    align-items:center;
    justify-content:left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow:hidden;
    line-height: 18px;
    padding-left: 10px;
    box-sizing: border-box;
   
}

.rbcTable .body .inner .found{
    width:40%;
    display:flex;
    align-items:center;
    justify-content:left;
    box-sizing:border-box;
    color:#130F26;
    font-weight:500;
    font-size:16px;
    line-height: 16px;
    padding-left: 10px;
    box-sizing: border-box;
}


.rbcTable .body .inner .elementAcc{
    width:15%;
    display:flex;
    align-items:center;
    justify-content:left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow:hidden;
    line-height: 18px;
    padding-left: 10px;
   
}

.rbcTable .body .inner .foundAcc{
    width:40%;
    display:flex;
    align-items:center;
    justify-content:left;
    box-sizing:border-box;
    color:#130F26;
    font-weight:500;
    font-size:16px;
    line-height: 16px;
    padding-left: 10px;
    box-sizing: border-box;
    box-sizing: border-box;
}

.creditSummaryClass{
    display:flex;
    align-items:center;
    justify-content:start;
    box-sizing:border-box;
    color:#130F26;
    font-weight:700;
    font-size:18px;
    line-height: 16px;
    padding-left: 10px;
    box-sizing: border-box;
}