/* MOBILE Statement View */
.mb-details-body {
  width: calc(100% - 8em);
  padding: 0 4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4em 0 1em 0;
}

.mb-details-body .mb-details-main-header {
  width: 100%;
}

.mb-details-body .mb-details-main-header h2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 2.3em;
  margin: 0 0 .2em 0;
}

.mb-details-body .mb-details-main-header .mb-details-main-header-sub {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: .5em
}

.mb-details-main-header-sub p {
  margin: 0;
  font-weight: 600;
}

.mb-details-main-header-sub .calendar-icon {
  width: 1.5em;
  height: 1.2em;
}

.mb-details-body .top-level-tabs {
  width: 100%;
}

.mb-details-body .tab-bodies-container {
  width: 100%;
}

/* Overview */
.mb-insight-tabs-container .mb-overview-header {
  margin: 1em 0 .5em 0;
}

.mb-insight-details {
  display: grid; 
  grid-template-columns: 1fr 1fr; 
  grid-template-rows: 1fr 1fr; 
  gap: 1em; 
  grid-template-areas: 
    ". ."
    ". .";
  margin: 1em 0 0 0;
}

.mb-insight-details .mb-details-subheader-item {
  display: flex;
  gap: 1em;
}

.mb-insight-details p,
.mb-insight-details h4 {
  margin: 0;
  font-size: 1.1em;
}

.mb-insight-details h4 { font-weight: 500; }

.mb-insight-details p { font-weight: 600; }

.mb-insight-balance-row {
  width: 100%;
  display: flex;
  gap: 1em;
  margin: 1.5em 0 0 0;
}

.mb-insight-balance-row .st-details-card {
  align-items: center;
  justify-content: space-between;
  text-align: center;
  gap: 2em;
}

.mb-insight-balance-row .details-header-row h3 {
  margin: 0 0 .2em 0;
}

.mb-insight-balance-row .details-body {
  justify-content: flex-end;
}

.mb-insight-balance-row h2 {
  margin: 0;
  font-size: 2.4em;
}

.mb-insight-balance-row .dc-simple-header-container p {
  display: none;
}

.mb-insight-balance-row .st-details-card p {
  margin: .5em 0;
  font-size: .9em;
}

.mb-insight-tabs-container {
  width: 100%;
  margin: 1em 0;
}

.mb-insight-tabs-container .mb-tab-details .mb-account-tab-content {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1em;
  margin: 2em 0 0 0;
}

.mb-back-button {
  border: none;
  font-size: 1.1em;
  padding: .7em 1em;
  cursor: pointer; 
  margin: 1em 0 0 0;
}

/* Accounts - Bank */
.mb-tab-details .st-details-card {
  padding: 0;
  width: 100%;
  border-radius: 30px 30px 10px 10px;
  gap: .5em;
}

.mb-tab-details .details-header-row {
  padding: .8em 2em;
  width: calc(100% - 4em);
  border-radius: 30px;
}

.mb-account-tab-content .details-header-row {
  display: flex;
  align-items: center;
  gap: 1em;
  background: #2A38A4;
}

.mb-account-tab-content .details-header-row h3 {
  font-size: 1.2em;
}

.mb-account-tab-content .details-header-row .mb-account-logo {
  width: calc(3.5em - 1px);
  height: 3.5em;
  border-radius: 5px;
  padding: 1px;
}

.mb-account-tab-content .details-header-row img,
.mb-account-tab-content .details-header-row h3 {
  position: relative;
}

.mb-account-tab-content .details-header-row .no-logo-url {
  position: relative;
  background: #1C256D;
  color: #fff;
  border: 1px solid #fff;
}

.mb-account-tab-content .mb-account-list-container {
  padding: 1em 2em 2em 2em;
}

.mb-account-tab-content .mb-account-list-container h3 {
  margin: 0;
}

.mb-account-tab-content .mb-account-list-container p {
  margin: 0;
  font-size: .8em;
}

.mb-account-tab-content .st-details-list-main-container {
  margin: 1em 0 0 0;
}

.mb-account-tab-content .list-type-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mb-account-tab-content .list-type-content .list-detail-with-link-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.mb-account-tab-content .list-type-content .list-detail-with-link-container .list-detail-link {
  width: 100%;
  display: flex;
  gap: .5em;
  align-items: center;
  justify-content: space-between;
}

.mb-account-tab-content .list-type-content .list-detail-with-link-container .list-detail-link span {
  color: #2A38A4;
}

.mb-account-tab-content .list-type-content .list-detail-with-link-container h2 + .list-detail-link {
  width: unset !important;
}

.mb-account-tab-content .list-type-content h2,
.mb-account-tab-content .list-type-content p {
  font-size: 1em;
}

.mb-tab-details .list-type-content button {
  background: transparent;
  border: none;
  font-size: 1.1em;
  cursor: pointer;
}

/* Accounts -- Lending  */
.mb-lenders-tab-content {
  padding: 2em 1.5em;
  width: calc(100% - 3em);
  border-radius: 5px;
  margin: 1.5em 0 0 0;
  background: #fff;
}

.mb-lenders-tab-content h2 {
  margin: 0 0 1em 0;
}

.mb-lenders-tab-content .details-header-row img,
.mb-lenders-tab-content .details-header-row .no-logo-url,
.mb-lenders-tab-content .details-header-row h3 {
  position: unset;
  top: unset;
}

.mb-tab-details .lending .details-header-row {
  align-items: center;
}

.mb-tab-details .lending .details-header-row .details-header-with-link-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  width: 100%;
  cursor: pointer;
}

.mb-tab-details .lending .details-header-row .details-header-with-link-container span {
  color: #fff;
}

@media only screen and (max-width: 1420px) {
  .mb-insight-tabs-container .mb-tab-details .mb-account-tab-content {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 1000px) {
  .mb-transactions-type-table-container .list-overflow-text-container {
    max-width: 200px;
  }
}

@media only screen and (max-width: 900px) {
  .mb-transactions-type-table-container .list-overflow-text-container {
    max-width: 100px;
  }
}

@media only screen and (max-width: 850px) {
  .mb-details-body .mb-insight-tabs-container .mb-insight-details .st-details-subheader-item {
    flex-direction: column;
  }

  .mb-insight-tabs-container .mb-tab-details .mb-account-tab-content {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .mb-account-analytics-details .details-list-container:nth-child(2) {
    flex-direction: column;
  }

  .mb-details-body .mb-insight-tabs-container .mb-insight-balance-row {
    flex-direction: column;
  }
}

@media only screen and (max-width: 730px) {
  .mb-transactions-type-table-container .list-overflow-text-container {
    max-width: 100%;
    padding: .2em 1em;
  }
}

@media only screen and (max-width: 600px) {
  .mb-details-body .mb-insight-tabs-container .mb-insight-details .st-details-subheader-item {
    flex-direction: row;
    justify-content: space-between;
  }

  .mb-insight-details {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 430px) {
  .mb-details-body .mb-insight-tabs-container .mb-insight-details .st-details-subheader-item {
    flex-direction: column;
    justify-content: flex-start;
    gap: .2em;
  }

  .mb-insight-details {
    gap: 1.5em;
  }

  .mb-tab-details .details-list-container {
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .mb-tab-details .dc-simple-visual-li {
    flex-direction: column;
    align-items: flex-start;
  }
}