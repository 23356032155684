/* Statement Detail Cards */
.st-details-card-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  flex-basis: 0px;
  flex-grow: 1;
}

.subscription-flag-off{
  text-align:right;
  padding-right:4px;
  box-sizing:border-box;
  font-size:12px;
  color:red
}

.subscription-hidden{
  display:none
}

.st-details-card {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  width: calc(100% - 4em);
  height: 100%;
  padding: 2em;
  gap: 1em 0;
  position: relative;
  transition: all .2s ease-in-out;
}

.details-header-row h3 {
  margin: 0;
  font-size: 1.4em;
  /* color: #2A38A4; */
 }

.details-header-row h4 {
  margin: 0;
  font-size: 1em;
  font-weight: 400;
}

#hover-detail-card:hover > .st-details-card {
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

#hover-detail-card:hover > .st-details-overlay {
  display: flex;
  visibility: visible;
  opacity: 1;
}

.st-details-overlay {
  display: none;
  visibility: hidden;
  opacity: 0;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  transition: visibility .2s, opacity .5s linear;
  background: linear-gradient(106deg, rgba(255, 255, 255, .6) 0%, rgba(255, 255, 255, .2) 100%);
}

.st-details-overlay button {
  border-radius: 5px;
  font-size: 1.1em;
  font-weight: 600;
  padding: .5em 1em;
  cursor: pointer;
  background: transparent;
  border: 1px solid #2A38A4;
  color: #2A38A4;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, .1);
}

.st-details-overlay button:hover {
  opacity: .7;
}

.st-details-card .st-details-icon-container {
  display: flex;
  align-items: center;
}

.st-details-card .st-details-icon-container svg {
  width: 2.2em;
}

.st-details-card .st-column-list-container {
  display: flex;
  flex-direction: column;
  gap: 2em 0;
  margin: 1em 0 0 0;
}

/* Statement Analytics - Income */
.st-analytics-income .st-details-card-main {
  width: 50%;
}

/* Statement Analytics - Pattern */
.st-details-pattern-top-row .st-details-card {
  justify-content: flex-start;
}

.st-details-pattern-top-row .st-details-pattern {
  display: flex;
  justify-content: space-between;
  gap: 2em;
}

.st-details-pattern-bottom-row .cards,
.st-details-double-colored-bottom-row .cards {
  padding: 0;
}

.st-details-pattern-bottom-row .st-details-card {
  width: 100%;
}

/* Statement Analytics - Cash Flow */
.cash-flow-content .st-details-card-container-row .details-header-row {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cash-flow-content .st-details-cash-top-row .details-header-row {
  justify-content: space-between;
  height: 100%;
}

.cash-flow-content .st-details-cash-top-row .details-header-row h3 {
  font-size: 1.5em;
}

.cash-flow-content .st-details-cash-top-row .details-header-row h4 {
  font-size: 1.2em;
}

.cash-flow-content .st-details-double-colored-bottom-row .details-header-row {
  flex-direction: column;
}

.cash-flow-content .st-details-double-colored-bottom-row .details-header-row h4 {
  font-size: 1.2em;
}

.cash-flow-content .st-details-double-colored-bottom-row .details-header-row p {
  margin: 0;
  font-size: 1.2em;
  font-weight: 500;
}

.cash-flow-content .st-details-cash-top-row {
  flex-wrap: wrap;
}

.cash-flow-content .st-details-cash-top-row .st-details-card-main {
  flex: 1 1 300px;
}

@media only screen and (max-width: 1045px) {
  .st-details-double-colored-bottom-row {
    display: grid; 
    grid-template-columns: 1fr; 
    grid-template-rows: 1fr 1fr; 
    gap: 2em; 
    grid-template-areas: 
      "auto" "auto"; 
  }

  .cash-flow-content .st-details-cash-top-row {
    gap: 1em;
  }

  .cash-flow-content .st-details-cash-top-row h3 {
    word-break: break-all;
  }
}

@media only screen and (max-width: 700px) {
  .st-analytics-income .st-details-card-main {
    width: 100%;
  }
}

@media only screen and (max-width: 400px) {
  .st-details-behavior-top-row .st-details-list {
    flex-direction: column;
  }

  .st-details-behavior-top-row .st-details-list li {
    display: flex;
    gap: 1em;
    justify-content: center;
    align-items: center;
  }

  .st-details-behavior-top-row .st-details-behavior-detail-inflow-main-container {
    flex-direction: column;
    gap: 2em;
  }

  .st-details-behavior-top-row .st-details-behavior-inflow-container {
    height: 7em;
  }

  .st-details-double-colored-bottom-row ul {
    padding: 1em !important;
  }

  .st-details-double-colored-bottom-row .details-header-row {
    font-size: 80%;
  }

  .st-details-double-colored-bottom-row .details-header-row h3 {
    word-break: break-all;
  }
}