.user-management-modal hr {
  background-color: #DFE8FF;
  color: #DFE8FF;
  border: #DFE8FF;
  height: 1px;
}

.user-management-modal .submit-container {
  margin: 1em 0 0 0;
}

.user-management-form-container {
  width: 100%;
}

.user-management-form-fields {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: .5em;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 400px;
}

.user-management-input-container {
  display: flex;
  flex-direction: column;
  gap: .4em;
}

.user-management-input-container label {
  font-size: 14px;
}

.user-management-input-container input {
  padding: .7em 1em;
  border: 2px solid #DFE8FF;
}

.user-management-input-container input:focus {
  outline: none;
}

.user-management-input-container input::placeholder {
  color: #6f7176;
  font-style: italic;
}

/* Roles Select*/
.user-management-select-inputs-container {
  padding: .7em 1em;
  border: 2px solid #DFE8FF;
  border-radius: 5px;
  background-color: #fff;
}

/* Custom Checkbox */
.user-management-checkbox-inputs-container {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
}

.user-management-checkbox-inputs-container .user-management-checkbox-input {
  /* flex: 1 1 auto; */
  width: 25%;
  display: flex;
  gap: 2em;
  align-items: center;
  position: relative;
  padding-left: 35px;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 22px;
}

.user-management-checkbox-inputs-container .user-management-checkbox-input input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.user-management-checkbox-inputs-container .user-management-checkbox-input .user-management-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #407BFF;
}

.user-management-checkbox-inputs-container .user-management-checkbox-input:hover input ~ .user-management-checkmark {
  background-color: #DFE8FF;
}

.user-management-checkbox-inputs-container .user-management-checkbox-input input:checked ~ .user-management-checkmark {
  background-color: #407BFF;
}

.user-management-checkbox-inputs-container .user-management-checkbox-input .user-management-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.user-management-checkbox-inputs-container .user-management-checkbox-input input:checked ~ .user-management-checkmark:after {
  display: block;
}

.user-management-checkbox-inputs-container .user-management-checkbox-input .user-management-checkmark:after {
  left: 6px;
  top: 4px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Custom Role */
.user-management-custom-role-container {
  display: flex;
  flex-direction: column;
  gap: .5em;
  margin: 1em 0 0 0;
}

/* Delete USer */
.delete-user button {
  border: 2px solid #8F9CA8;
  border-radius: 3px;
  color: #8F9CA8;
  background-color: #fff;
}

.delete-user .delete-user-button {
  border: 2px solid #FF725E;
  background-color: #FF725E;
  color: #fff;
}

.delete-user .submit-container {
  margin: 3em 0 0 0;
}