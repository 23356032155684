.report-hold{
    margin-top:40px;
    display:flex;
    background-color:whitesmoke;
    flex-direction:column;
    justify-content:space-around;
    border:1px solid rgba(196, 211, 249, 1);
    border-width:1px 0px 0px 0px;
    padding:20px;
    padding-top:35px
}

.report-hold .roof{
    display:flex;
    align-items:center;
    justify-content:start;
    column-gap:5px;
    margin:20px 0px
}

.report-hold .roof .span-view-report{
    font-size:16px;
    cursor:pointer;
    color:rgba(196, 196, 196, 1);
    font-weight:700;
}

.report-hold .roof .span-view-history{
    font-size:16px;
    cursor:pointer;
    color:rgba(17, 98, 220, 1);
    font-weight:700;
}

.title-class{
    color:rgba(0, 0, 0, 1);
    font-size:24px;
    font-weight: 700;
    text-align: left;
    padding-left: 10px;
}

.report-contentKYB{
    display:flex;
    justify-content:space-between;
    column-gap:20px;
    margin:30px 0px;
    width: 100%;
}

.report-contentKYB .report-fields{
    height:auto;
    min-height:300px;
    width:100%;
    padding:15px 20px;
    box-sizing:border-box;
    background-color:white;
    border-radius:12px;
    border:1px dotted rgba(217, 229, 255, 1);
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.info-report{
    height:auto;
    min-height:300px;
    width:100%;
    padding:15px 20px;
    box-sizing:border-box;
    background-color:white;
    border-radius:12px;
    border:1px dotted rgba(217, 229, 255, 1);
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    margin: 20px 0px;
}

.info-report .keyValue-holder{
    display:flex;
    justify-content:space-between;
    margin:10px 0px;
    align-items:center;
    
}

.report-contentKYB .report-fieldsKybData{
    height:auto;
    min-height:300px;
    width:100%;
    padding:15px 20px;
    box-sizing:border-box;
    background-color:white;
    border-radius:12px;
    border:1px dotted rgba(217, 229, 255, 1);
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.report-fieldsKybData .keyValue-holder{
    display:flex;
    justify-content:space-between;
    margin:10px 0px;
    align-items:center;
}

.keyValue-holder .key{
    font-weight:400;
    color:rgba(55, 58, 63, 1);
    font-size:14px
}

.keyValue-holder .value{
    font-weight:700;
    color:rgba(0, 0, 0, 1);
    font-size:16px
}

.report-contentKYB .img-container{
    height:fit-content;
    width:auto;
    padding:10px;
    box-sizing:border-box;
    background-color:white;
    border-radius:12px;
    border:1px dotted rgba(217, 229, 255, 1);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2)
}

.report-contentKYB .img-container .photo{
    color:rgba(0, 0, 0, 1);
    font-weight:700;
    font-size:16px;
    height:30px;
    margin-bottom:15px;

}


.img-container .imgKycBox{
    width:fit-content;
    display:flex;
    padding:7px;
    margin:0px auto;
    box-sizing:border-box;
    justify-content:center;
    align-items:center;
    height:100%;
    align-items: center;
    justify-content: center;
    border:1px dotted rgba(217, 229, 255, 1)
}

.report-contentKYB .no-img{
    height:350px;
    width:50%;
    padding:10px;
    box-sizing:border-box;
    background-color:white;
    border-radius:12px;
    border:1px dotted rgba(217, 229, 255, 1);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.report-contentKYB .no-img .text-top{
    color:rgba(42, 56, 164, 1);
    font-size:20px;
    font-weight:700

}