.decision-card-wrapper {
    flex-grow: 1;
    background-color: #fff;
    border: solid 1px #C3D4FF;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
}

.decision-card-wrapper .inner-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 1rem;
    border: 1px solid transparent
}

.decision-card-wrapper .inner-wrap {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 1rem;
    border: 1px solid transparent
}

.decision-card-wrapper .inner-wrap .top-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.decision-card-wrapper .inner-wrap .bottom-section h4 {
    font-size: 1.2rem;
    color: #363C4F;
}

.decision-card-wrapper .inner-wrap .bottom-section p {
    font-size: 0.7rem;
    color: #363C4F;
    margin-top: 5px;
}

