.top-section{
    display:flex;
    align-items:center;
    justify-content:start;
    column-gap:5px;
    margin-top:20px
}

.top-section .span-view-details{
    font-size:16px;
    cursor:pointer;
    color:rgba(196, 196, 196, 1);
    font-weight:700;
}

.top-section .span-view-password{
    font-size:16px;
    cursor:pointer;
    color:rgba(17, 98, 220, 1);
    font-weight:700;
}

.confirmPasswordSection{
    display: flex;
    justify-content: space-between;
}

.passwordHolderSect{
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}


.changeButtonHold{
    width:320px;
    margin-right: 0px;
}
  
  .change-pass-container {
    display: flex;
    flex-direction: column;
    width: 45%;
  }
  
  .change-pass-container .input-container:focus-within {
    box-shadow: 0 0 6px rgba(64, 123, 255, .35);
  }