.previewEngineBut{
    border: 1px solid rgba(64, 123, 255, 1);
    border-radius: 7px;
    color:rgba(64, 123, 255, 1);
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    height:50px;
    width:230px;
    cursor: pointer;
}

.selectToggle{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    cursor: pointer;
}

.selectToggle span{
    display: flex;
    justify-content: center;
    align-items: center;
}

.selectToggle .toggletext{
    font-weight: 700;
}

.untoggle{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    cursor: pointer;
}

.untoggle span{
    display: flex;
    justify-content: center;
    align-items: center;
}

.untoggle .toggletext{
    font-weight: 400;
}


.createParameter{
    border-radius: 7px;
    color: white;
    background-color: #2A38A4;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-around;
    column-gap: 7px;
    height:50px;
    width:230px;
    cursor: pointer;
    margin-top: 20px;
}

.uploadParameter{
    border-radius: 7px;
    color: #2A38A4;
    background-color: white;
    border: 1px solid #2A38A4;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: space-around;
    column-gap: 7px;
    height:50px;
    width:230px;
    cursor: pointer;
    margin-top: 20px;
}

.img-flex{
    display: flex;
    justify-content: center;
    align-items: center;
}

.showParam{
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    border-radius: 10px;
    padding: 20px 0px;
}

.showParam span{
    padding:5px 10px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
}

.cancelParameter{
    border-radius: 7px;
    color: rgba(64, 123, 255, 1) ;
    border: 1px solid rgba(64, 123, 255, 1);
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 7px;
    height:50px;
    width:230px;
    cursor: pointer;
    margin-top: 20px;
}

.imageHolder{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.editButton{
    display:flex;
    align-items:center;
    justify-content:Center;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    background-color: rgba(42, 56, 164, 1);
    font-size: 12px;
    height:40px;
    width: 65px;
}

.ParamBody{
    min-height:150px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-around;
    background-color:white;
    width:100%;
    border-radius:10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin:30px auto
}

.ParamBody .ItalicContent{
    font-weight:700;
    font-style:italic;
    font-size:22px
}