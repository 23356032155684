.form-wrap {
    background-color: #fff;
    border-radius: 9px;
    display: block;
    margin: 30px 0;
}

.form-wrap .inner-form-wrap {
    padding: 1.8rem;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.form-wrap .inner-form-wrap .flex-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
    row-gap: 20px;
}

.de-sub-page-wrap .de-footer-btns-wrap {
    display: flex;
    justify-content: flex-end;
    column-gap: 14px;
    padding-bottom: 40px;
}

.button-square {
    padding: .7em 2em;
    font-weight: 500;
    font-size: 1em;
    cursor: pointer;
}

.emptyField{
    display: none
}