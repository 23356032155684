
#progressBar {
    width: 100%; /* Ensure the progress bar fills its container */
    height: 14px; /* Set the height of the progress bar */
    border-radius: 20px;
    background-size: 100% 100%; 

  }


  .progress-label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }

  .rc-slider-mark {
     top: 50px
  }
  .rc-slider-dot {
bottom : -15px
  }

  .custom-rc-handle  {
    width:30px;
    height:30px;
    font-size: 12px;
    color: #fff;
    background-color: #5A8DFF;
    display: flex;
    align-items: center;
    justify-content: center;
    box-Shadow: 8px 8px 45px -px rgba(42, 56, 164, 1);
    z-index: 0;
  }