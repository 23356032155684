.wallet-body-spinner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wallet-body {
  width: calc(100% - 5em);
  padding: 4em 2.5em;
  display: flex;
  flex-direction: column;
}

.wallet-main-body {
  display: flex;
  flex-direction: column;
  background: inherit;
}

.wallet-main-body .top-level-tabs {
  border-bottom: 1px solid #2A38A4;
}

.TopUpSector{
  border:10px 0px;
  display:flex;
  align-items:center;
  justify-content:flex-start;
  column-gap:15px;
}

.TopUpSector .amountInput{
  outline:none;
  background-color:white;
  border:1px solid #2A38A4;
  height:40px;
  width:170px;
  border-radius:10px;
  padding-left:15px;
  box-sizing:border-box;
}

.topUpClassLoading{
  display:flex;
  color:white;
  background-color: #0c1a82;
  align-items:center;
  justify-content:center;
  height:40px;
  width:150px;
  border-radius:10px;
  cursor: not-allowed;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);

}

.topUpClass{
  display:flex;
  color:white;
  background-color:#0c1a82;
  align-items:center;
  justify-content:center;
  height:40px;
  width:150px;
  border-radius:10px;
  cursor: pointer;
  transition: all 1s;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);

}

.topUpClass:hover{
  background-color: #0c1a82;
  box-shadow: 10px 4px 6px rgba(0, 0, 0, 0.1);

}

.wallet-main-body .tab-bodies-container {
  width: 100%;
  border-radius: 10px;
  margin: 1.5em 0 0 0;
}

.wallet-main-body .tab-bodies-container h2 {
  margin: 0;
}

/* Wallet Balance */
.wallet-balance-container {
  width: calc(100% - 4em);
  padding: 3em 2em;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #2A38A4;
  background-repeat: no-repeat !important;
  background-position: bottom !important;
  background-size: cover !important;
  color: #fff;
  margin: 0 0 1em 0;
}

.wallet-balance-header h2 {
  font-size: 1.3em;
  font-weight: 500;
}

.wallet-balance-header h3 {
  font-size: 3em;
}

.wallet-balance-button-container {
  /* display: flex; */
  display: grid;
  align-items: flex-end;
}

.wallet-balance-button-container .btn-wallet-add {
  border: none;
  padding: .8em 1.2em;
  font-weight: 600;
  font-size: 1.1em;
  cursor: pointer;
  border-radius: 5px;
  color: #2A38A4;
}

/* Wallet Tabs */
.wallet-body .wallet-transactions-container {
  position: relative;
  padding: 1.5em;
  width: calc(100% - 3em);
  background: #fff;
  margin: 1em 0 0 0;
  border-radius: 5px;
}

.wallet-body .top-level-tabs .st-details-header {
  padding: 0 1.5em;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.wallet-body .top-level-tabs #inactive {
  filter: contrast(80%);
}

.wallet-body .tab-bodies-container {
  padding: 1.5em;
  width: calc(100% - 3em);
  background: #fff;
  position: relative;
}

/* Wallet Tables */
.wallet-body table {
  margin: 1em 0 0 0;
  font-size: 90%;
}

.wallet-body .table-container {
  position: unset;
}

.wallet-body table thead tr th {
  padding: .7em 0 0 1em;
}

.wallet-body table thead tr th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.wallet-body table thead tr th:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.wallet-body table tbody tr td {
  padding: 1em;;
}

.wallet-body .tb-filter-container {
  right: 1.6em;
  top: 1.6em;
}

/* Coming Soon */
.wallet-coming-soon-message-section {
  padding: 2em 1em;
  border-radius: 10px;
  width: calc(90% - 2em);
}

.wallet-coming-soon-message-section h1 {
  font-size: 3em;
}

.wallet-coming-soon-message-section p {
  font-size: 1.3em;
  font-weight: 500;
}

@media only screen and (max-width: 900px) {
  .wallet-main-body {
    flex-direction: column;
    gap: 2em 0;
  }

  .wallet-first-column {
    width: 100%;
  }

  .wallet-second-column {
    width: calc(100% - 1em);
    padding: .5em;
  }
  
  .wallet-second-column-scroll-container {
    flex-direction: row;
    gap: 0 .5em;
    flex-basis: unset;
    flex-grow: unset;
    overflow-x: auto;
    overflow-y: hidden;
    margin: 1.5em 0 0 0;
  }

  .wallet-second-column .st-details-card {
    width: calc(100% - 4em);
    padding: 1em 2em;
  }

  .wallet-second-column h4 {
    word-break: normal;
  }

  .wallet-balance-header svg {
    visibility: visible;
  }
}

@media only screen and (max-width: 600px) {
  .wallet-balance-button-row {
    flex-direction: column;
    align-items: flex-start;
    gap: 1em;
  }

  .wallet-balance-button-row h2 {
    font-size: 2em;
  }
}

@media only screen and (max-width: 350px) {
  .wallet-body .tb-filter-top-container .table-filter-field-container  .table-date-range-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .wallet-body .tb-filter-top-container .table-filter-field-container .tb-filter-inputs {
    width: calc(100% - 1em);
  }

  .wallet-body .tb-filter-top-container {
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .wallet-body .tb-filter-top-container .table-filter-field-container {
    flex: unset;
  }
}