
.inputContainer {
  border: 2px solid #DFE8FF;
  background-color: #fff;
  transition: border-color 0.3s ease;
  border-radius: 4px;
  overflow: hidden;
}

.input {
    font-size: 14px;
    font-weight: 400;
    outline: none;
    border:none;
    line-height:  18.23px;
    padding:20px;
    box-sizing: border-box;

  }
  
  .inputContainer:hover {
    border-color: #DFE8FF;
  }
  
  .inputContainer:focus {
    border-color: #DFE8FF;
  
  }

  .inputContainer:focus-within {
    border-color: #DFE8FF;
  }
  
  .input::placeholder {
    color: #888;
  }
  
  .input:disabled {
    cursor: not-allowed;
    background-color: #DFE8FF;
    border-color: #DFE8FF;
  }
  



  .fullWidth {
    width: 100%;
  }

  /**  label **/

.label {
font-size: 18px;
font-weight: 500;
line-height: 23.44px;
display: block;
margin-bottom: 5px;
}  

.selectInput {
    border:1px solid red
}

.control {
    padding: 20px;
    outline: none;
    border: none;
 

}
