.EmptyParams{
    display: flex;
    margin-top: 40px;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    row-gap: 30px;
}

.EmptyParams .title{
    color: #2A38A4;
    font-weight: 700;
    font-size: 20px;
}

.EmptyParams .proceed{
    color: #363C4F;
    font-weight: 500;
    font-size: 16px;
}