.decision-card-wrapper {
    flex-grow: 1;
    background-color: #fff;
    border: solid 1px #C3D4FF;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
}

.decision-card-wrapper .inner-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 1rem;
    border: 1px solid red;
}

.decision-card-wrapper .inner-wrapper .top-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.decision-card-wrapper .inner-wrapper .bottom-section h4 {
    font-size: 1.2rem;
    color: #363C4F;
}

.decision-card-wrapper .inner-wrapper .bottom-section p {
    font-size: 0.7rem;
    color: #363C4F;
    margin-top: 5px;
}

.selfCards{
    background-color:white;
    padding:10px;
    display:flex;
    justify-content:space-around;
    flex-direction:column;
    border-radius: 10px;
    height:180px;
    flex-basis: 250px;
}
