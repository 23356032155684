.title-class{
    color:rgba(0, 0, 0, 1);
    font-size:24px;
    font-weight: 700;
    text-align: left;
    padding-left: 10px;
}
.title-subclass{
    color: rgba(106, 113, 125, 1);
    font-size:16px;
    font-weight: 400;
    text-align: left;
    padding-left: 10px;
}

.formDiv{
    width:100%;
    height:250px;
    display:flex;
    box-sizing:border-box;
    flex-direction:column;
    justify-content:space-around;
    padding:20px;
    border-radius:8px;
}

.pop{
    right:5%;
    top:120px;
    position:fixed;
    z-index:10;
    height:150px;
    width:300px
}

.box-notifier{
    width:20px;
    height:20px;
    transform:rotate(45deg);
    position:absolute;
    bottom:-18px;
    left:20px;
    border:1px solid lightblue;
    background-color: white;
    z-index:11
}

.notifier-inner{
    width:40px;
    height:19px;
    background-color: white;
    position:absolute;
    bottom:-10px;
    left:15px;
    
    z-index:14
}

.notifying-text-box{
    height:100%;
    padding:5px;
    z-index:10;
    border-radius:7px;
    border:1px solid rgba(195, 212, 255, 1);
    position:absolute;
    top:0px;
    left:0px;
    right:0px;
    background-color:white;
    display:flex;
    flex-direction:column;
    justify-content:space-between
}


.notifying-text-box .text-section{
    height:70%;
    box-sizing:border-box;
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    align-items:center
}

.notifying-text-box .text-section span{
    padding:4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    color:#407BFF;
    width:20px;
    height:20px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:50%;
    border:1px solid #407BFF;
}

.notifying-text-box .nav-section{
    border:0.1px solid lightblue;
    display:flex;
    justify-content:center;
    align-items:center;
    box-sizing:border-box;
    border-width: 0.1px 0px 0px 0px;
    height:30%;
}

.notifying-text-box .nav-section span{
    width:90px;
    cursor:pointer;
    padding:7px 10px;
    display:flex;
    align-items:center;
    justify-content:center;
    border-radius:5px;
    background-color:#407BFF;
    color:white;
}