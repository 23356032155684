.tablePremblyHold{
    margin-top: 40px;
}

.tablePremblyHold .roof{
    display:flex;
    margin-bottom:30px;
    background-color: #EBF4FF;
    justify-content: space-between;
    padding: 20px;
}


.tablePremblyHold .itemHold{
    width: 25%;
    text-align: left;
    color: #130F26;
    font-weight: 700;
}

.tablePremblyHold .itemHoldBody{
    width: 25%;
    text-align: left;
    color: #130F26;
    font-weight: 500;
}

.tablePremblyHold .body{
    display:flex;
    margin-bottom:30px;
    justify-content: space-between;
    padding: 20px;
}