.not-enabled-body * {
  box-sizing: border-box;
}

.not-enabled-body {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 10px;
  gap: 2em 1em; 
  width: 100%;
  padding: 3em;
  margin: 2em 0 0 0;
}