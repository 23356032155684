.titleWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.formtTitle {
    margin-right: auto;
    line-height: 26.04px;
    font-size: 20px;
    font-weight: 700;
}
.applyBtn {
    height: 48px;
    width: 214px;
    padding: 10px 20px 10px 20px;
    gap: 10px;
    border-radius: 4px;
    outline:none;
    background-color: #fff;
    color: #2B52AA;
    font-size: 16px;
    font-weight: 500;
    line-height: 20.83px;
    white-space: nowrap;
    border:1px solid #2A38A4;

}

.applyBtn_contained {
background-color:#2A38A4 ;
color: #fff;
border:none;
}

.profileDetail {
    margin-block:50px;
    padding: 24px 18px 24px 18px;
    background-color: #EFF4FF;
    width: 100%;
    border-radius: 10px;
    /* min-height: 173px; */
}

.profileDetailTitle {
    color: #1F2227;
    font-weight: 700;
    font-size: 20px;
    line-height: 26.04px;
    margin-bottom: 30px;
}

.profileDetailList {
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
}

.profileDetailListItem {
    flex-basis: calc(25% - 37.5px);
}

.profileDetailListItemAddress {
    flex-basis: calc(33% - 37.5px);
}

.profileDetailListItem h6, .profileDetailListItemAddress h6 {
    color: #363C4F;
    font-weight: 500;
    line-height: 20.83px;
    font-size: 16px;
    text-transform: capitalize;
    margin-bottom: 10px;
}

.profileDetailListItem h5, .profileDetailListItemAddress h6 {
    color: #1F2228;
    font-weight: 500;
    line-height:26.04px;
    font-size: 20px;
    text-transform: capitalize;
}

.tableContainer {
    padding: 24px 24px 24px 24px;
    background-color: #EFF4FF;
    border-radius: 10px;
}

.button {
    padding: 18px 20px 18px 20px;
    border-radius: 4px;
    width: 147px;
    max-height: 32px;
    white-space:nowrap;
    font-family: inherit;
    outline: none;
    border:none;
    background-color: transparent;
    font-weight: 500;
    font-size: 14px;
    line-height: 18.23px;
    }
    
    .contained {
        composes: button;
        background-color:#2B52AA;
        color: #fff;
    }
    
    .outlined {
        composes: button;
        border: 1px solid #2B52AA;
        color: #2B52AA;
        background-color: #fff;
    }

/* transaction detail */

.profileDetailBullet li {
    color: #130F26;
    font-size: 20px;
    font-weight: 400;
    line-height: 26.04px;
}

.profileDetailHeader {
    display: flex;
    margin-bottom: 20px;
}

.profileDetailHeader h5 {
    margin-right: auto;
}
.profileDetailHeaderRight {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 20.83px;
    color:#407BFF;
    cursor: pointer;
}

.profileDetailListDocWrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: 220px;
    row-gap: 20px;
}

.profileDetailListDoc {
    flex-basis: calc(50% - 110px);
    display: flex;
    gap: 50px;
}

.profileDetailListCommWrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.profileDetailListComm {
    flex-basis: 100%;
    display: flex;
  
}

.docsDetail {
    margin-right: auto;   

}

.docsDetail h6 {
    color: #363C4F;
    font-size:18.7px;
    line-height: 24.35px;
    font-weight: 700;

}

.docsDetailMeta {
    display:flex;
    align-items: center;
    font-size: 16.83px;
    line-height: 21.92px;
    font-weight: 400;
    color: #848484;
}

.avatarName {
    width:64px;
    height:64px;
    border-radius: 50%;
    background-color: #DCEAFF;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    font-weight: 700;
    font-size: 40px;
    line-height: 52.08px;
    color:#2A38A4
}


.commentForm {
    border: 1px solid #D9D9D9;
    border-radius: 8px;
    margin-top:20px;
    padding: 20px;
}

.textareaContainer {
    border: none;
    border-bottom:  1px solid #0000001A;
}

.commentBtnWrapper {
    display: flex;
    justify-content: flex-end;
    margin-top:30px;
}

.commentBtnWrapper button {
    background: #407BFF;
}

.contained:disabled {
    cursor:not-allowed;
    opacity: 0.5;
}
