.BreakDownloadHold{
    width:100%;
    display:flex;
    align-items:center;
    justify-content:space-between;
}

@media only screen and (max-width:600px){
    .BreakDownloadHold{
        width:100%;
        display:flex;
        align-items:start;
        justify-content:space-between;
        flex-direction: column;
        margin-bottom: 10px;
    }

}