/* CUSTOM SCORE CARD */
.custom-score-main-container {
  display: flex;
  flex-direction: column;
  gap: 1em;
  background-color: #fff;
  border-radius: 5px;
  padding: 2em;
  width: calc(100% - 4em);
}

.score-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.score-card-container {
  flex: 1 1 200px;
  border: 1px solid #C3D4FF;
  border-radius: 4px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.score-card-container:only-child {
  flex: none;
  width: 50%;
}

.score-card-container .score-card-icon-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.score-card-container .score-card-icon {
  background-color: #2A38A4;
  border-radius: 10px;
  padding: .7em;
}

.score-card-form {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.score-card-form-inputs-container {
  display: flex;
  gap: .7em;
  flex-wrap: wrap;
}

.custom-credit-score-input-field {
  display: flex;
  flex-direction: column;
  flex: 1 1 250px;
}

.custom-credit-score-input-field label {
  font-size: 1.05em;
}

.custom-credit-score-input-field input,
.custom-credit-score-input-field select {
  padding: .7em 1em;
  background-color: #EFF4FF;
  border: none;
}

.score-card-submit-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 1em;
}

.score-card-submit-container button {
  padding: .7em 3em;
  font-weight: 600;
}

.custom-credit-accept-check {
  display: flex;
  align-items: center;
  gap: .5em;
}

.emptyField{
  display: none
}