.st-details-body {
  width: 100%;
  padding: 4em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.st-details-body-spinner {
  width: 100%;
  height: calc(100vh - 5em);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Top Buttons */
.st-top-buttons {
  width: calc(100% - 5em);
  padding: 0 2.5em;
  margin: 0 0 1em 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}

.st-report-buttons-container {
  display: flex;
  gap: 2em;
}

/* Statement stats */
.st-details-main-header-container {
  width: calc(100% - 5em);
  padding: 0 2.5em;
}

.st-details-main-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1em;
}

.st-details-main-header h2 {
  word-break: break-all;
  font-size: 2.3em;
  margin: 0 0 .2em 0;
}

.st-details-main-header-stats-container {
  display: flex;
  flex-wrap: wrap;
  gap: .5em;
}

.st-details-main-header-sub {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1 500px;
}

.st-details-main-header-sub h3,
.st-details-main-header-sub p {
  margin: 0;
  font-size: 1em;
}

.st-details-main-header-sub h3 {
  margin: 0 1em 0 0;
}

.st-details-main-header-sub img {
  width: 1.5em;
  height: 1.5em;
  margin: 0 .5em 0 0;
}

/* Statement Stats */
.st-main-details-container {
  width: 100%;
  margin: 3em 0 2em 0;
  display: flex;
  align-items: center;
  background-color: #DCE5F1;
}

.st-main-details-top {
  display: flex;
  width: calc(100% - 9em);
  padding: 1.5em 4.5em;
  justify-content: space-between;
  align-items: center;
}

.st-main-details {
  display: flex;
  align-items: center;
  gap: 0 1em;
}

.st-main-details-image-container {
  padding: .5em;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2A38A4;
}

.st-main-details .st-main-details-image-container img {
  width: 1.5em;
  height: 1.5em;
}

.st-main-details .st-main-details-text-container label {
  font-size: 1.1em;
}

.st-main-details .st-main-details-text-container p {
  margin: 0;
  font-size: 1.1em;
  font-weight: 600;
}

/* Statement Uploaded or Failed */
.st-not-processed-container {
  width: 80%;
  margin: 1em 0;
}

.st-not-processed-container .cards {
  background-color: #fff;
}

.st-not-processed-container .details-header-row {
  display: flex;
  gap: 1em;
  align-items: center;
}

.st-not-processed-container .st-details-card p {
  font-weight: 400;
  font-size: 1.2em;
  margin: .5em 0 0 0;
}

.st-not-processed-container .st-details-card p a {
  font-weight: 500;
}

.st-not-processed-container .st-details-card h3 {
  font-size: 1.8em;
}

.st-not-processed-container .dc-not-processed-header-container {
  display: flex;
  align-items: center;
  gap: 0 1em;
  margin: 0 0 1em 0;
}

.st-not-processed-container .dc-status-icon-container {
  display: none;
}

.st-not-processed-container img {
  width: 1.8em;
  height: 1.8em;
}

@media only screen and (max-width: 950px) {
  .st-details-main-header {
    flex-direction: column;
  }

  .st-details-main-header-left {
    width: 100% !important;
  }

  .st-details-main-header-right {
    width: 100%;
    margin: 2em 0 0 0;
  }

  .st-error-message-container {
    width: 100%;
    left: unset;
  }
}

@media only screen and (max-width: 850px) {
  .st-main-details-top {
    flex-direction: column;
    align-items: flex-start;
    gap: 1em 0;
    width: calc(100% - 5em);
    padding: .8em 2.5em;
  }
}

@media only screen and (max-width: 400px) {
  .st-main-details-top, .st-updates-fields-container {
    flex-direction: column;
  }

  .st-updates-fields, .st-main-details {
    width: 100%;
    margin: 1em 0;
  }

  .dt-div {
    display: none;
  }

  .st-main-details-bottom {
    margin: 0;
  }

  .st-details-header-row {
    height: auto;
    text-align: center;
    flex-direction: column;
    justify-content: center;
  }
}