.title-class{
    color:rgba(0, 0, 0, 1);
    font-size:24px;
    font-weight: 700;
    text-align: left;
    padding-left: 10px;
}
.title-subclass{
    color: rgba(106, 113, 125, 1);
    font-size:16px;
    font-weight: 400;
    text-align: left;
    padding-left: 10px;
}

.history-hold{
    margin-top:40px;
    display:flex;
    background-color:whitesmoke;
    flex-direction:column;
    justify-content:space-around;
    border:1px solid rgba(196, 211, 249, 1);
    border-width:1px 0px 0px 0px;
    padding:20px;
    padding-top:35px
}

.history-hold .roof{
    display:flex;
    align-items:center;
    justify-content:start;
    column-gap:5px;
    margin-top:20px
}

.amlScreenHold{
    background-color: white;
    width:100%;
    margin-top:35px;
    padding:20px 30px;
    box-sizing:border-box;
    height:280px;
    border-radius:10px;
}

.amlScreenHoldIndi{
    background-color: white;
    width:100%;
    margin-top:35px;
    padding:20px 30px;
    box-sizing:border-box;
    height:370px;
    border-radius:10px;
}

.proceedScreen{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(64, 123, 255, 1);
    border-radius: 5px;
    height: 40px;
    width:120px;
    color: white;
    cursor:pointer;
}

.proceedAltScreen{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(142, 174, 244);
    border-radius: 5px;
    height: 40px;
    width:120px;
    color: white;
}

.ScreenInputHold{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.ScreenInputDiv{
    display: flex;
    width:45%;
    flex-direction: column;
    row-gap: 6px;
}

.businessInputHold{
    width:100%;
}

.businessInputHold input{
    height:50px;
    background-color: rgba(239, 244, 255, 1);
    outline: none;
    border-width: 0px;
    padding-left: 10px;
    box-sizing: border-box;
    width: 100%;
}


.ScreenInputDiv input{
    height:50px;
    background-color: rgba(239, 244, 255, 1);
    outline: none;
    border-width: 0px;
    padding-left: 10px;
    box-sizing: border-box;
}

.ScreenSlidingInput{
    display: flex;
    width:100%;
    justify-content: space-between;
    align-items: center;
}

.ScreenSlidingInput input{
    height:35px;
    background-color: rgba(239, 244, 255, 1);
    outline: none;
    border-width: 0px;
    width: 70%;
}


.amlScreenTitle{
    color:rgba(42, 56, 164, 1);
    font-size: 24px;
    font-weight: 700;
}

.amlScreenDescript{
    margin-top: 15px;
    color:rgba(106, 113, 125, 1);
    font-size: 16px;
    font-weight: 400; 
}