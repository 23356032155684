.tableHighlight{
    width:35%;
    background-color:white;
    height:300px;
    padding:10px;
    box-sizing:border-box;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
}

.tableHighlight .header{
    color:#130F26;
    font-weight: 500;
    font-size: 18px;
}

.Audit-rows{
    display: flex;
    justify-content: space-between;
    margin: 20px 0px;
}

.Audit-cols{
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.Audit-cols .header{
    color:#6A717D;
    font-size: 18px;
    font-weight: 500px;
}

.Audit-rows .header{
    color:#6A717D;
    font-size: 18px;
    font-weight: 500px;
}