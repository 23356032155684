.transactions-main {
  margin: 1.5em 0 1em 0;
  padding: 2em 2em 1em 2em;
  width: calc(100% - 3em);
  border-radius: 10px;
  position: relative;
  background: #fff;
}

.transactions-main .tb-filter-container {
  top: 1.5em;
}

.transactions-main h3 {
  font-size: 1.3em;
  margin: 0 0 1em 0;
}

.transactions-main .transactions-type-table {
  border-spacing: 0 .5em;
  margin: 1em 0 0 0;
}

.transactions-main .table-container {
  position: unset;
}

.transactions-main {
  position: relative;
}

.transactions-main .tb-filter-container {
  right: 2em;
  top: 2em;
}

.transactions-main .table-container thead tr th div {
  padding: 0;
}

.transactions-type-table .header-row {
  padding: 0 1em;
}

.transactions-type-table tbody tr td {
  border: none !important;
  padding: .9em 1em;
}

.transactions-type-table tbody tr td:nth-child(2) {
  width: 40%;
}

.transactions-type-table tbody tr:hover {
  transform: unset;
}

.transactions-type-table .list-overflow-text-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  max-width: 250px;
}

@media only screen and (max-width: 1000px) {
  .transactions-type-table .list-overflow-text-container {
    max-width: 200px;
  }
}

@media only screen and (max-width: 900px) {
  .transactions-type-table .list-overflow-text-container {
    max-width: 100%;
  }

  .transactions-main .table-container thead tr th {
    padding: .2em .5em;
  }
}