/* Shared Tabs Styles */
.tabs-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.st-details-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  cursor: pointer;
  transition: transform .3s;
}

.st-details-header h4 {
  font-size: 1.05em;
}

/* Top Tabs */
.top-level-tabs .st-details-header {
  text-align: left;
  display: flex;
  flex-direction: row;
  padding: 0 2em;
  box-shadow: unset !important;
}

.top-level-tabs .st-details-header#active {
  color: #2A38A4;
  border-bottom: 4px solid #2A38A4;
}

.top-level-tabs {
  width: calc(100% - 5em);
  padding: 0 2.5em;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 45px;
}

.top-level-tabs .st-details-header h4 {
  margin: 1em 0;
}

/* Analytics Tabs */
.analytics-tabs {
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  gap: 0 1em;
  align-items: center;
  justify-content: center;
  margin: 1em 0 0 0;
  border: none !important;
}

.analytics-tabs .st-details-header {
  border: none !important;
  padding: .8em 2em;
  border-radius: 5px;
}

.analytics-tabs .st-details-header#active {
  background-color: #fff;
  color: #2A38A4;
  box-shadow: 0 0 16px rgba(0, 0, 0, .12);
}

.analytics-tabs .st-details-header h4 {
  margin: 0;
}

.analytics-tabs .st-details-header:hover {
  transform: scaleX(1.05);
}

.analytics-tabs .st-details-header img {
  width: 1.5em;
  height: 1.5em;
  margin: 0 1em 0 0;
}

.analytics-tabs #inactive img {
  filter: grayscale(100%);
}

.analytics-tabs ~ .tab-bodies-container {
  width: 100%;
}

.st-details-body > .tabs-container > .tab-bodies-container {
  width: calc(100% - 5em);
  padding: 0 2.5em;  
}

.analytics-tabs #inactive {
  opacity: .6;
}

/* Boxed Tabs */
.boxed-tabs {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #DFE8FF;
}

.boxed-tabs .st-details-header {
  padding: 1em;
}

.boxed-tabs .st-details-header h4 {
  font-weight: 500;
  font-size: 16px;
  color: #363C4F;
}

.boxed-tabs .st-details-header#active {
  background-color: #2A38A4;
}

.boxed-tabs .st-details-header#active h4 {
  font-weight: 600;
  color: #fff;
}

/* Tabs Body */
.tab-body-header-container {
  margin: 0 0 1em 0;
}

.tab-body-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}


.tab-body-no-form{
  width:75vw;
  margin:0px auto
}


.tab-body-form{
  width:75vw;
  min-width:300px;
}

@media only screen and (max-width: 1000px) {
  .analytics-tabs {
    gap: 0 1em;
  }
}

@media only screen and (max-width:800px){
  .tab-body-no-form{
    width:90vw;
  }

  .tab-body-form{
    width:90vw;
    min-width:300px
  }
}

@media only screen and (max-width: 820px) {
  .analytics-tabs {
    gap: 0 .5em;
  }
}

@media only screen and (max-width: 780px) {
  .analytics-tabs {
    height: unset;
    display: block;
  }
}

@media only screen and (max-width:600px){
  .top-level-tabs{
    flex-direction: column;
    justify-content: start;
    align-items:center;
    
  }

  .top-level-tabs .st-details-header {
    text-align: center;
    display: flex;
    flex-direction: row;
    padding: 0 2em;
    box-shadow: unset !important;

  }

  
}