.stepOne{
    width:550px;
    height:170px;
    background-color: #fff;
    position:absolute;
    transform: translate(-50%,-50%);
    top:50%;
    left:50%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tour-button-one, .tour-button-two{
    border-radius:7px;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
  }

  .tour-button-one{
    border:0.1px solid rgba(64, 123, 255, 1);
    color:rgba(64, 123, 255, 1);
  }

  .tour-button-two{
    background-color:rgba(64, 123, 255, 1);
    color:white
  }