.addButton{
    display: flex;
    justify-content: center;
    align-items: center;
    width:50px;
    height:50px;
    background-color: rgba(223, 232, 255, 1);
    color:white;
    font-size: 30px;
}

.addButtonSelected{
    background-color: rgba(64, 123, 255, 1);
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    width:50px;
    height:50px;
    color:white;
    font-size: 30px;
}

.createdParameter{
    border-radius: 7px;
    color: white;
    background-color: rgba(64, 123, 255, 1) ;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 7px;
    height:50px;
    width:230px;
    cursor: pointer;
    margin-top: 20px;
}


.createNewParameter{
    border-radius: 7px;
    color: white;
    background-color: rgba(223, 232, 255, 1);
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 7px;
    height:50px;
    width:230px;
    margin-top: 20px;
}