.modal-initial-button-hold{
    display:flex;
    justify-content:start;
    column-gap:15px
}

.modal-initial-button-hold .cancel{
    border:1px solid rgba(64, 123, 255, 1);
    width:150px;
    cursor:pointer;
    height:40px;
    color:rgba(64, 123, 255, 1);
    display:flex;
    border-radius:6px;
    align-items:center;
    justify-content:center;
}

.modal-initial-button-hold .close{
    border:1px solid rgba(64, 123, 255, 1);
    width:150px;
    cursor:pointer;
    height:40px;
    background-color:rgba(64, 123, 255, 1);
    color:white;
    display:flex;
    border-radius:6px;
    align-items:center;
    justify-content:center;
}

.modal-initial-button-hold .check{
    background-color:rgba(64, 123, 255, 1);
    width:150px;
    cursor:pointer;
    height:40px;
    display:flex;
    color:white;
    border: none;
    border-radius:6px;
    align-items:center;
    justify-content:center
}

.modal-loading,.modal-error,.modal-success{
    height:200px;
    width:100%;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: space-around;
}
