.idVerifyHead{
    height:auto;
    padding-left: 20px;
    box-sizing: border-box;
    border:1px solid rgba(203, 218, 255, 1);
    border-width: 0px 0px 1px 0px;
}

.idVerifyContent{
    height:90px;
    display:flex;
    justify-content: start;
    align-items: center;
    cursor:pointer
}

.idVerifyHead span{
    display: flex;
    align-items: center;
    justify-content: center;
}

.idVerifyHead p{
   color:rgba(31, 34, 39, 1);
   font-size: 24px;
   line-height: 31px;
   margin-left: 10px;
   font-weight: 700;
}