.kyc-history-holder{
    margin-top:40px;
    display:flex;
    background-color:whitesmoke;
    flex-direction:column;
    justify-content:space-around;
    border:1px solid rgba(196, 211, 249, 1);
    border-width:1px 0px 0px 0px;
    padding:20px;
    padding-top:35px
}

.kyc-history-holder .top-section{
    display:flex;
    align-items:center;
    justify-content:start;
    column-gap:5px;
    margin-top:20px
}

.top-section .span-view-report{
    font-size:16px;
    cursor:pointer;
    color:rgba(196, 196, 196, 1);
    font-weight:700;
}

.top-section .span-view-history{
    font-size:16px;
    cursor:pointer;
    color:rgba(17, 98, 220, 1);
    font-weight:700;
}

.kyc-table-hold{
    margin:10px 0px
}

.kyc-table-hold .header-hold{
    width:100%;
    display:flex;
    justify-content:space-between;
    align-items:center;
    margin-bottom:20px
}

.kyc-table-hold .header-hold p{
    width:15%;
    text-align:left;
    font-weight:700;
    padding-left:7px;
    color:rgba(0, 0, 0, 1);
    font-size:16px
}

.kyc-table-hold .header-hold .divider{
    width:15%;
    box-sizing:border-box;
    text-align:left;
    padding-left:7px;
    color:rgba(0, 0, 0, 1);
    font-size:16px
}


.kyc-table-hold .body{
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    align-items:center
}

.kyc-table-hold .body .inner{
    width:100%;
    background-color:rgba(255, 255, 255, 0.6);
    border:1px solid rgba(255, 255, 255, 1);
    height:45px;
    padding:5px;
    display:flex;
    justify-content:space-between
}

.kyc-table-hold .body .inner .element{
    width:15%;
    display:flex;
    align-items:center;
    justify-content:start;
    padding-left:7px;
    box-sizing:border-box
}

.kyc-table-hold .body .inner .found{
    width:15%;
    display:flex;
    align-items:center;
    justify-content:start;
    padding-left:7px;
    box-sizing:border-box;
    color:rgba(0, 119, 12, 1);
    font-weight:700;font-size:16px}

    .kyc-table-hold .body .inner .viewHold{
        width:15%;
        display:flex;
        align-items:center;
        justify-content:center
    }

    .kyc-table-hold .body .inner .viewHold span{
        background-color:rgba(64, 123, 255, 1);
        cursor:pointer;
        color:white;
        width:fit-content;
        padding:7px 20px;
        border-radius:5px;
        display:flex;
        align-items:center;
        justify-content:center
    }