


.DialogOverlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  inset: 0;
  z-index: 1000;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.DialogContent.ReactModal__Content--after-open{
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 652px;
  max-height: 85vh !important;
  padding: 25px;
  border-radius: 5px !important;
  overflow-y: auto !important;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}


.DialogContent:focus {
  outline: none;
}

.DialogTitle {
  margin: 0;
  font-weight: 700;
  color: #130F26;
  font-size: 25px;
  line-height:31.25px ;
}




.IconButton {
  font-family: inherit;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  outline:none;
  border:none;
  cursor: pointer;
}

