.repaymentHolder{
    width:100%;
    background-color:white;
    padding:10px;
    box-sizing: border-box;
    padding: 15px;
    box-sizing: border-box;
    
}

.paySectionOne{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 10px;
    box-sizing: border-box;
    font-weight: 700;
    background-color: #2A38A4;
    padding: 15px;
    color:#FFFFFF;
}

.paySectionTwo{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    font-weight: 700;
    background-color: #EBF4FF;
    color:#130F26;
    padding: 15px;
    box-sizing: border-box;
}

.firstItemTitle{
    width:30%;

}

.secondTitle{
    width:20%;

}

.thirdTitle{
    width:15%;

}

.fourthTitle{
    width:5%;

}

.paySectionThree{
    width:100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    color:#130F26;
    padding: 15px;
    box-sizing: border-box;
}

.tabSpanHeader{
    color: #130F26;
    font-weight: 700;
    font-size: 16px;
}

.tabSpanHolder{
    background-color:#EBF4FF;
    width:100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.tabSpanHolder span{
    width:33.3%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 7px;
    box-sizing: border-box;
}

.bodySpanHolder{
    width:100%;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.bodySpanHolder span{
    width:33.3%;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: 7px;
    box-sizing: border-box;
}

.tabToggleHold{
    width:100%
}

.extraInfoParent{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    row-gap: 10px;
    margin-bottom: 20px;
    width:100%;
}

.extraInfoBlock{
    width:25%;
    padding:10px;
    box-sizing: border-box;
}

.extraInfoBlock .title{
    color:#130F26;
    font-size: 16px;
    font-weight: 400;
    text-align: left;
}

.extraInfoBlock .value{
    color:#130F26;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
}

.extraInfoHold{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
}