/* Cards Container and rows*/
.st-details-card-container {
  display: flex;
  flex-direction: column;
  gap: 2em 1em; 
  width: 100%;
  margin: 2em 0 0 0;
}

.st-details-card-container-row {
  display: flex; 
  flex-direction: row;
  width: 100%;
  gap: 1em;
}

/* Income Tab Cards */
.st-analytics-income .st-details-card {
  width: calc(100% - 4em);
  padding: 2em;
}

.st-analytics-income table th,
.st-analytics-income table td {
  padding: .5em 0;
}

.st-analytics-income table tbody tr {
  margin: .3em .5em 0 0 !important;
}

.st-analytics-income table thead tr th:first-child,
.st-analytics-income table tbody tr td:first-child {
  padding: 0 0 0 .5em;
  width: calc(20% - .5em);
}

.st-analytics-income table thead tr th:nth-child(2),
.st-analytics-income table tbody tr td:nth-child(2) {
  width: 20%;
}

.st-analytics-income table thead tr th:last-child,
.st-analytics-income table tbody tr td:last-child {
  padding: 0 .5em 0 0;
  width: calc(60% - .5em);
}

/* Spend Tab Cards */
.st-analytics-spend-top-row-container {
  gap: 1em;
  flex-direction: column;
  width: 100%;
}

.st-analytics-spend-top-row-container .st-analytics-spend-top-row {
  display: flex;
  gap: 1em;
}

.st-analytics-spend-top-row .st-details-card-main {
  width: 25%;
}

.st-analytics-spend-top-row .cards {
  padding: 1em;
}

.st-analytics-spend-top-row .st-details-card {
  flex-direction: row;
  gap: 0 1em;
  width: calc(100% - 3em);
}

.st-analytics-spend-top-row .st-details-card .details-header-row {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
}

.st-analytics-spend-top-row .st-details-card svg {
  width: 3em;
  height: 3em;
}

.st-analytics-spend-top-row .st-details-card .details-header-row h3 {
  font-size: 1.5em;
}

.st-analytics-spend-top-row:first-child .st-details-card .details-header-row h3 {
  font-size: 1.2em;
  word-break: break-all;
}

.st-analytics-spend-top-row:first-child .st-details-card .details-header-row h4 {
  font-size: .9em;
}

.st-analytics-spend-top-row .st-details-card-main {
  width: 25%;
}

.st-analytics-spend-top-row .cards {
  padding: 1.5em;
}

.st-analytics-spend-top-row .st-details-card {
  flex-direction: row;
  gap: 0 1em;
  width: calc(100% - 3em);
  justify-content: center;
  align-items: center;
}

.st-analytics-spend-top-row:nth-child(2) .st-details-card {
  text-align: center;
}

.st-analytics-spend-top-row .st-details-card .details-header-row {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
}

.st-analytics-spend-top-row .st-details-card svg {
  width: 3em;
  height: 3em;
}

.st-analytics-spend-top-row .st-details-card .details-header-row h3 {
  font-size: 1.5em;
}

.st-spend-table-plus-atm table tr {
  width: calc(100% - 1em) !important;
  margin: 0 1em 0 0 !important;
}

.st-spend-table-plus-atm table thead tr th,
.st-spend-table-plus-atm table tbody tr td {
  padding: .4em 0;
  width: 40%;
}

.st-spend-table-plus-atm table thead tr th:first-child,
.st-spend-table-plus-atm table tbody tr td:first-child {
  padding: .4em 0;
  width: 20%;
}

.st-spend-table-plus-atm table thead tr th:not(:first-child),
.st-spend-table-plus-atm table tbody tr td:not(:first-child) {
  text-align: end;
}

.st-spend-table-plus-atm .st-details-scroll-card:only-child table thead tr th,
.st-spend-table-plus-atm .st-details-scroll-card:only-child table tbody tr td {
  width: 40%;
}

.st-spend-table-plus-atm .st-details-scroll-card:only-child table thead tr th:first-child,
.st-spend-table-plus-atm .st-details-scroll-card:only-child table tbody tr td:first-child {
  width: 20%;
}

/* Scroll Table and ATM Card */
.st-spend-table-plus-atm {
  gap: 1em;
}

.st-spend-table-plus-atm .st-details-scroll-card {
  width: 70%;
}

.st-spend-table-plus-atm .st-details-scroll-card:only-child {
  width: 100%;
}

.st-spend-table-plus-atm .st-details-scroll-card + .st-details-card-main {
  width: 30%;
}

.st-details-scroll-card .st-details-card {
  width: calc(50% - 4em);
}

/* Scroll card table */
.st-details-scroll-card .details-table-container {
  display: flex;
  position: relative;
}

.st-details-scroll-card .table-container {
  border-radius: 5px;
  padding: 0;
  width: 100%;
}

.st-details-scroll-card table thead tr,
.st-details-scroll-card table tbody tr {
  width: calc(100% - .5em);
  margin: 0 .5em 0 0;
  display: flex;
  align-items: center;
  gap: 1em;
  border-radius: 0;
}

.st-details-scroll-card table thead tr {
  width: calc(100% - 1em);
  margin: 0 1em 0 0;
}

.st-details-scroll-card table thead,
.st-details-scroll-card table tbody {
  width: 100%;
  display: flex;
}

.st-details-scroll-card table tbody {
  flex-direction: column;
}

.st-details-scroll-card .table-container table .table-body-container {
  max-height: 200px;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

/* ATM List */
.st-spend-table-plus-atm .st-atm-list-container {
  flex-basis: 0px;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.st-spend-table-plus-atm #st-atm-list {
  flex-basis: unset;
  flex-grow: unset;
}

.st-spend-table-plus-atm .st-atm-list-container .st-details-list-main-container {
  width: 100%;
}

.st-spend-table-plus-atm .st-details-list {
  display: flex;
  flex-direction: column;
  gap: .2em;
}

.st-spend-table-plus-atm .st-details-list li {
  padding: .5em 1em;
  margin: 0 .4em 0 0;
}

.st-spend-table-plus-atm .st-details-card .details-header-row {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.st-spend-table-plus-atm .st-details-card .atm-icon {
  width: 2em;
  height: 2.5em;
}

/* Bar Graphs Row */
.st-details-graphs-row {
  width: 100%;
}

.st-details-graphs-row .st-details-card-main {
  width: calc(50% - 1em);
}

.st-details-graphs-row .details-stacked-bar-container {
  min-height: 200px;
  max-height: 500px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

.st-details-graphs-row .details-stacked-bar-container .stacked-bar {
  max-width: 100%;
  height: 800px;
}

/* Pattern Tab Cards */
.st-details-pattern-bottom-row,
.st-details-pattern-top-row,
.st-details-behavior-top-row {
  width: 100%;
  gap: 1em;
}

.pattern-cards .st-details-pattern-transactions .st-details-list-pattern {
  display: flex;
  flex-direction: column;
  gap: .3em;
}

.st-details-pattern-top-row .list-type-content h2 {
  font-size: 1.1em;
  font-weight: 500;
  margin: .5em 0;
}

.st-details-pattern-top-row .list-type-content p {
  font-size: 1.5em;
  font-weight: 600;
}

.pattern-cards .missing-transactions-enabled {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pattern-cards .missing-transactions-enabled .st-details-list {
  flex-direction: column;
  gap: .5em;
}

.pattern-cards .missing-transactions-enabled .st-details-list li {
  width: 100%;
}

.pattern-cards .missing-transactions-enabled .st-details-list .list-type-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.pattern-cards .missing-transactions-enabled .st-details-list .list-type-content p,
.pattern-cards .missing-transactions-enabled .st-details-list .list-type-content h2 {
  font-size: 1.1em;
  margin: 0;
}

.pattern-cards .st-details-pattern-transactions .st-details-list-pattern .list-type-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2em;
}

.pattern-cards .st-details-card-main {
  width: 50%;
}

.pattern-cards .self-transfer-flows .list-type-content,
.simple-list-container .list-type-content {
  display: flex;
  justify-content: space-between;
}

.pattern-cards .self-transfer-flows .list-type-content h2 { font-weight: 500; }
.pattern-cards .self-transfer-flows .list-type-content p { font-weight: 600; }

.pattern-cards .self-transfer-flows li:first-child,
.simple-list-container li:first-child {
  margin: 0 0 1em 0;
}

.pattern-cards .self-transfer-flows .list-type-content h2,
.pattern-cards .self-transfer-flows .list-type-content p,
.simple-list-container .list-type-content h2,
.simple-list-container .list-type-content p {
  font-size: 1.1em;
}

.st-details-pattern-bottom-row .st-detail-color-background-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}

.st-details-pattern-bottom-row .details-header-row {
  width: calc(100% - 4em);
  padding: 2em 2em 1.5em 2em;
}

.details-doughnut {
  width: 100%;
  height: 15em;
}

/* Agent Transactions */
.pattern-cards .st-row-agent-transactions .cards {
  width: 100%;
}

.pattern-cards .st-row-agent-transactions .simple-table-main-container h2 {
  margin: 0 0 .5em 0;
}

.pattern-cards .st-row-agent-transactions .table-container {
  position: unset;
}

.pattern-cards .st-row-agent-transactions .simple-table-main-container {
  position: relative;
}

.pattern-cards .st-row-agent-transactions .tb-filter-container {
  top: 0;
  right: 0;
}

.pattern-cards .st-row-agent-transactions table thead tr th {
  padding: .3em .5em;
}

.pattern-cards .st-row-agent-transactions table tbody tr td {
  padding: .5em;
  width: 12%;
}

.pattern-cards .st-row-agent-transactions table tbody tr td:first-child {
  width: 24%;
}

.pattern-cards .st-row-agent-transactions table tbody tr td:nth-child(2) {
  width: 16%;
}

.pattern-cards .st-row-agent-transactions .pagination {
  padding: 1em 0;
  width: 100%;
}

.pattern-cards .st-row-agent-transactions .pagination .pagination-arrows button {
  padding: 0;
}

.st-row-agent-transactions-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Sacco Transactions */
.st-sacco-transactions .st-details-scroll-card {
  width: 100%;
}

.st-sacco-transactions .st-details-scroll-card table thead th,
.st-sacco-transactions .st-details-scroll-card table tbody td{
  width: 33%;
}

/* Behavioral */
.behavior-cards .st-details-behavior-detail-inflow-main-container .st-details-list-main-container .st-details-list {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.st-details-behavior-top-row .st-details-card {
  justify-content: center;
}

.st-details-behavior-top-row .st-details-behavior-detail-inflow-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.st-details-behavior-top-row .st-details-behavior-inflow-container {
  display: flex;
  gap: 1em;
  align-items: flex-end;
}

.st-details-behavior-top-row .dc-status-bar-container {
  width: 1.1em;
  height: calc(100% - 4px);
  border-radius: 5px;
  position: relative;
  background: #DCE5F1;
  border: 2px solid #2A38A4;
}

.st-details-behavior-top-row .dc-status-bar-container .dc-status-bar {
  width: 100%;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #2A38A4;
}

.st-details-behavior-top-row .dc-status-bar-details {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.st-details-behavior-top-row .st-details-behavior-inflow-container h2,
.st-details-behavior-top-row .st-details-behavior-inflow-container p {
  margin: 0;
}

.st-details-behavior-top-row .st-details-behavior-inflow-container h2 {
  font-size: 2em;
  line-height: .8;
}

.behavior-cards .st-details-double-colored-bottom-row .st-details-loan-list-container {
  padding: 1em;
}

.st-details-double-colored-bottom-row .st-details-card {
  width: 100%;
}

.st-details-double-colored-bottom-row .st-detail-color-background-list-container .st-details-list {
  gap: 1em;
}

.st-details-double-colored-bottom-row .st-detail-color-background-container {
  height: 100%;
}

.st-details-double-colored-bottom-row .st-detail-color-background-list-container {
  height: calc(100% - 5.5em);
  text-align: start;
  position: unset;
  bottom: 0;
  flex-grow: unset;
}

.st-details-double-colored-bottom-row .st-details-double-colored-bottom-row ul li .list-type-content h2 {
  text-align: start;
}

.st-details-double-colored-bottom-row .st-details-loan-list-container {
  height: 100%;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
}

.st-details-double-colored-bottom-row .st-details-loan-list-container ul {
  width: 100%;
}

.st-details-double-colored-bottom-row .details-header-row {
  padding: 2em 1em;
  text-align: center;
  display: flex;
  flex-direction: column-reverse;
}

.st-details-double-colored-bottom-row .details-header-row h4 {
  font-size: 1.2em;
}

.st-details-double-colored-bottom-row .details-header-row h3 {
  font-size: 2.2em;
  margin: .2em 0 0 0;
}

.behavior-cards .details-table-container table thead th,
.behavior-cards .details-table-container table tbody td {
  width: calc(25% - .5em);
}

.behavior-cards .details-table-container table thead th:first-child,
.behavior-cards .details-table-container table tbody td:first-child {
  width: calc(50% - .5em);
  word-break: break-all;
}

/* Cash Flow */
.details-table-container {
  height: calc(100% - 3em);
  justify-content: flex-start;
}

.st-details-no-table-data-container {
  height: calc(100% - 2em);
  margin: 2em 0 0 0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F6F8FC;
}

.st-details-table-row .st-details-scroll-card {
  width: 100%;
}

.st-details-table-row .st-details-scroll-card h2 {
  margin: 0;
}

.st-details-table-row .st-details-scroll-card h4 {
  font-size: 1.2em;
  font-weight: 500;
}

.st-details-table-row table tbody tr {
  margin: .3em 0 0 0;
}

.st-details-table-row table thead tr th,
.st-details-table-row table tbody tr td {
  width: calc(50% - .5em);
  padding: .5em 0 .5em .5em;
}

@media only screen and (max-width: 1254px) {
  .st-analytics-spend-top-row {
    flex-wrap: wrap;
  }
  
  .st-analytics-spend-top-row .st-details-card-main {
    width: 50%;
  }

  .st-details-pattern-top-row .st-details-list-pattern {
    display: flex;
    flex-direction: column;
    gap: .5em;
  }

  .st-details-pattern-top-row .st-details-list-pattern li .list-type-content {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: unset;
    gap: 0;
  }
  
  .analytics-tabs {
    gap: 0;
  }
  
  .analytics-tabs .st-details-header {
    padding: .8em 1em;
  }
}

@media only screen and (max-width: 1100px) {
  .st-details-double-colored-bottom-row {
    flex-direction: column;
  }

  .st-details-table-row {
    flex-direction: column;
  }
}

@media only screen and (max-width: 1000px) {
  .st-details-pattern-top-row,
  .st-details-pattern-bottom-row,
  .st-details-behavior-top-row {
    flex-direction: column;
  }

  .pattern-cards .st-details-card-main {
    width: 100%;
  }

  .st-details-pattern-top-row .st-details-list-pattern li .list-type-content {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 1em 5em;
  }
  
  .st-details-body .analytics-tabs {
    height: unset;
    display: block;
  }
}

@media only screen and (max-width: 900px) {
  .st-details-scroll-card .details-table-container thead tr th,
  .st-details-scroll-card .details-table-container tbody tr td {
    text-align: start;
  }

  .st-details-scroll-card .table-container table thead tr,
  .st-details-scroll-card table tbody tr {
    flex-direction: column;
    gap: .5em;
    align-items: flex-start;
  }
  
  .st-details-scroll-card table tbody tr td:last-child {
    margin: 0 0 1.5em 0;
  }

  .st-analytics-income table tbody tr {
    padding: .5em 0;
  }

  .st-analytics-income table thead tr th,
  .st-analytics-income table tbody tr td,
  .st-analytics-income table thead tr th:last-child,
  .st-analytics-income table tbody tr td:last-child {
    padding: 0 0 0 .5em;
  }

  .st-analytics-income table tbody tr td:last-child,
  .cash-flow-content .st-details-table-row table tbody tr td:last-child {
    margin: 0;
  }

  .st-details-scroll-card .table-container table .table-head-container thead tr th:first-child {
    width: 100%;
  }
  
  .st-details-scroll-card .table-container table .table-body-container tbody tr td:first-child {
    width: calc(100% - 1em);
  }
  
  .st-details-scroll-card .table-container table .table-head-container thead tr th:not(:first-child) {
    width: 100%;
  }
  
  .st-details-scroll-card .table-container table .table-body-container tbody tr td:not(:first-child) {
    width: 100%;
  }

  .st-details-scroll-card .table-container table .table-head-container {
    padding: .5em 0;
  }

  .st-details-body .top-level-tabs .st-details-header {
    padding: 0 1em;
  }

  .st-details-graphs-row {
    flex-direction: column;
  }

  .st-details-graphs-row .st-details-card{
    width: calc(100% - 4em);
  }

  .st-details-graphs-row .st-details-card-main {
    width: 100%;
  }
  
  .st-spend-table-plus-atm {
    flex-direction: column;
  }

  .st-spend-table-plus-atm .st-details-scroll-card {
    width: 100%;
  }

  .st-spend-table-plus-atm .st-details-scroll-card + .st-details-card-main {
    width: 100%;
  }

  .st-spend-table-plus-atm .st-atm-list-container {
    flex-basis: unset;
    flex-grow: unset;
  }
}

@media only screen and (max-width: 800px) {
  .st-analytics-income {
    flex-direction: column;
  }

  .st-analytics-income .st-details-card-main {
    width: 100%;
  }

  .st-analytics-spend-top-row:first-child .st-details-card .st-details-icon-container {
    display: none;
  }

  .st-analytics-spend-top-row:first-child .st-details-card .details-header-row {
    text-align: center;
  }

  .st-analytics-spend-top-row:first-child .st-details-card .details-header-row h3 {
    font-size: 1.5em;
  }

  .st-analytics-spend-top-row:first-child .st-details-card .details-header-row h4 {
    font-size: 1em;
  }

  .st-analytics-spend-top-row-container .st-analytics-spend-top-row {
    flex-direction: column;
  }

  .st-analytics-spend-top-row .st-details-card-main {
    width: 100%;
  }

  .st-spend-table-plus-atm {
    flex-direction: column-reverse;
  }

  .st-spend-table-plus-atm .st-details-scroll-card {
    width: 100%;
  }

  .st-details-pattern-top-row .st-details-list-pattern li .list-type-content {
    gap: 1em 2em;
  }
}

@media only screen and (max-width: 700px) {
  .st-analytics-spend-top-row {
    flex-direction: column;
  }
  
  .st-analytics-spend-top-row .st-details-card-main {
    width: 100%;
  }

  .pattern-cards .self-transfer-flows {
    flex-direction: column;
  }
}

@media only screen and (max-width: 650px) {
  .st-details-body .top-level-tabs {
    height: unset;
    display: block;
  }
}

@media only screen and (max-width: 500px) {
  .st-details-pattern-top-row .st-details-list {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .st-details-pattern-top-row .st-details-list li {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  .st-details-pattern-top-row .st-details-pattern {
    flex-direction: column;
  }
}