.loanHead{
    height:auto;
    padding-left: 20px;
    box-sizing: border-box;
    border:1px solid rgba(203, 218, 255, 1);
    border-width: 0px 0px 1px 0px;
}

.loanContent{
    height:90px;
    display:flex;
    justify-content: start;
    align-items: center;
    cursor:pointer
}

.loanHead span{
    display: flex;
    align-items: center;
    justify-content: center;
}

.loanHead p{
   color:rgba(31, 34, 39, 1);
   font-size: 24px;
   line-height: 31px;
   margin-left: 10px;
   font-weight: 700;
}

.loanBody{
    padding:20px;
    box-sizing: border-box;
}

.bodyhead{
    display: flex;
    justify-content: start;
    column-gap: 10px;
}

.bodyhead div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}


.bodyhead div .textColored{
    color: rgba(42, 56, 164, 1);
    font-size: 16px;
    font-weight: 700;
}

.bodyhead div .textUnColored{
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
    font-weight: 400;
}


.bodyhead div .colored{
    width: 100px;
    margin-top: 15px;
    height:6px;
    border-radius: 4px 4px 0px 0px ;
    background-color: rgba(42, 56, 164, 1);
}

.bodyhead div .uncolored{
  display: none;
}

.centerButton{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    cursor: pointer;
    gap:20px;
    width:100%;
}

.filterHold{
    align-items:end;
    border:1px solid rgba(203, 218, 255, 1);
    margin-top:30px;
    border-width:0px 0px 1px 0px;
    display:flex;
    justify-content:center;
    column-gap:10px
}


.centerButton .proceed{
    text-align: center;
    height:50px;
    color:white;
    font-size: 14px;
    background-color:rgba(64, 123, 255, 1);
    width: 200px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
}

.centerButton .back{
    text-align: center;
    height:50px;
    color:rgba(64, 123, 255, 1);
    font-size: 14px;
    background-color: white;
    width: 200px;
    border: 1.5px solid rgba(64, 123, 255, 1);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
}

.buttonHold{
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 20px;
    cursor: pointer;
    gap:20px;
    width:100%;
}

.buttonHold .back{
    text-align: right;
    height:50px;
    color:rgba(64, 123, 255, 1);
    font-size: 14px;
    background-color: white;
    width: 200px;
    border: 1.5px solid rgba(64, 123, 255, 1);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
}

.buttonHold .reject{
    text-align: right;
    height:50px;
    color:white;
    font-size: 14px;
    background-color: red;
    width: 200px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
}


.buttonHold section{
    text-align: right;
    height:50px;
    color:white;
    font-size: 14px;
    background-color:rgba(64, 123, 255, 1);
    width: 200px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
}


.buttonHold button{
    text-align: right;
    height:50px;
    color:white;
    font-size: 14px;
    background-color:rgba(64, 123, 255, 1);
    width: 200px;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
    border:none;
}

.buttonHold .saveText{
    color:rgba(64, 123, 255, 1);
    cursor:pointer;
    font-size:16px
}


.summaryHold{
    height:300px;
    width:95%;
    border:1px solid #407BFF;
    padding:20px;
    box-sizing: border-box;
    margin:40px auto;
    box-sizing:border-box
}


.summaryMainContent{
    display:flex;
    justify-content:space-around;
    column-gap:10px;
    align-items:center;
    height:100%
}

.summaryBox{
    background-color:white;
    flex:1;
    box-shadow:rgba(0, 0, 0, 0.1);
    border-radius:10px;
    padding:0px 0px 0px 20px;
    display:flex;
    flex-direction:column;
    height:100%;
    justify-content:space-around;
    align-items:start
}

.filterHeaderSelect{
    color:#2A38A4;
    border: 3px solid #2A38A4;
    border-width:0px 0px 1px 0px;
    padding: 10px;
    cursor: pointer;
    font-weight: 600;
}

.filterHeaderNonSelect{
    color: #000000;
    padding: 10px;
    cursor: pointer;
    font-weight: 600;


}

.mainBody{
    
}

.top-form{
    margin-top: 30px;
    margin-bottom: 60px;
}

.top-form-nav{
    width:300px;
    display:flex;
    margin:10px auto;
    align-items:center
}

.top-form-nav-but-colored{
    width:30px;
    display:flex;
    align-items:center;
    justify-content:center;
    height:30px;
    color:white;
    border-radius:50%;
    background-color:rgba(64, 123, 255, 1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor:pointer;
}

.top-form-nav-but-uncolored{
    width:30px;
    display:flex;
    align-items:center;
    justify-content:center;
    height:30px;
    color:rgba(64, 123, 255, 1);
    border-radius:50%;
    background-color:white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor:pointer;
}



@media only screen and (max-width: 1000px) {

    .summaryHold{
        height:auto;
        width:95%;
        border:1px solid #407BFF;
        padding:20px;
        box-sizing: border-box;
        margin:40px auto;
        box-sizing:border-box
    }


    .summaryMainContent{
        display:flex;
        justify-content:space-around;
        row-gap:10px;
        align-items:center;
        height:100%;
        flex-direction: column;
    }

    .summaryBox{
        background-color:white;
        box-shadow:rgba(0, 0, 0, 0.1);
        border-radius:10px;
        padding:15px 15px 0px 20px;
        display:flex;
        flex-direction:column;
        width:100%;
        height:500px;
        justify-content:space-around;
        align-items:start
    }
}







@media only screen and (max-width: 700px) {
    .buttonHold{
        display: flex;
        align-items: center;
        justify-content: end;
        margin-top: 20px;
        cursor: pointer;
        gap:10px
    }
    
    .buttonHold .back{
        text-align: right;
        height:50px;
        color:rgba(64, 123, 255, 1);
        font-size: 12px;
        background-color: white;
        width: 130px;
        border: 1.5px solid rgba(64, 123, 255, 1);
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
    }
    
    .buttonHold .reject{
        text-align: right;
        height:50px;
        color:white;
        font-size: 12px;
        background-color: red;
        width: 130px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
    }
    
    
    .buttonHold section{
        text-align: right;
        height:50px;
        color:white;
        font-size: 12px;
        background-color:rgba(64, 123, 255, 1);
        width: 130px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
    }
    
    
    .buttonHold button{
        text-align: right;
        height:50px;
        color:white;
        font-size: 12px;
        background-color:rgba(64, 123, 255, 1);
        width: 130px;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        border:none;
    }
    

}