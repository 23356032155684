.bureau-credit-check-dialog * {
  box-sizing: border-box;
}

.bureau-credit-check-dialog {
  box-sizing: border-box;
  width: 100%;
  padding: 2em 1.5em;
}

.bureau-credit-check-header {
  display: flex;
  justify-content: flex-end;
}

.bureau-credit-check-header img {
  cursor: pointer;
}

.bureau-credit-check-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: .5em;
}

.bureau-credit-check-form h4 { font-size: 1.5em; }
.bureau-credit-check-form p { font-size: .9em; }
.bureau-credit-check-form p.text-medium-dark { margin: 0 0 0.5em 0; }

.bureau-credit-check-form .text-error {
  font-size: .9em;
  font-style: italic;
}

.bureau-credit-check-select-container {
  display: flex;
  align-items: center;
  gap: .5em;
}

.bureau-credit-check-input-container {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.bureau-credit-check-select-container select {
  padding: .5em 1em;
  background-color: #F8FBFF;
  border: 2px solid #DFE8FF;
  border-radius: 5px;
}

.bureau-credit-check-input-container label,
.bureau-credit-check-select-container label {
  font-weight: 600;
}

.bureau-credit-check-input-container input {
  padding: .8em 1em;
  background-color: #F8FBFF;
  border: 2px solid #DFE8FF;
  border-radius: 5px;
}

.bureau-credit-check-accept-check {
  display: flex;
  align-items: center;
  gap: .5em;
}

.bureau-credit-check-button {
  margin: 1em 0 0 0;
  padding: .7em 4em;
  font-size: 1em;
}

.bureau-credit-check-radio-fieldset {
  display: flex;
  gap: 1em;
  margin: 0 0 1em 0;
}

.bureau-credit-check-radio-fieldset .bureau-credit-check-radio {
  font-weight: 500;
  display: flex;
  gap: .5em;
  align-items: center;
}

@media only screen and (max-width: 750px) {
  .ReactModal__Content--after-open {
    width: 80% !important;
  }
}

@media only screen and (max-width: 650px) {
  .bureau-credit-check-radio-fieldset {
    flex-direction: column;
    gap: .5em;
  }
}

@media only screen and (max-width: 500px) {
  .ReactModal__Content--after-open {
    width: 90% !important;
  }
}