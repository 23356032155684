.transaction-overview-wrap {
    display: flex;
    background-color: #fff;
    border: solid 1px #DCE5F1;
    flex: 0.5;
    border-radius: 7px;
    /* gap: 2em; */
}

.image-wrap {
    display: flex;
    background-color: #1162DC;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;
    justify-content: center;
    align-items: center;
    padding: 1.4em 2em;
}

.image-wrap img {
    width: 1.8em;
    filter: brightness(0) saturate(100%) invert(100%) sepia(98%) saturate(0%) hue-rotate(27deg) brightness(107%) contrast(101%);
}

.text-content-wrap {
    padding: 1em 1.2em;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.text-content-wrap div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.text-content-wrap div p {
    color: #747272;
    font-weight: 400;
    font-size: 1em;
    margin: 0;
}

.text-content-wrap div h4 {
    color: #000;
    font-weight: 700;
    font-size: 1em;
    margin: 0;
}

.bottom-text-section {
    margin-top: 0.5em;
}