.pipeHolder {
    display: flex; 
    flex-direction: row;
    width: 55%;
    box-sizing: border-box;
    display:flex;
    justify-content:space-between;
    background-color:white;
    border-radius:10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
    padding:20px;
    margin:30px 0px;
    height:100%;
  }

 