.accordion-container {
  display: flex;
  flex-direction: column;
}

#active-acc-row {
  border-radius: 5px;
  color: #000;
}

#active-acc-row-open {
  background: #2B52AA;
  color: #fff;
  padding: 1em 2em;
}

.acc-row {
  width: calc(100% - 4em);
  display: flex;
  justify-content: space-between;
  padding: 0 2em;
  align-items: center;
}

.acc-row h4 {
  font-weight: 500;
}

.acc-row .acc-row-right {
  display: flex;
  gap: 0 1em;
  align-items: center;
  justify-content: center;
}

.acc-row .acc-row-right h4 {
  font-weight: 600;
}

.acc-row-right button,
.acc-row-right span {
  border: none;
  background-color: transparent;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  font-weight: 900;
  font-size: 1.2em;
  text-align: center;
  cursor: pointer;
}

#active-acc-row-open .acc-row-right button {
  color: #fff;
}

#active-acc-row-open h4 {
  color: #fff !important;
}

#active-acc-row-open h4 span {
  color: #ededed !important;
}

.acc-row-right span {
  cursor: unset;
}

.accordion-container .acc-bodies-container {
  padding: 1em 2em;
  background: #f3f7ff;
}

.accordion-container table thead {
  display: none;
}

.accordion-container table tbody tr {
  font-size: 90%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0 0 1em 0;
}

.accordion-container table td {
  padding: .5em 0;
}

.accordion-container table td:first-child {
  padding: 0 0 0 1.5em;
  width: calc(40% - 1.5em);
}

.accordion-container table td:nth-child(2) {
  text-align: end;
  padding: 0 1em 0 0;
  width: calc(25% - 1em);
}

.accordion-container table td:nth-child(3) {
  text-align: center;
  padding: 0;
  width: 10%;
}

.accordion-container table td:nth-child(4) {
  padding: 0 0 0 1em;
  width: calc(35% - 1em);
}

.accordion-container table td:last-child {
  padding: 0 1.5em 0 0;
  text-align: end;
  font-weight: 600;
  width: calc(15% - 1.5em);
}

.accordion-container table tbody tr:hover {
  transform: unset;
}

@media only screen and (max-width:600px){
  #active-acc-row-open {
    background: #2B52AA;
    color: #fff;
    padding: 1em 2em;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
  }

  .acc-row {
    width: calc(100% - 4em);
    justify-content: space-between;
    padding: 0 2em;
    align-items: center;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
    margin-top: 15px;
  }

}