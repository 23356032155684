.sign-up-details-form-main {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
  }
  
  .loadingForm{
    height: 80%;
  }

  .formTitle{
    color:rgba(0, 0, 0, 1);
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 25px;
  }


  .sign-up-details-form-main .sign-up-details-input-container {
    display: flex;
    flex-direction: column;
  }

  .sign-up-details-form-main .sign-up-details-input-container-sales-item-one {
    display: flex;
    flex-direction: column;
    width:50%;
  }

  .sign-up-details-form-main .sign-up-details-input-container-sales-item-three {
    display: flex;
    flex-direction: column;
    width:33.33%;
  }



  .sign-up-details-top-inputs .sign-up-details-top-input-container {
    display: flex;
    flex-direction: column;
    flex:1
  }

  .sign-up-details-form-main .sign-up-details-top-inputs {
    width: 100%;
    display: flex; 
    justify-content: space-between;
    gap: 2.5em;
    margin-top: 2.5em;
    margin-bottom: 2.5em;
  }


  .sign-up-details-top-input-container label{
    color:rgba(54, 60, 79, 1);
    font-size: 16px;
    font-weight: 500;
  }
  
  
 .sign-up-details-top-input-container select {
  padding: .7em 3em;
  background-color: rgba(239, 244, 255, 1);
  border:none;
  outline:none;
  margin-top: 15px;
}

 .sign-up-details-top-input-container input {
  padding: .7em 3em;
  background-color: rgba(239, 244, 255, 1);
  border:none;
  outline:none;
  height:30px
}

  .sign-up-details-form-main form {
    
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
    box-sizing: border-box;

  }
  
  .sign-up-details-form-main .sign-up-details-headers {
    width: 100%;
  }
  
  .sign-up-details-form-main .sign-up-details-inputs {
    width: 100%;
    display: grid; 
    grid-template-columns: 1fr 1fr ; 
    grid-template-rows: 1fr 1fr;
    grid-template-areas: 
      "top top "
      "bottom bottom "; 
    gap: 2.5em;
  }

  .sign-up-details-form-main .sign-up-details-inputs-sales-two {
    width: 100%;
    display: flex; 
    margin:30px 0px;
    column-gap: 2.5em;
  }


  .sign-up-details-form-main .sign-up-details-inputs-resident{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .sign-up-details-form-main .sign-up-details-input-container-resident-one {
    display: flex;
    flex-direction: column;
    width:30%;
    column-gap: 5px;
    min-width: 150px;
    flex: 1fr;
  }

  .sign-up-details-form-main .sign-up-details-input-container-resident-two {
    display: flex;
    flex-direction: column;
    width:45%;
    column-gap: 5px;
    min-width: 300px;
    flex: 1fr;
  }

  .sign-up-details-form-main .sign-up-details-input-container-resident-three {
    display: flex;
    flex-direction: column;
    width:100%;
    column-gap: 5px;
    
    flex: 1fr;
  }

   label{
    color:rgba(54, 60, 79, 1);
    font-size: 16px;
    font-weight: 500;
  }
  
  


.sign-up-details-form-main .selectHolder {
  padding: 0px;
  background-color: rgba(239, 244, 255, 1);
  height: 30px;
  width:100%;
}

.sign-up-details-form-main .selectHolder select{
width:100%;
height:100%;

background-color: rgba(239, 244, 255, 1);
border:none;
outline:none;
overflow-y: auto;
}


.sign-up-details-form-main  input {
  padding: .7em 3em;
  background-color: rgba(239, 244, 255, 1);
  border:none;
  outline:none;
  height:30px
}









  .sign-up-details-input-container label{
    color:rgba(54, 60, 79, 1);
    font-size: 16px;
    font-weight: 500;
  }
  
  


.sign-up-details-form-main .sign-up-details-input-container .selectHolder {
  padding: 0px;
  background-color: rgba(239, 244, 255, 1);
  height: 50px;
  padding-left: 15px;
  box-sizing: border-box;
}

.sign-up-details-form-main .sign-up-details-input-container .selectHolder select{
width:100%;
height:100%;

background-color: rgba(239, 244, 255, 1);
border:none;
outline:none;
overflow-y: auto;
}


.sign-up-details-form-main .sign-up-details-input-container input {
  padding: .7em 3em;
  background-color: rgba(239, 244, 255, 1);
  border:none;
  outline:none;
  height:30px;
}

.currency-section{
  height:fit-content;
  width:100%;
  display:flex;
  align-items:center;
  box-sizing:border-box
}

.currency-section span{
  height:30px;
  padding: .7em .7em;
  width:40px;
  background-color:rgba(246, 248, 252, 1);
  display:flex;
  align-items:center;
  justify-content:center;
  
}
.currency-section input{
  background-color: rgba(239, 244, 255, 1);
  border:none;
  outline:none;
  width: 85%;
  height:30px;
  background-color: aqua;
}

.salesSelector{
  width:250px;
  height: 50px;
}


@media only screen and (max-width: 1300px) {


.sign-up-details-form-main .sign-up-details-inputs-sales-two {
  width: 100%;
  display: flex; 
  flex-direction: column;
  row-gap: 2.5em;
}

.imageAndCancelPreHold{
  text-align: right;
  height:40px;
  color:white;
  font-size: 12px;
  background-color:rgba(64, 123, 255, 1);
  width: 200px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-around;
  border-radius: 10px;
  cursor: pointer;
}


.imagePreHold{
    text-align: right;
    width: 65%;
    overflow: hidden;
}

.imageAndCancelPreHold .imgCancel{
    display:flex;
    align-items:center;
    justify-content:center
}

.sign-up-details-form-main .sign-up-details-input-container-sales-item-one {
  display: flex;
  flex-direction: column;
  width:100%;
}
}


@media only screen and (max-width: 1000px) {
  .sign-up-details-form-main .sign-up-details-inputs {
    width: 100%;
    display: flex;
    flex-direction: column; 
    row-gap: 2.5em;
    align-items: center;
  }

  .sign-up-details-form-main .sign-up-details-top-inputs {
    width: 100%;
    display: flex; 
    flex-direction: column; 
    row-gap: 2.5em;
    align-items: center;
    margin-bottom: 2.5em;
  }

  .sign-up-details-form-main .sign-up-details-input-container {
    display: flex;
    flex-direction: column;
    flex:1;
    width:100% ;
  }

  .sign-up-details-top-inputs .sign-up-details-top-input-container {
    display: flex;
    flex-direction: column;
    flex:1;
    width:100%;
  }

}