
.tour-prompt{
    height:100%;
    width:100vw;
    overflow: auto;
    display:flex;
    align-items:center;
    justify-content:center;
    position: fixed;
    z-index:100000;
    top: 0px;
    right: 0px;
    -webkit-backdrop-filter:blur(1.5px);
    backdrop-filter: blur(1.5px);
    padding-left: 235px;
    box-sizing: border-box;
  }
  
  .tour-button-hold{
    display: flex;
    justify-content: start;
  }
  
  .tour-button-hold div{
    margin-right: 15px;
    width:160px;
    height:45px;
  }
  
  
  .stepOne{
    width:650px;
    height:250px;
    background-color: #fff;
    position:absolute;
    transform: translate(-50%,-50%);
    top:50%;
    left:50%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .inner-tour-hold{
    width:80%;
    height:80%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
  
  .tour-button-one, .tour-button-two{
    border-radius:7px;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
  }

  .tour-button-one{
    border:0.1px solid rgba(64, 123, 255, 1);
    color:rgba(64, 123, 255, 1);
  }

  .tour-button-two{
    background-color:rgba(64, 123, 255, 1);
    color:white;
    display:flex;
    justify-content:space-between;
    align-items:center;
  }