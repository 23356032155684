.bureau-form-main {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 2em 0;
}

.bureau-form-section {
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 2em 0 0 0;
  border-radius: 10px;
  padding: 1.5em;
}

.bureau-form-main .button-square {
  padding: .5em 2em;
  font-weight: 500;
  font-size: 1em;
  cursor: pointer;
}

.bureau-form-section:first-child,
.bureau-form-section:last-child {
  margin: 0;
}

.bureau-form-section h3 {
  margin: 0;
}

.bureau-form-section-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  padding: 1em 0;
  border-radius: 10px;
}

.bureau-form-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: flex-end;
  border: none;
}

.bureau-input-container {
  flex: 1 1 30%;
  display: flex;
  flex-direction: column;
  gap: .3em;
}

.bureau-input-container .inputs {
  padding: .5em 1em;
}

.bureau-input-container .inputs:disabled {
  background: #F7F7F7 !important;
}

.bureau-form-main .simple-tabs {
  margin: 0;
}

.bureau-form-main .bureau-form-section:last-child .button-square {
  padding: .5em 3.2em;
}

.bureau-form-main .bureau-form-section button { border-width: 2px; }
.bureau-form-main .bureau-form-section .button-outline { font-weight: 600; }