.previewHold{
    padding: 20px 40px;
    box-sizing: border-box;
    
}

.previewHold .PreviewHead{
    height:auto;
    box-sizing: border-box;
 
}

.PreviewHead .backView{
    height:90px;
    display:flex;
    justify-content: start;
    align-items: center;
    cursor:pointer
}

.section-top-hold{
    display:flex;
    justify-content:space-between
}

.section-top-hold div{
    flex:1;
    height:100px;
    display:flex;
    flex-direction:column;
    justify-content:space-between;

}

.customVerification-section{
    display:grid;
    grid-template-columns:1fr 1fr 1fr;
    margin:20px 0px;
    gap:2.5em;
    width:100%;
    justify-content:space-between;
}

.sigImgView{
    display:flex;
    color: rgba(64, 123, 255, 1);
    align-items:center;
    justify-content:center;
    border: 1px solid rgba(64, 123, 255, 1);
    border-width: 0px 0px 1px 0px;
    cursor: pointer;
}

@media only screen and (max-width:900px){
    .customVerification-section{
        display:flex;
        flex-direction: column;
        margin:20px 0px;
        width:100%;
        justify-content:space-between;
    }
}

@media only screen and (max-width: 1250px) {
    .section-top-hold{
        display:flex;
        justify-content:space-between;
        flex-direction: column;
    } 
    .section-top-hold div{
        margin: 15px 0px;
        row-gap: 7px;
    }
}

