.user-management-settings .user-management-type-table {
  background-color: #fff;
  border: 1px solid #DFE8FF;
  border-radius: 10px;
  padding: 1em;
}

.user-management-settings .user-management-type-table tbody tr:nth-child(odd) {
  background-color: #E5EDFF;
}

.user-management-settings .user-management-type-table tbody tr:nth-child(even) {
  background-color: #F6F8FC;
}

/* PEOPLE */


/* ROLES */
.user-management-roles-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  width: 100%;
  margin: 0 0 1em 0;
}

.user-management-roles-header-container h2 {
  font-size: 1em;
}

.user-management-roles-header-container button {
  padding: 1em 2em;
  border-radius: 4px;
  border: 1px solid #DFE8FF;
}