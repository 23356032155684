/* Bank Account Overall */
.bank-account .st-details-card-main h5 {
  margin: 0;
  font-size: 1em;
  font-weight: 500;
}

.bank-account .mb-back-button {
  margin: 0 0 1em 0;
}

.bank-account .details-header-row h3 {
  font-size: 1.2em;
}

.bank-account .st-details-card-main h4 {
  font-size: 1.2em;
}

.bank-account .st-details-card-main .mb-account-overall-details .st-details-list {
  display: flex;
  justify-content: space-between;
  gap: .5em;
}

.bank-account .st-details-card-main .mb-account-overall-details .st-details-list li h2 {
  font-size: 1em;
  font-weight: 400;
}

.bank-account .st-details-card-main .mb-account-overall-details .st-details-list li p {
  font-size: 1.5em;
  font-weight: 600;
}

/* Analytics */
.mb-account-analytics {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.mb-account-analytics .mb-account-analytics-section h2 {
  margin: 0 0 .3em 0;
}

.mb-account-analytics .mb-account-analytics-section ul {
  display: flex;
  flex-direction: column;
  gap: .5em;
}

.mb-account-analytics .mb-account-analytics-section .list-type-content {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.mb-account-analytics .mb-account-analytics-section .list-type-content h2 {
  font-size: 1em;
  margin: 0;
  font-weight: 500;
}

.mb-account-analytics .mb-account-analytics-section .list-type-content p {
  font-weight: 500;
}

.mb-account-analytics .mb-account-analytics-section .mb-account-section-double-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5em;
}

.mb-account-analytics .mb-account-analytics-section .list-type-title {
  font-size: 1.2em;
  font-weight: 600;
  margin: 0 0 1em 0;
}

/* Spend */
.mb-account-analytics .mb-spend .st-atm-list-container {
  min-height: auto;
  max-height: 10em;
  overflow-y: auto;
  overflow-x: hidden;
}

.mb-account-analytics .mb-spend ul {
  gap: 0;
}

.mb-account-analytics .mb-spend li {
  padding: .5em 1em;
  margin: 0 .5em 0 0;
}

.mb-account-analytics .mb-spend .list-type-content p {
  font-weight: 400;
}

/* Pattern */
.mb-account-analytics .mb-pattern .details-header-row h3 {
  font-size: 1em;
}

.mb-account-analytics .mb-pattern .details-header-row h4 {
  font-size: 2.5em;
  font-weight: 600;
}

/* Analytics Double Cards */
.mb-account-analytics .mb-account-analytics-section .mb-account-analytics-double-cards-container {
  display: flex;
  gap: 1em;
}

/* Transactions */
.mb-transactions-type-table-container {
  margin: 1em 0 0 0;
  padding: 1.5em;
  width: calc(100% - 3em);
  border-radius: 5px;
}

.mb-transactions-type-table-container h2 {
  margin: 0;
}

.mb-transactions-type-table-container p {
  margin: 0;
}

.mb-transactions-type-table-container .header-row {
  padding: 1em;
}

.mb-transactions-type-table-container thead tr th:first-child,
.mb-transactions-type-table-container tbody tr td:first-child {
  width: 60%;
}

.bank-account .mb-details-no-table-data-container {
  margin: 1em 0 0 0;
  align-items: center;
}

@media only screen and (max-width: 900px) {
  .mb-account-analytics .mb-behavioral .mb-account-analytics-double-cards-container {
    flex-direction: column;
  }

  .mb-transactions-type-table-container table {
    margin: 1em 0 0 0;
  }

  .mb-transactions-type-table-container .header-row {
    padding: .3em 0;
  }
}

@media only screen and (max-width: 750px) {
  .bank-account .st-details-card-main .mb-account-overall-details .st-details-list {
    flex-direction: column;
  }
}

@media only screen and (max-width: 500px) {
  .mb-account-analytics .mb-pattern .mb-account-analytics-double-cards-container {
    flex-direction: column;
  }

  .mb-account-analytics .mb-account-analytics-section .list-type-content {
    flex-direction: column;
  }
}