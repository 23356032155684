.previewEngineBut{
    border: 1px solid rgba(64, 123, 255, 1);
    border-radius: 7px;
    color:rgba(64, 123, 255, 1);
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    height:50px;
    width:230px;
    cursor: pointer;
}

.selectToggle{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    cursor: pointer;
}

.selectToggle span{
    display: flex;
    justify-content: center;
    align-items: center;
}

.selectToggle .toggletext{
    font-weight: 700;
}

.untoggle{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    cursor: pointer;
}

.untoggle span{
    display: flex;
    justify-content: center;
    align-items: center;
}

.untoggle .toggletext{
    font-weight: 400;
}


.createParameter{
    border-radius: 7px;
    color: white;
    background-color: rgba(64, 123, 255, 1) ;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 7px;
    height:50px;
    width:230px;
    cursor: pointer;
    margin-top: 20px;
}

.imageHolder{
    display: flex;
    align-items: center;
    justify-content: center;
}

.editButton{
    display:flex;
    align-items:center;
    justify-content:Center;
    border-radius: 5px;
    color: white;
    background-color: rgba(42, 56, 164, 1);
    font-size: 12px;
    height:40px;
    width: 65px;
}

.errorClassBox{
    display: flex;
    flex-direction: column;
    padding:20px;
    border-radius: 10px;
    background-color: white;
    row-gap: 15px;
    justify-content: space-around;
    align-items: center;
}

.errorClassBox .imgHold{
    display: flex;
    align-items: center;
    justify-content: center;
}

.errorClassBox .errorTitle{
    color:blue
}

.errorClassBox .errorMssg{
    color: red
}

.openCardEdit{
    display: flex;
    justify-content: end;
    width: 100%
}

.openCardEdit section{
    color:rgba(64, 123, 255, 1) ;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
}