.previewEngineBut{
    border: 1px solid rgba(64, 123, 255, 1);
    border-radius: 7px;
    color:rgba(64, 123, 255, 1);
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    height:50px;
    width:230px;
    cursor: pointer;
}

.selectToggle{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    cursor: pointer;
}

.selectToggle span{
    display: flex;
    justify-content: center;
    align-items: center;
}

.imgHoldSpan{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.optionHolderDiv{
    display: flex;
    width: 100%;
    align-items:center;
    justify-content: space-between;
}

.optionHolderDiv .inputFields{
    border:2px solid rgba(223, 232, 255, 1);
    height:45px;
    width:85%;
    padding-left:30px;
}

.optionButton{
    background-color: #2A38A4;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 130px;
    padding:10px 15px;
    color:white;
    border-radius: 7px;
    cursor: pointer;
}

.optionGrid{
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 10px;
    margin-bottom: 20px;
  };

.selectToggle .toggletext{
    font-weight: 700;
}

.untoggle{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    cursor: pointer;
}

.untoggle span{
    display: flex;
    justify-content: center;
    align-items: center;
}

.untoggle .toggletext{
    font-weight: 400;
}

.imageHolder{
    display: flex;
    align-items: center;
    justify-content: center;
}

.addParams{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    width:300px;
    cursor:pointer;
    margin: 10px 0px;
}

.editButton{
    display:flex;
    align-items:center;
    justify-content:Center;
    border-radius: 5px;
    color: white;
    background-color: rgba(42, 56, 164, 1);
    font-size: 12px;
    height:40px;
    width: 65px;
}


.createParameter{
    border-radius: 7px;
    color: white;
    background-color: rgba(42, 56, 164, 1) ;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 7px;
    height:50px;
    width:230px;
    cursor: pointer;
    margin-top: 20px;
}

.cancelParameter{
    border-radius: 7px;
    color: rgba(42, 56, 164, 1);
    background-color:  white;
    font-size: 18px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 7px;
    height:50px;
    width:230px;
    cursor: pointer;
    margin-top: 20px;
}


.ParamButton{
    width:100%;
    display: flex;
    justify-content: end;
    column-gap: 15px;
}

.successHoldBody{
    background-color:white;
    width:100%;
    border-radius:10px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    margin-top: 50px;

}

.imgFlex{
    display: flex;
    justify-content: center;
    align-items: center;
}

.successHoldBody .cardInfo{
    color: #2A38A4;
    font-size: 22px;
    text-align: center;
    font-weight: 700;
}

.successHoldBody .cardDirection{
    color: #363C4F;
    font-size: 16px;
    text-align: center;
}

.successHoldBody .cardFailed{
    color: red;
    font-size: 16px;
    text-align: center;
}

.successHoldBody .button{
    background-color: #2A38A4;
    color: white;
    width:150px;
    border-radius: 10px;
    padding: 12px;
    text-align: center;
    cursor: pointer;
}

.OptionClass{
    color:#363C4F;
    font-size: 18px;
    font-weight: 500;
    margin: 30px 0px 10px 0px;
}