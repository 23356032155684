.App {
  text-align: center;
  height: 100%;
  font-family: 'DM Sans', sans-serif;
}

* {
  font-family: 'DM Sans', sans-serif;
}

html, body, #app, #app>div, #root {
  font-size: 100%;
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'DM Sans', sans-serif;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

.app-loading-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.parent-body {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100%;
}

.main-body {
  width: calc(100% - 220px);
  min-height: 100%;
  position: relative;
  left: 220px;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(90deg, #F6F7FD 32%, #F6F9FF 100%);
  overflow-x: hidden;
}

.main-body-with-navbar {
  display: grid;
  grid-template-rows: auto 1fr auto;
  width: calc(100% - 220px);
  position: relative;
  left: 220px;
  backdrop-filter: blur(10px);
  background: linear-gradient(90deg, #eff3fa 32%, #e8eef6 100%);
}

hr {
  width: 100%;
}

button {
  cursor: pointer;
}

.detail-header { color: #2A38A4; }
.detail-header-light { color: #407BFF; }
.detail-header-white { color: #fff; }

.text-light { color: #8D94A0; }
.text-medium { color: #808080; }
.text-medium-dark { color: #5e5e5e }
.text-dark { color: #434141; }
.text-darker { color: #363C4F; }
.text-warning { color: #FF725E; }
.text-success { color: #3c9e43 }
.text-error { color: #c1121f; }

.separator-light {
  color: #D0CDCD;
  background-color: #D0CDCD;
}

.separator-blue {
  color: #2A38A4;
  background-color: #2A38A4;
}

.error-msg {
  text-align: left;
  margin-top: .5em;
  font-size: .9em;
  color: #c1121f;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cards {
  padding: 1.5em 2em;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.inputs {
  /* border-radius: 5px; */
  padding: 0 1em;
  /* border: 1px solid rgba(0, 0, 0, .2); */
  background: #fff;
}

.input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  padding: 0 1em;
  border: 1px solid rgba(0, 0, 0, .2);
}

.input-container .inputs {
  width: calc(100% - 2em);
}

.input-container img {
  width: 1.5em;
  height: 1.5em;
}

.inputs {
  border-radius: 5px;
}

.no-logo-url {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 2em;
  max-width: 1.8em;
  max-height: 1.8em;
  min-width: 1.8em;
  min-height: 1.8em;
  border-radius: 5px;
}

.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #2A38A4 #C4D3E8;
}

.scrollbar::-webkit-scrollbar {
  width: .7em;
  height: .7em;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: #C4D3E8;
  border-radius: 20px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #2A38A4;
  border-radius: 20px;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #1C256D;
}

.subtitle {
  font-size: .9em;
}

@media only screen and (max-width: 800px) {
  .main-body,
  .main-body-with-navbar {
    width: 100%;
    left: 0;
  }
}