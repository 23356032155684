.cs-main-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    margin: 2em 0 0 0;
  }
  
  .cs-main-body hr {
    height: 2px;
  }
  
  /* No Score */
  .cs-ns-current-container {
    width: calc(100% - 4em);
    padding: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #fff;
  }
  
  .cs-main-body .cs-ns-current-container button {
    cursor: pointer;
    width: auto;
    padding: .6em 4em;
    font-weight: 500;
    font-size: 1.05em;
    margin: 2em 0 0 0;
    border: none;
  }
  
  .cs-main-body .cs-ns-current-container .cs-current-header {
    margin: 0 0 1em 0;
    font-style: italic;
  }
  
  .cs-main-body .contact-link {
    font-size: 1.1em;
    font-weight: 500;
    margin: 1em 0 0 0;
  }
  
  /* One or more scores */
  .cs-main-body .cs-current-container .cs-current-score {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .cs-main-body .cs-current-container .cs-current-score,
  .cs-main-body .cs-current-container .cs-current-breakdown {
    width: calc(50% - 4em);
    padding: 1.5em 2em;
    border-radius: 10px;
    background-color: #fff;
  }
  
  .cs-main-body .cs-current-container .cs-current-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .cs-main-body .cs-current-container .cs-current-header .cs-current-header-text {
    display: flex;
    flex-direction: column;
  }
  
  .cs-main-body .cs-current-container .cs-current-header .cs-current-header-text h3 {
    font-size: 1.5em;
  }
  
  .cs-main-body .cs-current-container .cs-current-header .cs-current-header-text p {
    font-size: .9em;
  }
  
  .cs-main-body .cs-current-container .cs-current-header .cs-current-header-text p span {
    margin: 0 0 0 .5em;
    font-weight: 500;
  }
  
  .cs-main-body .cs-current-container .cs-current-header .cs-current-header-text h3,
  .cs-main-body .cs-current-container .cs-current-header .cs-current-header-text p {
    margin: 0;
  }
  
  .cs-main-body .cs-current-container button {
    cursor: pointer;
    width: auto;
    height: fit-content;
    padding: .5em 2em;
    font-weight: 500;
    border: none;
    border-radius: 2px;
  }
  
  /* Points based Speedometer */
  .cs-main-body .cs-current-container .cs-current-speedometer-container {
    position: relative;
    margin: 2.5em 1em 0 1em;
  }
  
  .cs-main-body .cs-current-container .cs-current-speedometer-container .speedometer {
    width: 100%;
    height: fit-content;
  }
  
  .cs-main-body .cs-current-container .cs-current-speedometer-container .needle {
    height: 70%;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    bottom: 15%;
    transform-origin: bottom center;
  }
  
  .cs-main-body .cs-current-container .cs-current-speedometer-container canvas {
    height: 40% !important;
    width: 70% !important;
  }
  
  .cs-main-body .cs-current-container .cs-current-speedometer-container .cs-current-speedometer-center-label {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: flex-end;
    align-items: center;
  }
  
  .cs-main-body .cs-current-container .cs-current-speedometer-container .cs-current-speedometer-center-label h1,
  .cs-main-body .cs-current-container .cs-current-speedometer-container .cs-current-speedometer-center-label p { 
    margin: 0;
  }
  
  .cs-main-body .cs-current-container .cs-current-speedometer-container .cs-current-speedometer-center-label h1 {
    font-weight: 500;
    font-size: 2.5em;
  }
  
  /* Credit Score Current */
  .cs-current-container {
    width: 100%;
    display: flex;
    gap: 1em;
  }
  
  .cs-main-body .cs-current-container .cs-current-header {
    display: flex;
    justify-content: space-between;
  }
  
  /* Credit Score Breakdown */
  .cs-main-body .cs-current-container .cs-current-breakdown {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .cs-main-body .cs-current-container .cs-current-breakdown hr {
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  
  .cs-main-body .cs-base-points-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  
  .cs-main-body .cs-base-points-container h3,
  .cs-main-body .cs-base-points-container p,
  .cs-main-body .cs-breakdown-total-container h4,
  .cs-main-body .cs-breakdown-total-container p  {
    margin: 0;
    font-weight: 500;
    font-size: 1.3em;
  }
  
  .cs-main-body .cs-current-container #cs-current-breakdown-heading {
    font-weight: 600;
  }
  
  .cs-main-body .cs-current-container .cs-current-breakdown .cs-breakdown-scroll-container {
    min-height: auto;
    max-height: 9em;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 1em 0 0;
  }
  
  .cs-main-body .cs-current-container .cs-current-breakdown .cs-breakdown-scroll-container ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }
  
  .cs-main-body .cs-current-container .cs-current-breakdown .cs-breakdown-scroll-container ul .list-type-content,
  .cs-main-body .cs-current-container .cs-current-breakdown .cs-breakdown-scroll-container ul .sublist-type-content {
    display: flex;
    justify-content: space-between;
  }
  
  .cs-main-body .cs-current-container .cs-current-breakdown .cs-breakdown-scroll-container ul .list-type-content h2,
  .cs-main-body .cs-current-container .cs-current-breakdown .cs-breakdown-scroll-container ul .list-type-content p {
    margin: 0;
    font-size: 1em;
    font-weight: 500;
  }
  
  .cs-main-body .cs-current-container .cs-current-breakdown .cs-breakdown-scroll-container ul .sublist-type-content h2,
  .cs-main-body .cs-current-container .cs-current-breakdown .cs-breakdown-scroll-container ul .sublist-type-content p {
    margin: 0;
    font-size: .9em;
  }
  
  .cs-main-body .cs-current-container .cs-current-breakdown .cs-breakdown-scroll-container ul .sublist-type-content h2 {
    font-weight: 400;
    color: #808080;
  }
  
  .cs-main-body .cs-current-container .cs-current-breakdown .cs-breakdown-scroll-container ul .sublist-type-content p {
    font-weight: 600;
  }
  
  .cs-main-body .cs-current-container .cs-breakdown-total-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Credit Scores History */
  .cs-main-body .cs-history-container {
    width: calc(100% - 4em);
    justify-content: flex-start;
  }
  
  .cs-main-body .cs-history-container h3 {
    font-size: 1.5em;
    margin: 0;
  }
  
  .cs-main-body .cs-history-container p {
    margin: 0;
    font-size: .9em;
  }
  
  /* Accordion */
  .cs-main-body .cs-history-container #credit-score-accordion .acc-row {
    display: grid; 
    grid-template-columns: 2.4fr 0.4fr 0.2fr; 
    grid-template-rows: 1fr;
    grid-template-areas: 
      ". . .";
    width: calc(100% - 2em);
    padding: .5em 1em;
  }
  
  .cs-main-body .cs-history-container #custom-credit-score-accordion .acc-row {
    display: grid; 
    grid-template-columns: 1.2fr 1.2fr .4fr .2fr; 
    grid-template-rows: 1fr;
    grid-template-areas: 
      ". . . .";
    width: calc(100% - 2em);
    padding: .5em 1em;
  }
  
  .cs-main-body .cs-history-container .accordion-container .acc-bodies-container {
    padding: 1em;
  }
  
  .cs-main-body .cs-history-container #credit-score-accordion .acc-row h4:nth-child(2),
  .cs-main-body .cs-history-container #custom-credit-score-accordion .acc-row h4:nth-child(3) {
    text-align: end;
    width: fit-content;
    border: 1px solid;
    padding: .3em 1em;
    border-radius: 5px;
    font-weight: 700;
  }
  
  .cs-main-body .cs-history-container .acc-bodies-container h3 {
    font-size: 1.2em;
    margin: 0 0 1em 0;
  }
  
  .cs-main-body .cs-history-container .acc-bodies-container .cs-base-points-container {
    margin: 0 0 .5em 0;
  }
  
  .cs-history-container .cs-history-scroll-container {
    overflow-x: hidden;
    width: 100%;
    margin: 1em 0 0 0;
  }
  
  .cs-main-body .cs-history-container .acc-bodies-container .cs-base-points-container h3 {
    margin: 0;
    font-size: 1.1em;
  }
  
  /* Accordion Body - Table */
  .cs-main-body .cs-history-container .acc-bodies-container .table-container tbody td {
    border: none !important;
  }
  
  .cs-main-body .accordion-container .cr-table-container {
    min-height: auto;
    max-height: 9em;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: thin;
    margin: 0 0 1em 0;
  }
  
  @media only screen and (max-width: 800px) {
    .cs-main-body .cs-current-container {
      flex-direction: column;
    }
  
    .cs-main-body .cs-current-container .cs-current-score,
    .cs-main-body .cs-current-container .cs-current-breakdown {
      width: calc(100% - 4em);
    }
  }
  
  @media only screen and (max-width: 700px) {
    .cs-main-body .cs-history-container .acc-bodies-container .table-container tbody tr {
      display: grid; 
      grid-template-columns: 1.9fr 1fr 0.1fr; 
      grid-template-rows: 1fr 1fr; 
      gap: 0px 0px; 
      grid-template-areas: 
        ". . ."
        ". . .";
    }
  
    .cs-main-body .cs-history-container .acc-bodies-container .table-container {
      margin: 0 .5em 0 0;
    }
  
    .cs-main-body .cs-history-container .acc-bodies-container .table-container td {
      padding: 0;
      width: unset;
      text-align: start;
    }
  }
  
  @media only screen and (max-width: 400px) {
    .cs-main-body .cs-history-container .acc-bodies-container .table-container tbody tr {
      display: flex;
      flex-direction: column;
    }
  }