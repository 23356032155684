.loanHead{
    height:auto;
    padding-left: 20px;
    box-sizing: border-box;
    border:1px solid rgba(203, 218, 255, 1);
    border-width: 0px 0px 1px 0px;
}
.loanHead span{
    display: flex;
    align-items: center;
    justify-content: center;
}

.loanHead p{
   color:rgba(31, 34, 39, 1);
   font-size: 24px;
   line-height: 31px;
   margin-left: 10px;
   font-weight: 700;
}

.loanContent{
    height:90px;
    display:flex;
    justify-content: start;
    align-items: center;
    cursor:pointer
}

.summaryHoldAgency{
    height:300px;
    width:95%;
    border:1px solid #407BFF;
    padding:20px;
    box-sizing: border-box;
    margin:40px auto;
    box-sizing:border-box;
    position:relative
}

.summaryHoldAgency .AbottomLc{
    background-color: #3E301B0D;
    border-radius: 10px;
    position: absolute;
    bottom:10px;
    left: 10px;
    width:fit-content;
    padding: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.summaryHoldAgency .ATopRc{
    top:10px;
    right: 10px;
    position: absolute;
    width:fit-content;
    padding: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.summaryHoldAgency .ATopRc select{
    border: none;
    width:100px;
}


.summaryMainContent{
    display:flex;
    justify-content:space-around;
    column-gap:10px;
    align-items:center;
    height:100%;
 
}

.agentBreakDownHold{
    background-color:white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width:25%;
    padding:10px;
    box-sizing:border-box
}

.agentBreakDownHoldSolo{
    background-color:white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width:95%;
    padding:10px;
    box-sizing:border-box;
    margin:25px auto
}

.agentBreakDownHoldChart{
    background-color:white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width:fit-content;
    padding:10px;
    box-sizing:border-box;
    height:430px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

}

.agentBreakDownHoldChartSolo{
    background-color:white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width:100%;
    padding:10px;
    box-sizing:border-box;
    height:550px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.titleBreakHold{
    display:flex;
    justify-content:space-between;
    margin:10px 0px;
}

.titleBreakHold .title{
    color:#1F2227;
    font-weight:700;
    font-size:18px;
}

.titleBreakHold .view{
    color:#2A38A4;
    font-weight:700;
    font-size:20px;
    cursor: pointer;
}

.AgentTable{
    width:95%;
    height:fit-content;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin:20px auto;
    background-color:white;
    padding: 10px;
    box-sizing: border-box;
}

.AgentTableSmall{
    width:45%;
    height:fit-content;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin:20px auto;
    background-color:white;
    padding: 10px;
    box-sizing: border-box;
}

.AgentTableRow{
    display:flex;
    justify-content:space-around;
    margin-top:20px;
    align-items: center;
}

.AgentTableRow section{
    width:18%
}


.TransactTableRow div:nth-child(even) {
    background-color: #D9E5FF;
    display:flex;
    justify-content:space-around;
    margin-top:20px;
    align-items: center; 
    padding: 15px 0px;/* Light gray background for even rows */
}

.TransactTableRow div:nth-child(odd) {
    background-color: #F6F8FC;
    display:flex;
    justify-content:space-around;
    margin-top:20px;
    align-items: center;
    padding: 15px 0px; /* White background for odd rows */
}

.flexCenter{
    display: flex;
    align-items: center;
    justify-content: center;
}

.AgentName{
    color: #1F2228;
    font-size: 24px;
    font-weight: 700;
}

.AgentFirm{
    color: #707070;
    font-size: 18px;
    font-weight: 500;
}

.AgentButtonHolder{

    padding-top: 10px;

}

.AgentButton{
    background-color: #D9E5FF;
    border-radius:5px;
    padding:5px;
    display:flex;
    align-items:center;
    justify-content:center;
    color:#2A38A4;
    font-size: 14px;
    font-weight: 500;
}

.filterHold{
    align-items:end;
    border:1px solid rgba(203, 218, 255, 1);
    margin-top:30px;
    border-width:0px 0px 1px 0px;
    display:flex;
    justify-content:center;
    column-gap:10px
}

.filterHeaderSelect{
    color:#2A38A4;
    border: 3px solid #2A38A4;
    border-width:0px 0px 1px 0px;
    padding: 10px;
    cursor: pointer;
    font-weight: 600;
}


.filterHeaderNonSelect{
    color: #000000;
    padding: 10px;
    cursor: pointer;
    font-weight: 600;
}
