.container {
    width: 100%;
  }


  .headerContainer {
    width: 100%;
    /* margin-bottom: 2.5rem; */
    border-bottom: 1px solid #2A38A4;
  }
  
  .header {
  
    display: flex;
    align-items: center;
    width:50%;
    margin-inline: auto;
    justify-content: center;
    color: #1F2228;
  }
  
  .headerItem {
    font-size: 16px;
    color: #282828;
    flex-grow: 1;
    cursor: pointer;
    min-height: 5rem;
    display: flex;
    font-family: inherit;
    align-items: center;
    line-height: 20.83px;
    font-weight: 400;
    justify-content: center;
    transition: background-color 0.1s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  
  .headerActive {
    color:#2A38A4;
    font-weight: 700;
    font-size:18px;
    line-height: 23.44px;
    position: relative;
 /* border-bottom:5px solid #2A38A4; */


  }

  .headerActive::after {
    width:100%;
    height:8px;
    content: "";
    position: absolute;
    background-color: #2A38A4 ;
    bottom: 0;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }


.body {
  padding-inline: 25px;
}

  