.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;

  }

  .top-cards-container-loader {

    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 0 2.5em 0;
    gap:20px;
    padding: 1em;
    border-radius: 5px;
    border: 1px solid #407BFF;
    justify-content: space-between;
}

.loaderWrap {
    flex-basis: 40%;
    height:150px;
    display:flex;
    justify-content: center;
    position: relative;
    background-color: #fff;
    flex-direction: column;
    padding-inline:30px;
    gap:10px
}

  